import Brightness1Icon from '@mui/icons-material/Brightness1';
import Box from '@mui/material/Box';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Typography from '@mui/material/Typography';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import React from 'react';
import { assets } from 'assets/assets';

function Description() {
  const description = `Become a skilled professional Software Developer, web designer or a mobile app developer under the tutelage of our vast and experienced trainers. Within your three(3) months of learning be rest assured you will be taken through the right path for your career growth and and become better equipped in your chosen learning track. Get onboarded by our seasoned tutored and researchers as you get up to date with the latest industry needs.

  Let's get you started today.
  `;

  const trainingSkills = [
    { name: 'UI/UX', icon: <Brightness1Icon /> },
    { name: 'Web', icon: <Brightness1Icon /> },
    { name: 'Mobile', icon: <Brightness1Icon /> },
    { name: 'Cloud', icon: <Brightness1Icon /> },
  ];
  return (
    <Box overflow={'hidden'}>
      <Box
        display="flex"
        flexDirection={{
          xl: 'row',
          lg: 'row',
          md: 'column-reverse',
          sm: 'column-reverse',
          xs: 'column-reverse',
        }}
        justifyContent={'center'}
      >
        <Box
          sx={{
            maxWidth: 600,
            height: 600,
            marginBottom: 5,
          }}
        >
          <Box
            component={LazyLoadImage}
            src={assets.trainingSection.trainingImage}
            alt="topibrotech, training"
            sx={{
              height: '100%',
              width: '100%',
            }}
          />
        </Box>
        <Box
          alignItems={'center'}
          flexGrow={1}
          sx={{ display: 'flex', justifyContent: 'center' }}
        >
          <Box>
            <Typography
              variant={'h1'}
              component={'h1'}
              gutterBottom
              sx={{
                fontWeight: 700,
                textAlign: 'center',
                fontFamily: 'Roboto, sans-serif',
              }}
            >
              Training
            </Typography>
            <Typography
              variant="body2"
              sx={{
                color: '#84848C',
                fontSize: 18,
                fontWeight: 400,
                marginBottom: 0,
                maxWidth: { xl: 560, lg: 560, md: 'none' },
                marginTop: 5,
                textAlign: 'center',
              }}
            >
              {description}
            </Typography>
            <Typography
              variant="body2"
              sx={{
                color: '#84848C',
                fontSize: 15,
                fontWeight: 400,
                marginBottom: 0,
                maxWidth: { xl: 560, lg: 560, md: 'none' },
                display: 'flex',
                flexWrap: {
                  xl: 'nowrap',
                  lg: 'nowrap',
                  md: 'wrap',
                  sm: 'wrap',
                  xs: 'wrap',
                },
                justifyContent: 'left',
                textAlign: 'center',
              }}
            >
              {trainingSkills.map((training, i) => {
                return (
                  <ListItem key={i}>
                    <ListItemIcon>{training.icon}</ListItemIcon>
                    <ListItemText>{training.name}</ListItemText>
                  </ListItem>
                );
              })}
            </Typography>
          </Box>
        </Box>
      </Box>
    </Box>
  );
}

export default Description;
