import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import { useTheme } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import React from 'react';
import { assets } from 'assets/assets';

function Description() {
  const theme = useTheme();

  const description = `We provide you with an affordable, conducive, convenient and, work-friendly co-working space for your seminars, freelancers, meetups, professionals and, event space that is safe and secure, air-conditioned and, has a reception waiting area, dedicated desks, free internet, power backup, scheduled private office and is always opened 24hrs. At Topibro Technologies, we provide a co-working space built in your interest to help get work done and on target.
  `;
  return (
    <Box justifyContent="center" display="flex">
      <Box
        sx={{
          backgroundColor: '#f2f7ff',
          borderRadius: 360,
          position: 'absolute',
          zIndex: 0,
          left: { xl: 0, lg: 0, md: 50, sm: 0, xs: 0 },
          top: 900,
          height: '80%',
          width: '60%',
          overflow: 'hidden',
        }}
      />
      <Box
        sx={{
          backgroundColor: '#f2f7ff',
          borderRadius: 360,
          position: 'absolute',
          zIndex: 0,
          right: { xl: 50, lg: 50, md: 50, sm: 25, xs: 0 },
          top: 1100,
          width: { xl: '30%', lg: '25%', md: '40%', sm: '50%', xs: '50%' },
          height: { xl: '50%', lg: '40%', md: '30%', sm: '60%', xs: '40%' },
          overflow: 'hidden',
        }}
      />
      <Box
        sx={{
          display: 'flex',
          flexDirection: {
            xl: 'row',
            lg: 'row',
            md: 'row',
            sm: 'column-reverse',
            xs: 'column-reverse',
          },
          zIndex: 10,
          alignItems: 'center',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-around',
            flexGrow: 1,
            marginY: 5,
            paddingRight: { xl: 5, lg: 5, md: 5, sm: 0, xs: 0 },
          }}
        >
          <Box
            sx={{
              maxWidth: 800,
              width: {
                xl: '60%',
                lg: '60%',
                md: '60%',
                sm: '100vw',
                xs: '100vw',
              },
              marginRight: {
                xl: '40%',
                lg: '40%',
                md: '40%',
                sm: 'auto',
                xs: 'auto',
              },
            }}
          >
            <Box
              component={'img'}
              src={assets.coworkingSpace.description1}
              width={'100%'}
              height={'100%'}
              sx={{
                filter:
                  theme.palette.mode === 'dark' ? 'brightness(0.8)' : 'none',
                borderRadius: { xl: 5, lg: 5, md: 5, sm: 0, xs: 0 },
                marginBottom: 5,
              }}
            />
          </Box>
          <Box
            sx={{
              maxWidth: 800,
              width: {
                xl: '60%',
                lg: '60%',
                md: '60%',
                sm: '100%',
                xs: '100vw',
              },
              marginLeft: {
                xl: '40%',
                lg: '40%',
                md: '40%',
                sm: 'auto',
                xs: 'auto',
              },
              display: {
                xl: 'block',
                lg: 'block',
                md: 'block',
                sm: 'none',
                xs: 'none',
              },
              paddingLeft: 5,
            }}
          >
            <Box
              component={'img'}
              src={assets.coworkingSpace.description2}
              width={'100%'}
              height={'100%'}
              sx={{
                filter:
                  theme.palette.mode === 'dark' ? 'brightness(0.8)' : 'none',
                borderRadius: 5,
                marginTop: 5,
              }}
            />
          </Box>
        </Box>
        <Box alignItems={'center'} marginTop={5}>
          <Box
            sx={{
              width: { xl: 400, lg: 400, md: 400, sm: '90%', xs: '90%' },
              margin: 'auto',
            }}
          >
            <Typography
              variant={'h4'}
              sx={{ fontWeight: 700, marginY: 3 }}
              textAlign={'left'}
            >
              Co-working Space
            </Typography>
            <Typography
              variant="body2"
              sx={{
                color: '#84848C',
                fontSize: 18,
                fontWeight: 400,
                marginBottom: 5,
              }}
            >
              {description}
            </Typography>
            <Button
              variant="contained"
              color="primary"
              size="large"
              component="a"
              href="contact-us"
            >
              Book a tour
            </Button>
          </Box>
        </Box>
      </Box>
    </Box>
  );
}

export default Description;
