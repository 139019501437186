import AutoFixHighIcon from '@mui/icons-material/AutoFixHigh';
import BoltIcon from '@mui/icons-material/Bolt';
import TelegramIcon from '@mui/icons-material/Telegram';
import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import { alpha, useTheme } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import React from 'react';

const mock = [
  {
    title: 'Quality training method',
    icons: (
      <TelegramIcon
        sx={{
          width: 80,
          height: 80,
        }}
      />
    ),
    subtitle:
      'With our modern facilities we deliver training in the most effective manner.',
    icon: (
      <svg
        height={24}
        width={24}
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        stroke="currentColor"
      >
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={2}
          d="M15 15l-2 5L9 9l11 4-5 2zm0 0l5 5M7.188 2.239l.777 2.897M5.136 7.965l-2.898-.777M13.95 4.05l-2.122 2.122m-5.657 5.656l-2.12 2.122"
        />
      </svg>
    ),
  },
  {
    title: 'Systematic approach',
    icons: (
      <BoltIcon
        sx={{
          width: 80,
          height: 80,
        }}
      />
    ),
    subtitle:
      'We prioritize using a unique learning system that guarantee 100% positive result in our trainees.',
    icon: (
      <svg
        height={24}
        width={24}
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        stroke="currentColor"
      >
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={2}
          d="M7 21a4 4 0 01-4-4V5a2 2 0 012-2h4a2 2 0 012 2v12a4 4 0 01-4 4zm0 0h12a2 2 0 002-2v-4a2 2 0 00-2-2h-2.343M11 7.343l1.657-1.657a2 2 0 012.828 0l2.829 2.829a2 2 0 010 2.828l-8.486 8.485M7 17h.01"
        />
      </svg>
    ),
  },
  {
    title: 'One to one mentorship',
    icons: (
      <AutoFixHighIcon
        sx={{
          width: 80,
          height: 80,
        }}
      />
    ),
    subtitle:
      'We made provision for quality one on one mentorship during and after your training process.',
    icon: (
      <svg
        height={24}
        width={24}
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        stroke="currentColor"
      >
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={2}
          d="M10 20l4-16m4 4l4 4-4 4M6 16l-4-4 4-4"
        />
      </svg>
    ),
  },
];

const Features = () => {
  const theme = useTheme();
  return (
    <Box data-aos={'fade-up'} data-aos-offset={100} data-aos-duration={600}>
      <Box
        justifyContent="center"
        marginY={5}
        display="flex"
        flexDirection={'column'}
      >
        <Typography
          variant="h1"
          fontWeight={700}
          textAlign="center"
          textTransform={'capitalize'}
          sx={{
            fontFamily: 'Roboto, sans-serif',
          }}
        >
          Training pattern
        </Typography>
      </Box>
      <Grid container spacing={2}>
        {mock.map((item, i) => (
          <Grid item xs={12} md={4} key={i}>
            <Box
              width={1}
              height={1}
              data-aos={'fade-up'}
              data-aos-delay={i * 100}
              data-aos-offset={100}
              data-aos-duration={600}
              marginY={3}
            >
              <Box
                display={'flex'}
                flexDirection={'column'}
                alignItems={'center'}
              >
                <Box
                  component={Avatar}
                  width={150}
                  height={150}
                  marginBottom={2}
                  backgroundColor={alpha(theme.palette.primary.main, 0.1)}
                  color={theme.palette.primary.main}
                >
                  {item.icons}
                </Box>
                <Typography
                  variant={'h6'}
                  gutterBottom
                  sx={{ fontWeight: 700 }}
                  align={'center'}
                  textTransform="capitalize"
                >
                  {item.title}
                </Typography>
                <Typography align={'center'} color="text.secondary">
                  {item.subtitle}
                </Typography>
              </Box>
            </Box>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default Features;
