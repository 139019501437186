import React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';

const Description = () => {
  return (
    <Box sx={{ m: 2 }}>
      <Box>
        <Typography variant="h5">Description </Typography>
      </Box>
      <Box sx={{ my: 4 }}>
        <Paper sx={{ p: 3 }}>
          <Box>This is just an updated test design</Box>
        </Paper>
      </Box>
    </Box>
  );
};
export default Description;
