import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import ListItem from '@mui/material/ListItem';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemText from '@mui/material/ListItemText';
import { useTheme } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import useMediaQuery from '@mui/material/useMediaQuery';
import React from 'react';

const Main = () => {
  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.up('md'), {
    defaultMatches: true,
  });
  const whatWeAreLookingFor = [
    'Developing, and testing UI for web applications',
    'Build reusable code and libraries for future use',
    'Accurately convert designs to functional web applications',
    'Accurately translate user and business needs into functional frontend code',
    'Implementing design on mobile websites.',
    'Creating tools that improve site interaction regardless of the browser.',
    'Managing software workflow.',
  ];

  // const requirements = [
  //   'Strong proficiency in JavaScript, including DOM manipulation and the JavaScript object model',
  //   'Thorough understanding of React.js and its core principles',
  //   'Familiarity with newer specifications of EcmaScript',
  //   'Familiarity with RESTful APIs',
  //   'Knowledge of modern authorization mechanisms, such as JSON Web Token',
  //   'Ability to understand business requirements and translate them into technical requirements',
  //   'Understanding and ability to use version control tools i.e Git',
  //   'Experience with debugging using dev tools.',
  // ];

  const whoWeAre = `Our focus is always on finding the best people to work with. Our
  bar is high, but you look ready to take on the challenge. We
  design and implement creative solutions to everyday business
  problems. We are a team of creative consultants who help bridge
  the digital gap between companies and their clients with websites
  that not only serve as marketing platforms but also provide
  solutions to online business problems and digital marketing
  strategies that connect you with the ideal client and help create
  a loyal customer.`;

  const whatWeAreLookinForParagrap = `The ideal candidate will be responsible for developing, testing,
  and debugging responsive web applications for Topibro
  Technologies. Using React, JavaScript, HTML, and CSS, this
  candidate will be able to translate user and business needs into
  functional frontend design.`;
  return (
    <Box>
      <Box
        display={'flex'}
        justifyContent={'space-between'}
        alignItems={{ xs: 'flex-start', sm: 'center' }}
        flexDirection={{ xs: 'column', sm: 'row' }}
      >
        <Box>
          <Typography fontWeight={700} variant={'h4'} gutterBottom>
            Mobile / Web Developer
          </Typography>
          <Typography variant={'h6'}>Ijegun, Lagos · Full time</Typography>
        </Box>
        <Box display="flex" marginTop={{ xs: 2, md: 0 }}>
          <Button variant="contained" color="primary" size="large">
            Apply now
          </Button>
          <Box
            component={Button}
            variant="outlined"
            color="primary"
            size="large"
            marginLeft={2}
          >
            Refer a friend
          </Box>
        </Box>
      </Box>
      <Divider sx={{ marginY: 4 }} />
      <Grid container spacing={isMd ? 4 : 2}>
        <Grid item xs={12} md={8}>
          <Box marginBottom={3}>
            <Typography variant={'h5'} fontWeight={700} gutterBottom>
              Who we are
            </Typography>
            <Typography component={'p'}>{whoWeAre}</Typography>
          </Box>
          <Box marginBottom={3}>
            <Typography variant={'h5'} fontWeight={700} gutterBottom>
              What we’re looking for
            </Typography>
            <Typography component={'p'}>
              {whatWeAreLookinForParagrap}
            </Typography>
            <Grid container spacing={1} sx={{ marginTop: 1 }}>
              {whatWeAreLookingFor.map((item, i) => (
                <Grid item xs={12} key={i}>
                  <Box
                    component={ListItem}
                    disableGutters
                    width={'auto'}
                    padding={0}
                  >
                    <Box
                      component={ListItemAvatar}
                      minWidth={'auto !important'}
                      marginRight={2}
                    >
                      <Box
                        component={Avatar}
                        bgcolor={theme.palette.secondary.main}
                        width={20}
                        height={20}
                      >
                        <svg
                          width={12}
                          height={12}
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 20 20"
                          fill="currentColor"
                        >
                          <path
                            fillRule="evenodd"
                            d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                            clipRule="evenodd"
                          />
                        </svg>
                      </Box>
                    </Box>
                    <ListItemText primary={item} />
                  </Box>
                </Grid>
              ))}
            </Grid>
          </Box>
          <Box>
            <Typography variant={'h5'} fontWeight={700} gutterBottom>
              How to apply
            </Typography>
            <Typography component={'p'}>
              Fill your details and send a copy of your resume and cover letter
              in the form provided below
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={12} md={4}>
          <Grid container spacing={isMd ? 4 : 2} direction="column">
            <Grid item xs={12} data-aos="fade-up">
              <Box component={Card} bgcolor={'primary.main'}>
                <CardContent>
                  <Typography
                    variant="h6"
                    gutterBottom
                    color="text.primary"
                    sx={{ color: 'common.white' }}
                  >
                    You like what you’re reading?
                  </Typography>
                  <Typography
                    variant="subtitle1"
                    color="text.secondary"
                    sx={{ color: 'common.white' }}
                  >
                    Get free online programing tips and resources delivered
                    directly to your inbox.
                  </Typography>
                </CardContent>
              </Box>
            </Grid>
            <Grid item xs={12} data-aos="fade-up">
              <Box component={Card}>
                <CardContent>
                  <Typography variant="h6" gutterBottom color="text.primary">
                    Interactive decision support system
                  </Typography>
                  <Button
                    variant="contained"
                    color="primary"
                    size="large"
                    endIcon={
                      <Box
                        component={'svg'}
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                        width={24}
                        height={24}
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth={2}
                          d="M17 8l4 4m0 0l-4 4m4-4H3"
                        />
                      </Box>
                    }
                  >
                    View all
                  </Button>
                </CardContent>
              </Box>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
};

export default Main;
