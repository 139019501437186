import FacebookIcon from '@mui/icons-material/Facebook';
import InstagramIcon from '@mui/icons-material/Instagram';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import TwitterIcon from '@mui/icons-material/Twitter';
import { Link } from '@mui/material';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import { useTheme } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import NavItem from './components/NavItem';

const SidebarNav = ({ pages, colorInvert }) => {
  const theme = useTheme();
  const { mode } = theme.palette;

  const [activeLink, setActiveLink] = useState('');
  useEffect(() => {
    setActiveLink(window && window.location ? window.location.pathname : '');
  }, []);
  const {
    service: servicePage,
    // career: careerPage,
    about: aboutUsPage,
    seminar: eventPage,
    blog: blogPage,
    mentor: mentorPage,
    create: createPage,
  } = pages;

  const hasActiveLink = (link) => {
    return link === activeLink;
  };
  const isOnlinePage = window.location?.pathname?.includes('/onlineplatform');

  return (
    <Box>
      <Box width={1} paddingX={2} paddingY={1}>
        <Box
          display={'flex'}
          component="a"
          href="/"
          title="Topibro Tech"
          width={{ xs: 100, md: 120 }}
        >
          <Box
            component={'img'}
            src={
              mode === 'light'
                ? 'https://firebasestorage.googleapis.com/v0/b/topibro-tech.appspot.com/o/minified-images%2Ftopibro-logo2.png?alt=media&token=a27cd13f-8d1e-45b5-a191-b0ecde94bb28'
                : 'https://firebasestorage.googleapis.com/v0/b/topibro-tech.appspot.com/o/minified-images%2Ftopibro-logo-dark-theme.png?alt=media&token=44d8ee0a-b693-4135-b5fa-6c256b4ec85c'
            }
            height={1}
            width={1}
          />
        </Box>
      </Box>
      <Box paddingX={2} paddingY={2}>
        {isOnlinePage ? (
          <>
            <Box marginLeft={4}>
              <Link
                underline="none"
                component="a"
                href={mentorPage[0].href}
                color={colorInvert ? 'common.white' : 'text.primary'}
                sx={{ display: 'flex', alignItems: 'center' }}
                fontWeight={hasActiveLink(mentorPage[0].href) ? 700 : 400}
              >
                {mentorPage[0].title}
              </Link>
            </Box>
            <Box marginLeft={4}>
              <Link
                underline="none"
                component="a"
                href={createPage[0].href}
                color={colorInvert ? 'common.white' : 'text.primary'}
                sx={{ display: 'flex', alignItems: 'center' }}
                fontWeight={hasActiveLink(createPage[0].href) ? 700 : 400}
              >
                {createPage[0].title}
              </Link>
            </Box>
            <Box marginLeft={4}>
              <Button
                variant="contained"
                color="primary"
                component="a"
                target="blank"
                href="login"
                size="large"
              >
                Log In
              </Button>
            </Box>
          </>
        ) : (
          <>
            <Box sx={{ marginBottom: 2 }}>
              <NavItem title={'Services'} items={servicePage} />
            </Box>
            {/* <Box sx={{ marginBottom: 4 }}>
          <Link
            underline="none"
            component="a"
            href={careerPage[0].href}
            sx={{ display: 'flex', alignItems: 'center' }}
            fontWeight={hasActiveLink(careerPage[0].href) ? 700 : 400}
          >
            <Typography
              variant={'p'}
              sx={{
                fontSize: 16,
                lineHeight: 1,
              }}
              color={
                hasActiveLink(careerPage[0].href) ? 'primary' : 'text.primary'
              }
            >
              {careerPage[0].title}
            </Typography>
          </Link>
        </Box> */}
            <Box sx={{ marginBottom: 4 }}>
              <Link
                underline="none"
                component="a"
                href={aboutUsPage[0].href}
                sx={{ display: 'flex', alignItems: 'center' }}
                fontWeight={hasActiveLink(aboutUsPage[0].href) ? 700 : 400}
              >
                <Typography
                  variant={'p'}
                  sx={{
                    fontSize: 16,
                    lineHeight: 1,
                  }}
                  color={
                    hasActiveLink(aboutUsPage[0].href)
                      ? 'primary'
                      : 'text.primary'
                  }
                >
                  {aboutUsPage[0].title}
                </Typography>
              </Link>
            </Box>

            <Box sx={{ marginBottom: 4 }}>
              <Link
                underline="none"
                component="a"
                href={eventPage[0].href}
                sx={{ display: 'flex', alignItems: 'center' }}
                fontWeight={hasActiveLink(eventPage[0].href) ? 700 : 400}
              >
                <Typography
                  variant={'p'}
                  sx={{
                    fontSize: 16,
                    lineHeight: 1,
                  }}
                  color={
                    hasActiveLink(eventPage[0].href)
                      ? 'primary'
                      : 'text.primary'
                  }
                >
                  {eventPage[0].title}
                </Typography>
              </Link>
            </Box>

            <Box sx={{ marginBottom: 4 }}>
              <Link
                underline="none"
                component="a"
                href={blogPage[0].href}
                sx={{ display: 'flex', alignItems: 'center' }}
                fontWeight={hasActiveLink(blogPage[0].href) ? 700 : 400}
              >
                <Typography
                  variant={'p'}
                  sx={{
                    fontSize: 16,
                    lineHeight: 1,
                  }}
                  color={
                    hasActiveLink(blogPage[0].href) ? 'primary' : 'text.primary'
                  }
                >
                  {blogPage[0].title}
                </Typography>
              </Link>
            </Box>

            {/* <Box>
          <NavItem title={'Account'} items={accountPages} />
        </Box>
        <Box>
          <NavItem title={'Blog'} items={blogPages} />
        </Box>
        <Box>
          <NavItem title={'Portfolio'} items={portfolioPages} />
        </Box> */}
            <Box marginTop={2}>
              <Link
                underline="none"
                component="a"
                href="contact-us"
                sx={{ display: 'flex', alignItems: 'center' }}
                color={hasActiveLink() ? 'text.secondary' : 'common.white'}
              >
                <Button
                  size={'large'}
                  variant="outlined"
                  fullWidth
                  component="a"
                  href="#"
                  sx={{
                    color: 'text.primary',
                  }}
                >
                  Contact Us
                </Button>
              </Link>
            </Box>
          </>
        )}
        <Box marginTop={1}>
          {/* <Button
            size={'large'}
            variant="contained"
            color="primary"
            fullWidth
            component="a"
            target="blank"
            href="https://mui.com/store/items/the-front-landing-page/"
          >
            Purchase now
          </Button> */}
        </Box>
      </Box>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          fontSize: 30,
        }}
      >
        <Box
          marginRight={{ xs: 1, sm: 2 }}
          sx={{ color: 'text.secondary', marginLeft: 2 }}
        >
          <Link
            underline="none"
            component="a"
            href="https://facebook.com/topibrotech"
            color="text.primary"
          >
            <FacebookIcon fontSize="15" />
          </Link>
        </Box>
        <Box
          marginRight={{ xs: 1, sm: 2 }}
          sx={{ color: 'text.secondary', marginLeft: 2 }}
        >
          <Link
            underline="none"
            component="a"
            href="https://twitter.com/topibrotech"
            color="text.primary"
          >
            <TwitterIcon fontSize="15" />
          </Link>
        </Box>
        <Box
          marginRight={{ xs: 1, sm: 2 }}
          sx={{ color: 'text.secondary', marginLeft: 2 }}
        >
          <Link
            underline="none"
            component="a"
            href="https://instagram.com/topibrotech"
            color="text.primary"
          >
            <InstagramIcon fontSize="15" />
          </Link>
        </Box>
        <Box
          marginRight={{ xs: 1, sm: 2 }}
          sx={{ color: 'text.secondary', marginLeft: 2 }}
        >
          <Link
            underline="none"
            component="a"
            href="https://www.linkedin.com/company/topibrotech/"
            color="text.primary"
          >
            <LinkedInIcon fontSize="15" />
          </Link>
        </Box>
      </Box>
    </Box>
  );
};

SidebarNav.propTypes = {
  pages: PropTypes.object.isRequired,
  colorInvert: PropTypes.bool,
};

export default SidebarNav;
