import Box from '@mui/material/Box';
import { useTheme } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import React from 'react';
import { assets } from 'assets/assets';

function Description() {
  const theme = useTheme();

  const description = `
  An effective website design should fulfil its intended function by conveying its particular message whilst simultaneously engaging the visitor. Several factors such as consistency, colours, typography, imagery, simplicity, and functionality contribute to good website design.
  
  When designing a website there are many key factors that will contribute to how it is perceived. A well-designed website can help build trust and guide visitors to take action. Creating a great user experience involves making sure your website design is optimised for usability (form and aesthetics) and how easy is it to use (functionality).`;
  return (
    <Box justifyContent="center" display="flex">
      <Box
        display="flex"
        flexDirection={{
          xl: 'row',
          lg: 'row',
          md: 'row',
          sm: 'column',
          xs: 'column',
        }}
      >
        <Box alignItems={'center'} xs={12} md={6} marginTop={5}>
          <Box>
            <Typography variant={'h4'} sx={{ fontWeight: 700, marginY: 3 }}>
              Web Design and Layouts
            </Typography>
            <Typography
              variant="body2"
              sx={{
                color: '#84848C',
                fontSize: 18,
                fontWeight: 400,
                marginBottom: 0,
                maxWidth: 560,
              }}
            >
              {description}
            </Typography>
          </Box>
        </Box>
        <Box justifyContent="center" alignItems="center" xs={12} md={6}>
          <Box maxWidth={500} width={1}>
            <Box
              component={'img'}
              src={assets.webDesign.layoutImage}
              width={1}
              height={1}
              sx={{
                filter:
                  theme.palette.mode === 'dark' ? 'brightness(0.8)' : 'none',
              }}
            />
          </Box>
        </Box>
      </Box>
    </Box>
  );
}

export default Description;
