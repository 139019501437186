import React, { useEffect, useState } from 'react';
import Container from 'components/Container';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { useLocation } from 'react-router-dom';

import { Alert, Snackbar } from '@mui/material';
import Box from '@mui/material/Box';

import Grid from '@mui/material/Grid';
import { useTheme } from '@mui/material/styles';
import Typography from '@mui/material/Typography';

// import FormDialog from './FormDialogue';
import AlertDialogSlide from './MailPop';
import SuccessMessage from './success';
import CourseList from './CourseList';

const Hero = () => {
  const theme = useTheme();
  // const isMd = useMediaQuery(theme.breakpoints.up('md'), {
  //   defaultMatches: true,
  // });
  const [openConfirmModal, setOpenConfirmModal] = useState(false);
  const [openSuccessModal, setOpenSuccessModal] = useState(false);

  const [confirmToken, setSetConfirmToken] = useState('');
  const [confirmSeminar, setConfirmSeminar] = useState(false);

  const [openSnackBar, setOpenSnackBar] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const location = useLocation();

  useEffect(() => {
    if (location.search) {
      const token = new URLSearchParams(location.search).get('token');
      const confirm = new URLSearchParams(location.search).get('confirm');

      setSetConfirmToken(token);
      setConfirmSeminar(confirm === 'true');
      setOpenConfirmModal(true);
    }
  }, []);

  const handleCloseSnackbar = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpenSnackBar(false);
  };

  const GridItemHeadlineBlock = () => (
    <Box marginBottom={4} data-aos="fade-up">
      <Typography
        variant="h3"
        gutterBottom
        textAlign="left"
        sx={{
          fontWeight: 900,
          color: 'common.white',
        }}
      >
        Become a mentor
      </Typography>
      <Typography
        variant="h6"
        component="p"
        color="text.primary"
        sx={{
          fontWeight: 500,
          color: 'common.white',
        }}
      >
        Join our Network of top expert mentors, and share your passion for code
        with others while helping them solve their problems at the same time
      </Typography>
      <Box marginTop={5} fullwidth>
        <CourseList />
      </Box>
    </Box>
  );

  const GridItemLeftBlock = () => (
    <Box
      sx={{
        height: { xs: 'auto', md: 1 },
        '& img': {
          objectFit: 'cover',
        },
        '& .lazy-load-image-loaded': {
          height: 1,
          width: 1,
        },
      }}
    >
      <Box
        component={LazyLoadImage}
        effect="blur"
        src={
          'https://images.pexels.com/photos/6266980/pexels-photo-6266980.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2'
        }
        height="100%"
        width="100%"
        margin={0}
      />
    </Box>
  );

  return (
    <Box
      minHeight={300}
      height={'auto'}
      position={'relative'}
      sx={{
        marginTop: -16,
        paddingTop: 13,
        backgroundColor: theme.palette.alternate.main,
        background:
          'url(https://firebasestorage.googleapis.com/v0/b/topibro-tech.appspot.com/o/minified-images%2FMobile%20Development%20hero%20background-min.jpeg?alt=media&token=5a5a77d6-8e3d-4a1b-baac-dd15bd260840) no-repeat center',
        backgroundSize: 'cover',
      }}
    >
      <Box
        sx={{
          position: 'absolute',
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          width: 1,
          height: 1,
          // backgroundColor: theme.palette.primary.main,
          backgroundImage: `linear-gradient(315deg, ${theme.palette.primary.main} 0%, #000000 74%)`,
          opacity: '0.8',
          zIndex: 1,
        }}
      />
      <Container position={'relative'} zIndex={2}>
        <Grid container spacing={4}>
          <Grid item xs={12} md={6}>
            <Box width={1} height="100%" display="flex" alignItems="center">
              <GridItemHeadlineBlock />
            </Box>
          </Grid>
          <Grid item xs={12} md={6}>
            <Box width={1} height="100%" display="flex" alignItems="center">
              <GridItemLeftBlock />
            </Box>
          </Grid>
        </Grid>
        <AlertDialogSlide
          openConfirmModal={openConfirmModal}
          setOpenConfirmModal={setOpenConfirmModal}
          confirmToken={confirmToken}
          confirmSeminar={confirmSeminar}
          setOpenSuccessModal={setOpenSuccessModal}
          setErrorMessage={setErrorMessage}
          setOpenSnackBar={setOpenSnackBar}
        />
        <SuccessMessage
          openSuccessModal={openSuccessModal}
          setOpenSuccessModal={setOpenSuccessModal}
        />
        <Snackbar
          anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
          open={openSnackBar}
          autoHideDuration={3000}
          onClose={handleCloseSnackbar}
          color={'red'}
          // action={action}
        >
          <Alert
            onClose={handleCloseSnackbar}
            onClick={handleCloseSnackbar}
            severity="error"
            sx={{ width: '100%' }}
          >
            {errorMessage}
          </Alert>
        </Snackbar>
      </Container>
    </Box>
  );
};

export default Hero;
