import React from 'react';

import { Link } from '@mui/material';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Grid from '@mui/material/Grid';
import { useTheme } from '@mui/material/styles';
import Typography from '@mui/material/Typography';

const mock = [
  // {
  //   image:
  //     'https://firebasestorage.googleapis.com/v0/b/topibro-tech.appspot.com/o/minified-images%2Fwed-design-training-page-topibro.png?alt=media&token=91406ade-5485-44ba-97d6-c3fac7b914a7',
  //   column1: ['HTML', 'CSS'],
  //   column2: ['Bootstrap', 'Javascript'],
  //   title: 'Web Design',
  //   path: '/contact-us',
  //   alt: 'web design training topibrotech Ijegun Lagos',
  //   price: '30,000',
  // },
  {
    image:
      'https://firebasestorage.googleapis.com/v0/b/topibro-tech.appspot.com/o/minified-images%2Fweb-development-training-page-topibro.png?alt=media&token=3afecaf7-1252-41f2-8d5f-1df58c03e7dc',
    column1: ['Javascript', 'React'],
    column2: ['NodeJs', 'AWS'],
    title: 'Web Development',
    path: '/contact-us',
    alt: 'web development training topibrotech Ijegun Lagos',
    price: '40,000',
  },
  {
    image:
      'https://firebasestorage.googleapis.com/v0/b/topibro-tech.appspot.com/o/minified-images%2Fmobile-app-development-training-page-topibro.png?alt=media&token=98a28f79-6f4c-494f-a3b3-bec0d291626a',
    column1: ['ReactNative', 'Flutter'],
    column2: ['FireBase', 'Swift'],
    title: 'Mobile App Development',
    path: '/contact-us',
    alt: 'mobile app development training topibrotech Ijegun Lagos',
    price: '50,000',
  },
  {
    image:
      'https://firebasestorage.googleapis.com/v0/b/topibro-tech.appspot.com/o/minified-images%2Fdata%20analysis%20land.jpg?alt=media&token=3ccd030f-6165-40ab-a157-ebfafb4cccf8',
    column1: ['Excel', 'Sql'],
    column2: ['PowerBI', 'Python'],
    title: 'Data Analysis',
    path: '/contact-us',
    alt: 'data analysis development training topibrotech Ijegun Lagos',
    price: '40,000',
  },
  // {
  //   image:
  //     'https://firebasestorage.googleapis.com/v0/b/topibro-tech.appspot.com/o/minified-images%2Fgraphic%20desin%20lan.jpg?alt=media&token=ab6b002a-3f9e-425f-b97d-cdc221bb85a5',
  //   column1: ['PhotoShop', 'CorelDraw'],
  //   column2: ['Illustrator', 'Sketch'],
  //   title: 'Graphic Design',
  //   path: '/contact-us',
  //   alt: 'Graphic  design training topibrotech Ijegun Lagos',
  //   price: '20,000',
  // },
  // {
  //   image:
  //     'https://firebasestorage.googleapis.com/v0/b/topibro-tech.appspot.com/o/minified-images%2Fui%20ux%20lan%20(1).jpg?alt=media&token=13f5b825-d65f-42aa-be00-c69a7b989e0f',
  //   column1: ['Figma', 'AdobeXD'],
  //   column2: ['Sketch', 'WebFlow'],
  //   title: 'UI/UX Design',
  //   path: '/contact-us',
  //   alt: 'mobile app development training topibrotech Ijegun Lagos',
  //   price: '20,000',
  // },
];

const Platform = () => {
  const theme = useTheme();
  return (
    <Box
      sx={{
        marginBottom: 0,
      }}
    >
      <Typography variant={'h6'} fontWeight={700} gutterBottom marginTop={4}>
        Web Development & Data Analysis
      </Typography>
      <Grid container spacing={4}>
        {mock.map((item, i) => (
          <Grid item xs={12} md={4} xl={4} lg={4} key={i}>
            <Box
              component="a"
              href={item.path}
              display={'block'}
              width={'100%'}
              height={'100%'}
              margin="auto"
              sx={{
                textDecoration: 'none',
                transition: 'all .2s ease-in-out',
                '&:hover': {
                  transform: `translateY(-${theme.spacing(1 / 2)})`,
                },
              }}
            >
              <Box
                component={Card}
                width={'100%'}
                height={'100%'}
                display={'flex'}
                margin={'auto'}
                flexDirection={'column'}
              >
                <Box
                  sx={{
                    display: 'block',
                    height: { xs: 250, md: 200 },
                    margin: 'auto',
                    width: { xs: '100%', md: '100%', xl: '100%' },
                  }}
                >
                  <CardMedia
                    image={item.image}
                    title={item.title}
                    alt={item.alt}
                    sx={{
                      height: { xs: '100%', md: '100%' },
                      width: {
                        xl: '100%',
                        lg: '100%',
                        md: '100%',
                        sm: '115%',
                        xs: '120%',
                      },
                      margin: 'auto',
                      padding: -25,
                      filter:
                        theme.palette.mode === 'dark'
                          ? 'brightness(0.7)'
                          : 'none',
                    }}
                  />
                </Box>
                <Box component={CardContent}>
                  <Typography variant={'h6'} fontWeight={700} gutterBottom>
                    {item.title}
                  </Typography>
                  <Box
                    sx={{
                      display: 'flex',
                      flexDirection: 'row',
                      justifyContent: 'space-between',
                    }}
                  >
                    <Box>
                      {item.column1.map((course, index) => {
                        return (
                          <Grid container spacing={2} key={index}>
                            <Grid item xs={6}>
                              <Typography
                                variant={'body2'}
                                color="text.secondary"
                                sx={{
                                  width: 'fit-content',
                                  margin: 'auto',
                                }}
                              >
                                {course}
                              </Typography>
                            </Grid>
                          </Grid>
                        );
                      })}
                    </Box>
                    <Box>
                      {item.column2.map((course, index) => {
                        return (
                          <Grid container spacing={2} key={index}>
                            <Grid item xs={6}>
                              <Typography
                                variant={'body2'}
                                color="text.secondary"
                                sx={{
                                  width: 'fit-content',
                                  margin: 'auto',
                                }}
                              >
                                {course}
                              </Typography>
                            </Grid>
                          </Grid>
                        );
                      })}
                    </Box>
                  </Box>
                </Box>
                <Box flexGrow={1} />
                <Box
                  component={CardActions}
                  sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                  }}
                >
                  <Button
                    size="large"
                    endIcon={
                      <svg
                        width={16}
                        height={16}
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth={2}
                          d="M17 8l4 4m0 0l-4 4m4-4H3"
                        />
                      </svg>
                    }
                  >
                    <Link
                      component="a"
                      href={item.path}
                      sx={{
                        textDecoration: 'none',
                      }}
                    >
                      Monthly fee
                    </Link>
                  </Button>
                  <Typography
                    sx={{
                      backgroundColor: '#216d5b',
                      color: '#fff',
                      padding: 1,
                      fontFamily: 'Roboto, sans-serif',
                      borderRadius: 1,
                    }}
                  >
                    {item.price}
                  </Typography>
                </Box>
              </Box>
            </Box>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default Platform;
