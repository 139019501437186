import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from 'components/Container';
import NoteAddIcon from '@mui/icons-material/NoteAdd';
import BrushIcon from '@mui/icons-material/Brush';
import React from 'react';
import DeveloperModeIcon from '@mui/icons-material/DeveloperMode';

function HowItWorks() {
  const description = [
    {
      icon: <NoteAddIcon sx={{ fontSize: 70 }} />,
      title: 'Client specifications',
      paragraph: `We collect clients detailed specification which will serve as documentation for both developers and clients during the development life-cycle.`,
    },
    {
      icon: <BrushIcon sx={{ fontSize: 70 }} />,
      title: 'Planning and Designing',
      paragraph: `Our team carefully plan out a structure that would work based on your business requirements after which our designers will design the perfect layouts. `,
    },
    {
      icon: <DeveloperModeIcon sx={{ fontSize: 70 }} />,
      title: 'Development and testing',
      paragraph: `Here we bring the planning and designs to life, upon completion product will be subjected to multiple testing to ensure it meets with clients specification and requirements.`,
    },
  ];
  return (
    <Box
      backgroundColor="#016D5B"
      data-aos={'fade-up'}
      data-aos-delay={100}
      data-aos-offset={100}
      data-aos-duration={600}
      marginBottom={3}
    >
      <Container paddingBottom="0">
        <Typography
          variant="h3"
          sx={{
            fontWeight: 600,
            color: '#fff',
            textAlign: 'center',
          }}
        >
          How we work
        </Typography>
      </Container>
      <Container
        display="flex"
        xl={4}
        sm={12}
        xs={12}
        sx={{
          flexDirection: {
            xl: 'row',
            lg: 'row',
            xs: 'column',
          },
        }}
      >
        {description.map((describe, i) => (
          <Box
            display="flex"
            flexDirection="column"
            xl={4}
            sm={12}
            xs={12}
            justifyContent="center"
            textAlign="center"
            key={i}
            marginRight={2}
            padding={3}
            maxWidth={400}
          >
            <Typography color="#fff">{describe.icon}</Typography>
            <Typography
              variant="h5"
              color="#fff"
              fontWeight={600}
              sx={{ marginY: 3 }}
            >
              {describe.title}
            </Typography>
            <Typography variant="p" color="#fff">
              {describe.paragraph}
            </Typography>
          </Box>
        ))}
      </Container>
    </Box>
  );
}

export default HowItWorks;
