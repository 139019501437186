export const assets = {
  mobileDev: {
    herobackground:
      'https://firebasestorage.googleapis.com/v0/b/topibro-tech.appspot.com/o/minified-images%2FMobile%20Development%20hero%20background-min.png?alt=media&token=f35974f4-5a64-4d85-9526-22dc088c82a5',
    heroGirl:
      'https://firebasestorage.googleapis.com/v0/b/topibro-tech.appspot.com/o/minified-images%2Fmobile%20development-%20HERO%20GIRL%20image.png?alt=media&token=e2a18780-3b1a-4fd6-b324-3f455c9a19f3',
    workSmart:
      'https://firebasestorage.googleapis.com/v0/b/topibro-tech.appspot.com/o/minified-images%2Fmobile-app-development-image3-topibro.png?alt=media&token=d460be72-3c51-48d5-966c-010ddeb7650c',
    shareSection:
      'https://firebasestorage.googleapis.com/v0/b/topibro-tech.appspot.com/o/minified-images%2Fmobile-app-development-image2-topibro.png?alt=media&token=dbde239f-680d-47b3-a3d0-3920a88fdc8f',
    carousel1:
      'https://firebasestorage.googleapis.com/v0/b/topibro-tech.appspot.com/o/minified-images%2Ftopibro-carousel-training1.jpg?alt=media&token=536a9452-3b42-4252-a07c-341f5ea381be',
    carousel2:
      'https://firebasestorage.googleapis.com/v0/b/topibro-tech.appspot.com/o/minified-images%2Ftopibro-carousel-training2.jpg?alt=media&token=2ce7b7e5-b7a8-4421-a3d0-8b56cfb309d4',
    carousel3:
      'https://firebasestorage.googleapis.com/v0/b/topibro-tech.appspot.com/o/minified-images%2Ftopibro-carousel-training3.jpg?alt=media&token=155c88a2-c940-4624-9dd8-6633b033135e',
    carousel4:
      'https://firebasestorage.googleapis.com/v0/b/topibro-tech.appspot.com/o/minified-images%2Ftopibro-carousel-training4.jpg?alt=media&token=6afd2a06-7af4-49ce-95a9-186b86792620',
    carousel5:
      'https://firebasestorage.googleapis.com/v0/b/topibro-tech.appspot.com/o/minified-images%2Ftopibro-carousel-training5.jpg?alt=media&token=61254af1-d547-4ecc-ab84-0533480d7259',
  },
  indexView: {
    templateScreens: [
      {
        group: [
          {
            cover:
              'https://firebasestorage.googleapis.com/v0/b/topibro-tech.appspot.com/o/minified-images%2Fpreview10-topibro.jpeg?alt=media&token=590f44ec-dac7-491a-ab3c-62d0014780b6',

            coverDark:
              'https://firebasestorage.googleapis.com/v0/b/topibro-tech.appspot.com/o/minified-images%2Fpreview10-topibro.jpeg?alt=media&token=590f44ec-dac7-491a-ab3c-62d0014780b6',
          },
          {
            cover:
              'https://firebasestorage.googleapis.com/v0/b/topibro-tech.appspot.com/o/minified-images%2Fpreview11-topibro.jpg?alt=media&token=80f7dfd3-565a-4ba9-8da9-15499ea66b3a',
            coverDark:
              'https://firebasestorage.googleapis.com/v0/b/topibro-tech.appspot.com/o/minified-images%2Fpreview11-topibro.jpg?alt=media&token=80f7dfd3-565a-4ba9-8da9-15499ea66b3a',
          },
          {
            cover:
              'https://firebasestorage.googleapis.com/v0/b/topibro-tech.appspot.com/o/minified-images%2Fpreview5-topibro.jpg?alt=media&token=796f21a3-7cc6-461c-a724-fc5705489d0d',
            coverDark:
              'https://firebasestorage.googleapis.com/v0/b/topibro-tech.appspot.com/o/minified-images%2Fpreview5-topibro.jpg?alt=media&token=796f21a3-7cc6-461c-a724-fc5705489d0d',
          },
        ],
      },
      {
        group: [
          {
            cover:
              'https://firebasestorage.googleapis.com/v0/b/topibro-tech.appspot.com/o/minified-images%2Fpreview12-topibro.png?alt=media&token=ef61f843-9234-4675-9334-826e928eeaa9',
            coverDark:
              'https://firebasestorage.googleapis.com/v0/b/topibro-tech.appspot.com/o/minified-images%2Fpreview12-topibro.png?alt=media&token=ef61f843-9234-4675-9334-826e928eeaa9',
          },
          {
            cover:
              'https://firebasestorage.googleapis.com/v0/b/topibro-tech.appspot.com/o/minified-images%2Fpreview6-topibro.jpg?alt=media&token=ba3a9ddd-7695-4212-b7ba-1aedfdabe684',
            coverDark:
              'https://firebasestorage.googleapis.com/v0/b/topibro-tech.appspot.com/o/minified-images%2Fpreview6-topibro.jpg?alt=media&token=ba3a9ddd-7695-4212-b7ba-1aedfdabe684',
          },
          {
            cover:
              'https://firebasestorage.googleapis.com/v0/b/topibro-tech.appspot.com/o/minified-images%2Fpreview1-topibro.jpg?alt=media&token=8a4c912a-0b88-4c7a-8a9c-c9be3116189b',
            coverDark:
              'https://firebasestorage.googleapis.com/v0/b/topibro-tech.appspot.com/o/minified-images%2Fpreview1-topibro.jpg?alt=media&token=8a4c912a-0b88-4c7a-8a9c-c9be3116189b',
          },
          {
            cover:
              'https://firebasestorage.googleapis.com/v0/b/topibro-tech.appspot.com/o/minified-images%2Fpreview8-topibro.jpg?alt=media&token=2e98cbfb-76ce-4ba4-a0be-c9826376da56',
            coverDark:
              'https://firebasestorage.googleapis.com/v0/b/topibro-tech.appspot.com/o/minified-images%2Fpreview8-topibro.jpg?alt=media&token=2e98cbfb-76ce-4ba4-a0be-c9826376da56',
          },
        ],
      },
      {
        group: [
          {
            cover:
              'https://firebasestorage.googleapis.com/v0/b/topibro-tech.appspot.com/o/minified-images%2Fpreview2-topibro.jpg?alt=media&token=d986831f-cc4a-4071-a453-c5f90463b7f3',
            coverDark:
              'https://firebasestorage.googleapis.com/v0/b/topibro-tech.appspot.com/o/minified-images%2Fpreview2-topibro.jpg?alt=media&token=d986831f-cc4a-4071-a453-c5f90463b7f3',
          },
          {
            cover:
              'https://firebasestorage.googleapis.com/v0/b/topibro-tech.appspot.com/o/minified-images%2Fpreview9-topibro.jpg?alt=media&token=f0f2e26d-e193-4776-85cb-3b14a4f347d6',
            coverDark:
              'https://firebasestorage.googleapis.com/v0/b/topibro-tech.appspot.com/o/minified-images%2Fpreview9-topibro.jpg?alt=media&token=f0f2e26d-e193-4776-85cb-3b14a4f347d6',
          },
          {
            cover:
              'https://firebasestorage.googleapis.com/v0/b/topibro-tech.appspot.com/o/minified-images%2Fpreview11-topibro.jpg?alt=media&token=80f7dfd3-565a-4ba9-8da9-15499ea66b3a',
            coverDark:
              'https://firebasestorage.googleapis.com/v0/b/topibro-tech.appspot.com/o/minified-images%2Fpreview11-topibro.jpg?alt=media&token=80f7dfd3-565a-4ba9-8da9-15499ea66b3a',
          },
          {
            cover:
              'https://firebasestorage.googleapis.com/v0/b/topibro-tech.appspot.com/o/minified-images%2Fpreview5-topibro.jpg?alt=media&token=796f21a3-7cc6-461c-a724-fc5705489d0d',
            coverDark:
              'https://firebasestorage.googleapis.com/v0/b/topibro-tech.appspot.com/o/minified-images%2Fpreview5-topibro.jpg?alt=media&token=796f21a3-7cc6-461c-a724-fc5705489d0d',
          },
        ],
      },
    ],

    serviceSreens: [
      {
        image:
          'https://firebasestorage.googleapis.com/v0/b/topibro-tech.appspot.com/o/minified-images%2Fweb-design-home-topibro.webp?alt=media&token=cb446ea8-944e-4ad8-8d99-1f0c44242905',
        description: `We design a marketing website that attracts, engages,
           and converts visitors. When designing, we create the right combination of images, colors, fonts, space.`,
        title: 'Web Design',
        path: '/web-design',
        altText: 'topibrotech, web-design',
      },
      {
        image:
          'https://firebasestorage.googleapis.com/v0/b/topibro-tech.appspot.com/o/minified-images%2Fweb-development-home-topibro.jpeg?alt=media&token=596a55f9-ba59-472a-96b6-55fd3994299b',
        description: `We develop responsive, mobile-friendly, attractive, and easy-to-use websites.
           Our designers and developers aim to give your visitors a pleasant user experience.`,
        title: 'Web Development',
        path: '/web-development',
        altText: 'topibrotech, web development',
      },
      {
        image:
          'https://firebasestorage.googleapis.com/v0/b/topibro-tech.appspot.com/o/minified-images%2FMobile-App-Development-home-topibro.jpeg?alt=media&token=334de675-d3ca-4626-beec-d1eaadc97151',
        description: `Developing a mobile app for your business will help you establish a better connection with your customers. 
          We design mobile apps to increase customer satisfaction.`,
        title: 'Mobile App Development',
        path: '/mobile-app-development',
        altText: 'topibrotech, mobile development',
      },
      {
        image:
          'https://firebasestorage.googleapis.com/v0/b/topibro-tech.appspot.com/o/minified-images%2Ftraining-home-topibro.jpeg?alt=media&token=d9d91604-1aeb-4177-81c4-dc776278da17',
        description: `Becoming a professional, highly skilled, and trained web designer is all you need to succeed as an expert in web design, web, and mobile development.`,
        title: 'Training',
        path: '/training',
        altText: 'topibrotech, training',
      },
      {
        image:
          'https://firebasestorage.googleapis.com/v0/b/topibro-tech.appspot.com/o/minified-images%2Fcoworking-home-topibro.jpeg?alt=media&token=11ad36f6-785d-4f28-babb-c6657b3c9141',
        description: `Dedicated and regular office, meeting room suitable for entrepreneurs, start-ups, and freelancers. With light and internet in a convenient and affordable space.`,
        title: 'Co-working Space',
        path: 'coworkingspace',
        altText: 'topibrotech, coworking space',
      },
      {
        image:
          'https://firebasestorage.googleapis.com/v0/b/topibro-tech.appspot.com/o/minified-images%2Fseminar-event-home-topibro.jpg?alt=media&token=160dc032-6a40-4087-a560-26ed75340dbe',
        description: `Seminar and Events are organized at Topibro tech office hall to create a tech community and opportunity for developers to share ideas and resources`,
        title: 'Seminar / Events',
        path: 'seminar-event',
        altText: 'topibrotech, seminar events',
      },
    ],

    createWebsite: [
      'https://firebasestorage.googleapis.com/v0/b/topibro-tech.appspot.com/o/minified-images%2Fadvertisement_screen01.png?alt=media&token=f0a13074-78a6-43f0-a110-0aaf714023b9',
      'https://firebasestorage.googleapis.com/v0/b/topibro-tech.appspot.com/o/minified-images%2Fadvertisement_screen02.png?alt=media&token=61ff17d1-7dfe-42fd-9500-e1c0c69b6fb3',
      'https://firebasestorage.googleapis.com/v0/b/topibro-tech.appspot.com/o/minified-images%2Fadvertisement_screen03.png?alt=media&token=345e6556-62e7-4f62-b60c-6adbd8fb378f',
      'https://firebasestorage.googleapis.com/v0/b/topibro-tech.appspot.com/o/minified-images%2Fadvertisement_screen04.png?alt=media&token=2a0453dd-45c0-44cf-98ac-fece7efbf539',
      'https://firebasestorage.googleapis.com/v0/b/topibro-tech.appspot.com/o/minified-images%2Fadvertisement_screen05.png?alt=media&token=76c37f01-18e2-4704-b1cb-2f136ae7d8c5',
      'https://firebasestorage.googleapis.com/v0/b/topibro-tech.appspot.com/o/minified-images%2Fadvertisement_screen06.png?alt=media&token=ee83cf56-be79-4e2b-9a93-893cbe6bc662',
      'https://firebasestorage.googleapis.com/v0/b/topibro-tech.appspot.com/o/minified-images%2Fadvertisement_screen07.png?alt=media&token=e3086af9-4ca9-4860-b6f3-13a40d3991c1',
    ],

    teamPics: {
      ibrahim:
        'https://firebasestorage.googleapis.com/v0/b/topibro-tech.appspot.com/o/minified-images%2Fibrahim-homepage-topibro.jpeg?alt=media&token=5e82ffd2-733a-4b70-a8e4-ae4af44b7ce3',
      fabiyi:
        'https://firebasestorage.googleapis.com/v0/b/topibro-tech.appspot.com/o/minified-images%2Ffabiyi_johnson.jpg?alt=media&token=6fbb9b66-378b-4dfa-849c-59a2e5b9f7fa',

      anita:
        'https://firebasestorage.googleapis.com/v0/b/topibro-tech.appspot.com/o/minified-images%2Fnkem_anita.jpg?alt=media&token=f94422da-c33f-4fd5-996e-1e5b10553c45',

      saheed:
        'https://firebasestorage.googleapis.com/v0/b/topibro-tech.appspot.com/o/minified-images%2FofficeShot.jpg?alt=media&token=12164b70-af97-4ed1-b8c1-015cc2a5d8ae',

      joshua:
        'https://firebasestorage.googleapis.com/v0/b/topibro-tech.appspot.com/o/minified-images%2FJoshua.png?alt=media&token=574ced22-81e0-4e1a-97a9-b8b43f646c90',

      kennedy:
        'https://firebasestorage.googleapis.com/v0/b/topibro-tech.appspot.com/o/minified-images%2FKennedy%20(1).jpg?alt=media&token=0c38d1f3-7a73-4de8-8f46-4383b35e0c06',

      george:
        'https://firebasestorage.googleapis.com/v0/b/topibro-tech.appspot.com/o/minified-images%2FGeorge-2.jpg?alt=media&token=8cd80c2b-d6ff-4cec-a16e-c70d1ebf0da8',

      osinakachi:
        'https://firebasestorage.googleapis.com/v0/b/topibro-tech.appspot.com/o/minified-images%2Fosi-homepage-topibro.jpeg?alt=media&token=f2ea64cb-9cb6-4d3d-93b9-33105b59f8b0',

      maurice:
        'https://firebasestorage.googleapis.com/v0/b/topibro-tech.appspot.com/o/minified-images%2Fmaurice.jpg?alt=media&token=d29ea373-2036-4716-a2ca-20a17a3b05ff',

      blessing:
        'https://firebasestorage.googleapis.com/v0/b/topibro-tech.appspot.com/o/minified-images%2Fblessing.jpg?alt=media&token=9718f8b8-e6e8-4244-b67a-25eacb822179',

      favour:
        'https://firebasestorage.googleapis.com/v0/b/topibro-tech.appspot.com/o/minified-images%2Ffavour.jpg?alt=media&token=fac0c271-002b-4512-a7e1-bbc6386a8d4d',

      ebuka:
        'https://firebasestorage.googleapis.com/v0/b/topibro-tech.appspot.com/o/minified-images%2Febuka.jpg?alt=media&token=9bb54551-7b17-4f58-8d36-6f2d84e02fca',
    },

    partner: [
      'https://assets.maccarianagency.com/svg/logos/airbnb-original.svg',
      'https://assets.maccarianagency.com/svg/logos/amazon-original.svg',
      'https://assets.maccarianagency.com/svg/logos/fitbit-original.svg',
      'https://assets.maccarianagency.com/svg/logos/netflix-original.svg',
      'https://assets.maccarianagency.com/svg/logos/google-original.svg',
      'https://assets.maccarianagency.com/svg/logos/paypal-original.svg',
      'https://assets.maccarianagency.com/svg/logos/hubspot-original.svg',
      'https://assets.maccarianagency.com/svg/logos/mapbox-original.svg',
      'https://assets.maccarianagency.com/svg/logos/slack-original.svg',
    ],

    technologies1: [
      {
        logo: 'https://firebasestorage.googleapis.com/v0/b/topibro-tech.appspot.com/o/assets%2Fsvg%2Fhtml5.svg?alt=media&token=2585715f-ea4a-4f4a-8b49-4df801c6ac85',
        name: 'HTML%',
      },
      {
        logo: 'https://firebasestorage.googleapis.com/v0/b/topibro-tech.appspot.com/o/assets%2Fsvg%2Fjavascript.svg?alt=media&token=7f753a57-db47-497a-8d1c-b4de92acb33b',
        name: 'JS',
      },
      {
        logo: 'https://firebasestorage.googleapis.com/v0/b/topibro-tech.appspot.com/o/assets%2Fsvg%2Fnodejs.svg?alt=media&token=d37520f4-2751-4ba0-b281-14d81bb71891',
        name: 'NODEJS',
      },
      {
        logo: 'https://firebasestorage.googleapis.com/v0/b/topibro-tech.appspot.com/o/assets%2Fsvg%2Ftypescript.svg?alt=media&token=4a976f75-7ac8-4cca-8eda-07534b8f5b20',
        name: 'TS',
      },
      {
        logo: 'https://firebasestorage.googleapis.com/v0/b/topibro-tech.appspot.com/o/assets%2Fsvg%2Fslack.svg?alt=media&token=8525e87e-003d-4a12-afa9-085ff76909fa',
        name: 'SLACK',
      },
      {
        logo: 'https://firebasestorage.googleapis.com/v0/b/topibro-tech.appspot.com/o/assets%2Fsvg%2Fmui.svg?alt=media&token=d199f8a3-2cd3-457a-87d6-17fc22032d5a',
        name: 'MUI',
      },
    ],

    technologies2: [
      {
        logo: 'https://firebasestorage.googleapis.com/v0/b/topibro-tech.appspot.com/o/assets%2Fsvg%2Ffirebase.svg?alt=media&token=b53977eb-0735-4668-80ce-b71d1165cc9a',
        name: 'FIREBASE',
      },
      {
        logo: 'https://firebasestorage.googleapis.com/v0/b/topibro-tech.appspot.com/o/assets%2Fsvg%2Freact.svg?alt=media&token=8c0855f1-9a4b-41e2-9dc3-50bb16762488',
        name: 'REACT',
      },
      {
        logo: 'https://firebasestorage.googleapis.com/v0/b/topibro-tech.appspot.com/o/assets%2Fsvg%2Freact-native.svg?alt=media&token=40b86174-323f-480c-8b9c-748607bac90a',
        name: 'REACT_NATIVE',
      },
      {
        logo: 'https://firebasestorage.googleapis.com/v0/b/topibro-tech.appspot.com/o/assets%2Fsvg%2Fcss3.svg?alt=media&token=54fd69b9-d376-4088-b0d8-9e386881bc0d',
        name: 'CSS',
      },
      {
        logo: 'https://firebasestorage.googleapis.com/v0/b/topibro-tech.appspot.com/o/assets%2Fsvg%2Fnext.svg?alt=media&token=91bf0cef-aae8-4967-8e7e-8f2c75bda64a',
        name: 'NEXT',
      },
      {
        logo: 'https://firebasestorage.googleapis.com/v0/b/topibro-tech.appspot.com/o/assets%2Fsvg%2Fvsc.svg?alt=media&token=772211fd-e029-44db-852b-0c490bb5a927',
        name: 'VSC',
      },
    ],
    faruk:
      'https://firebasestorage.googleapis.com/v0/b/topibro-tech.appspot.com/o/minified-images%2Ffaruk-topibro.jpeg?alt=media&token=0b663904-31cf-4323-a8c0-dda6aceafb25',
  },

  webDevelopment: {
    greenHeroBackground:
      'https://firebasestorage.googleapis.com/v0/b/topibro-tech.appspot.com/o/minified-images%2Fweb%20dev%20and%20design%20hero%20background.png?alt=media&token=fb3162a9-d41c-4180-a8b1-e83d99a53261',
    animationImage:
      'https://firebasestorage.googleapis.com/v0/b/topibro-tech.appspot.com/o/minified-images%2FWeb%20development%20hero%20Image.png?alt=media&token=ab3065dd-a358-4c23-8116-1165f6612b41',
    whatYouGet:
      'https://firebasestorage.googleapis.com/v0/b/topibro-tech.appspot.com/o/minified-images%2Fweb-dev-image-topibro.jpeg?alt=media&token=81bd167e-7790-4b2c-bfe7-6e07fac0d943',
    whyYouNeedimage:
      'https://firebasestorage.googleapis.com/v0/b/topibro-tech.appspot.com/o/minified-images%2Fweb-dev-image2-topibro.jpeg?alt=media&token=b3aa5364-5f33-4d2b-8ee4-644d238fedbd',
    standOutImage:
      'https://assets.maccarianagency.com/svg/illustrations/drawkit-illustration1.svg',
    coreServices:
      'https://assets.maccarianagency.com/svg/illustrations/drawkit-illustration2.svg',
  },
  webDesign: {
    greenHeroBackground:
      'https://firebasestorage.googleapis.com/v0/b/topibro-tech.appspot.com/o/minified-images%2Fweb%20dev%20and%20design%20hero%20background.png?alt=media&token=fb3162a9-d41c-4180-a8b1-e83d99a53261',
    designHeroImage:
      'https://firebasestorage.googleapis.com/v0/b/topibro-tech.appspot.com/o/minified-images%2Fweb%20design%20girl%20image.png?alt=media&token=58e98b75-060e-4d1b-98c6-6dae84b56f6b',
    layoutImage:
      'https://firebasestorage.googleapis.com/v0/b/topibro-tech.appspot.com/o/minified-images%2Fcoworking%20(1).png?alt=media&token=09fb0ac7-c61a-4f7c-ad2b-d3b3d161b6d1',
    galleryImage: [
      {
        src: 'https://firebasestorage.googleapis.com/v0/b/topibro-tech.appspot.com/o/minified-images%2Fgallery-1-web-design-topibro.jpg?alt=media&token=ac05446c-d386-4f11-962b-e60e4a702488',
        rows: 1,
        cols: 2,
      },
      {
        src: 'https://firebasestorage.googleapis.com/v0/b/topibro-tech.appspot.com/o/minified-images%2Fgallery-2-web-design-topibro.jpg?alt=media&token=047f9e2e-0138-4b66-b312-1139a91325c9',
        rows: 1,
        cols: 1,
      },
      {
        src: 'https://firebasestorage.googleapis.com/v0/b/topibro-tech.appspot.com/o/minified-images%2Fgallery-3-web-design-topibro.jpg?alt=media&token=35613b3a-1a2c-4d64-92dd-1d059cf15887',
        rows: 1,
        cols: 1,
      },
      {
        src: 'https://firebasestorage.googleapis.com/v0/b/topibro-tech.appspot.com/o/minified-images%2Fgallery-4-web-design-topibro.jpg?alt=media&token=86162e13-5c96-493e-b538-97a6572533f1',
        rows: 1,
        cols: 2,
      },
    ],

    principlesMan:
      'https://firebasestorage.googleapis.com/v0/b/topibro-tech.appspot.com/o/minified-images%2Fweb-design-review-2-topibro.jpeg?alt=media&token=4db5a29c-21b3-4fdc-b03f-35bcc9cf28ff',
    principlesLaptop:
      'https://firebasestorage.googleapis.com/v0/b/topibro-tech.appspot.com/o/minified-images%2Fprinciples-of-good-website-design-topibro.jpeg?alt=media&token=b0a27058-f6eb-4c09-8306-9ec92116e32c',
    mobilePhone:
      'https://firebasestorage.googleapis.com/v0/b/topibro-tech.appspot.com/o/minified-images%2Fprinciples-of-good-mobile-app-design-topibro.jpeg?alt=media&token=bbfb523a-f906-4870-9d2d-5042e6962f58',
    priinciplesWoman:
      'https://firebasestorage.googleapis.com/v0/b/topibro-tech.appspot.com/o/minified-images%2Fweb-design-review-1-topibro.jpeg?alt=media&token=49947d55-6031-46e6-94d3-88c1f92feabd',
  },

  trainingSection: {
    waterDropBackground:
      'https://firebasestorage.googleapis.com/v0/b/topibro-tech.appspot.com/o/minified-images%2Ftrainingbackground.jpeg?alt=media&token=04169c31-23f1-4371-a7ec-e334cf41d7fc',
    heroImage:
      'https://firebasestorage.googleapis.com/v0/b/topibro-tech.appspot.com/o/minified-images%2Ftopibrotraininghero%20(1).png?alt=media&token=3d465c41-c2e5-4902-8638-eca74f78ac1e',
    trainingImage:
      'https://firebasestorage.googleapis.com/v0/b/topibro-tech.appspot.com/o/minified-images%2Ftraningdescription%20(1).png?alt=media&token=d99ecb21-d376-4019-b281-07977861a97e',

    coursesImage: [
      {
        image:
          'https://firebasestorage.googleapis.com/v0/b/topibro-tech.appspot.com/o/minified-images%2Fwed-design-training-page-topibro.png?alt=media&token=91406ade-5485-44ba-97d6-c3fac7b914a7',
        column1: ['HTML', 'CSS'],
        column2: ['Bootstrap', 'Javascript'],
        title: 'Web Design',
        path: '/contact-us',
        alt: 'web design training topibrotech Ijegun Lagos',
      },
      {
        image:
          'https://firebasestorage.googleapis.com/v0/b/topibro-tech.appspot.com/o/minified-images%2Fweb-development-training-page-topibro.png?alt=media&token=3afecaf7-1252-41f2-8d5f-1df58c03e7dc',
        column1: ['Javascript', 'React'],
        column2: ['NodeJs', 'AWS'],
        title: 'Web Development',
        path: '/contact-us',
        alt: 'web development training topibrotech Ijegun Lagos',
      },
      {
        image:
          'https://firebasestorage.googleapis.com/v0/b/topibro-tech.appspot.com/o/minified-images%2Fmobile-app-development-training-page-topibro.png?alt=media&token=98a28f79-6f4c-494f-a3b3-bec0d291626a',
        column1: ['ReactNative', 'Flutter'],
        column2: ['FireBase', 'Swift'],
        title: 'Mobile App Development',
        path: '/contact-us',
        alt: 'mobile app development training topibrotech Ijegun Lagos',
      },
    ],
  },

  coworkingSpace: {
    heroImage1:
      'https://firebasestorage.googleapis.com/v0/b/topibro-tech.appspot.com/o/minified-images%2Fcoworking%20space%20topibro%20carousel.jpeg?alt=media&token=7344b4f2-7cc2-40cf-9bec-076bf6cd3c65',
    heroImage2:
      'https://firebasestorage.googleapis.com/v0/b/topibro-tech.appspot.com/o/minified-images%2Ftopibro-coworking%20space%20minified.jpeg?alt=media&token=1e4ad8a1-778f-4257-9db3-21386363d912',
    heroImage3:
      'https://firebasestorage.googleapis.com/v0/b/topibro-tech.appspot.com/o/minified-images%2Fcoworking%20space%20topibro%20minified%202.jpeg?alt=media&token=6b37c49d-119b-4c88-8d38-cbf38ca15ccc',
    heroImage4:
      'https://firebasestorage.googleapis.com/v0/b/topibro-tech.appspot.com/o/minified-images%2Ftopibro%20tech%20coworking%20space%2012.jpeg?alt=media&token=868c7f5d-60a6-43d8-922b-74433cbee7ea',
    description1:
      'https://firebasestorage.googleapis.com/v0/b/topibro-tech.appspot.com/o/minified-images%2Ftopibro%20tech%20-%20coworking%20space.jpeg?alt=media&token=ba042fc3-c3b2-46d2-9a6a-6d7ae4809cda',
    description2:
      'https://firebasestorage.googleapis.com/v0/b/topibro-tech.appspot.com/o/minified-images%2Ftopibro%20tech%20coworking%20space%2012.jpeg?alt=media&token=868c7f5d-60a6-43d8-922b-74433cbee7ea',
    benefits1:
      'https://firebasestorage.googleapis.com/v0/b/topibro-tech.appspot.com/o/minified-images%2Ftopibro-coworking%20space%20minified.jpeg?alt=media&token=1e4ad8a1-778f-4257-9db3-21386363d912',
    paymentImage1:
      'https://firebasestorage.googleapis.com/v0/b/topibro-tech.appspot.com/o/minified-images%2Ftopibro%20tech%20co%20working%20space.jpeg?alt=media&token=b2d9322e-6623-45df-a577-df9b49c83679',
    paymentImage2: 'https://assets.maccarianagency.com/backgrounds/img2.jpg',
    paymentImage3: 'https://assets.maccarianagency.com/backgrounds/img4.jpg',
  },

  aboutUsSection: {
    heroBackround:
      'https://firebasestorage.googleapis.com/v0/b/topibro-tech.appspot.com/o/minified-images%2Faboutus-hero-topibro.jpeg?alt=media&token=4c5853fa-bf3a-455d-9d78-d801386c1160',
    storyImage:
      'https://assets.maccarianagency.com/svg/illustrations/drawkit-illustration1.svg',
    whoWeAre:
      'https://firebasestorage.googleapis.com/v0/b/topibro-tech.appspot.com/o/minified-images%2Faboutgroup.png?alt=media&token=d1072af9-599b-4d34-9c07-b1b79f1550bd',
  },

  eventSection: {
    imageBackground: 'https://assets.maccarianagency.com/backgrounds/img19.jpg',
    heroImage:
      'https://firebasestorage.googleapis.com/v0/b/topibro-tech.appspot.com/o/minified-images%2Ftopibro%20technology%20-%20Tech%20n%20Chills.png?alt=media&token=f925d918-846f-4bd2-a08c-87789d2d6cd9',
    aboutImage1:
      'https://firebasestorage.googleapis.com/v0/b/topibro-tech.appspot.com/o/minified-images%2Ftopibro%20tech%20seminar.jpeg?alt=media&token=880fab3e-d9b6-4331-801b-24b481d2211c',
    eventImage2:
      'https://firebasestorage.googleapis.com/v0/b/topibro-tech.appspot.com/o/minified-images%2FtNc%20attendees%20(1).jpg?alt=media&token=ad91828f-a409-4305-b09f-bb41f7d338c3',
  },

  blogSection: {
    heroBackground:
      'https://firebasestorage.googleapis.com/v0/b/topibro-tech.appspot.com/o/minified-images%2Ftnc%20community%20(1).jpg?alt=media&token=cfa3eac8-86b5-4d9a-81c9-2ca47e48ae5f',
    tnc: 'https://firebasestorage.googleapis.com/v0/b/topibro-tech.appspot.com/o/minified-images%2Ftopibro%20technology%20-%20Tech%20n%20Chills.png?alt=media&token=f925d918-846f-4bd2-a08c-87789d2d6cd9',
    avatar:
      'https://firebasestorage.googleapis.com/v0/b/topibro-tech.appspot.com/o/minified-images%2Ftopibro-logo2.png?alt=media&token=a27cd13f-8d1e-45b5-a191-b0ecde94bb28',
    story1:
      'https://firebasestorage.googleapis.com/v0/b/topibro-tech.appspot.com/o/minified-images%2FtNc%20group%202%20(1).jpg?alt=media&token=87c361c6-9781-4eb4-8ff5-01bf73477d28',
    story2:
      'https://firebasestorage.googleapis.com/v0/b/topibro-tech.appspot.com/o/tit-z2h.png?alt=media&token=01b6d61f-4d0d-4c31-9934-fa4ae2ab29b8',
    caseStudy1: 'https://assets.maccarianagency.com/backgrounds/img2.jpg',
    casestudy2: 'https://assets.maccarianagency.com/backgrounds/img3.jpg',
  },
};
