import React from 'react';
import { useTheme } from '@mui/material/styles';

const GoogleMap = () => {
  const theme = useTheme();
  return (
    <iframe
      width="100%"
      height="100%"
      frameBorder="0"
      title="map"
      marginHeight={0}
      marginWidth={0}
      scrolling="no"
      src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3964.0352435192153!2d3.2552369147706925!3d6.5172231952858795!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x103b8f9275664aa1%3A0xc7e3b525fa32babb!2sTopibro%20Technologies%3A%20Web%20design%20%2FMobile%20App%20Development%20%2F!5e0!3m2!1sen!2sng!4v1648206974762!5m2!1sen!2sng"
      style={{
        minHeight: 300,
        filter:
          theme.palette.mode === 'dark'
            ? 'grayscale(0.5) opacity(0.7)'
            : 'none',
      }}
    />
  );
};

export default GoogleMap;
