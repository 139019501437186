import React from 'react';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import CheckIcon from '@mui/icons-material/Check';
const WhatYouWillLearn = () => {
  return (
    <Paper
      sx={{
        display: 'flex',
        flexDirection: 'column',
        p: 5,
        m: 2,
        border: '1px solid gray',
        borderRadius: 0,
      }}
    >
      <Box sx={{ mb: 3 }}>
        <Typography variant="h5">What you will learn</Typography>
      </Box>
      <Box
        sx={{
          display: 'flex',
          mt: 4,
          flexDirection: { xs: 'column', md: 'row' },
        }}
      >
        <Box sx={{ display: 'flex', mt: 2 }}>
          <CheckIcon sx={{ mr: 3, color: 'primary.main' }} />
          <Typography sx={{ fontSize: 15 }}>
            Create their own HTML and CSS Templates
          </Typography>
        </Box>
        <Box sx={{ display: 'flex', mt: 2 }}>
          <CheckIcon sx={{ mr: 3, color: 'primary.main' }} />
          <Typography sx={{ fontSize: 15 }}>
            Become an experienced web designer
          </Typography>
        </Box>
        <Box sx={{ display: 'flex', mt: 2 }}>
          <CheckIcon sx={{ mr: 3, color: 'primary.main' }} />
          <Typography sx={{ fontSize: 15 }}>
            Building awesome responsive websites
          </Typography>
        </Box>
      </Box>
    </Paper>
  );
};
export default WhatYouWillLearn;
