import React from 'react';

import Container from 'components/Container';
// import Navbar from './components/Topbar/Navbar';
import { Main } from 'layouts';
import Box from '@mui/material/Box';
import Courses from './components/Courses';
import Hero from './components/Hero';
import Mentee from './components/Mentee';
import Creator from './components/Creator';

// useEffect(() => {
//   const jarallaxInit = async () => {
//     const jarallaxElems = document.querySelectorAll('.jarallax');
//     if (!jarallaxElems || (jarallaxElems && jarallaxElems.length === 0)) {
//       return;
//     }

//     const { jarallax } = await import('jarallax');
//     jarallax(jarallaxElems, { speed: 0.2 });
//   };

//   jarallaxInit();
// });
// const theme = useTheme();

const OnlinePlatform = () => {
  return (
    <Main>
      <Box>
        <Hero />
      </Box>
      <Box>
        <Container>
          <Courses />
        </Container>
        <Container paddingTop={'0 !important'}>
          <Mentee />
        </Container>
        <Container paddingTop={'0 !important'}>
          <Creator />
        </Container>
      </Box>
    </Main>
  );
};

export default OnlinePlatform;
