import Box from '@mui/material/Box';
import { useTheme } from '@mui/material/styles';
import Container from 'components/Container';
import Main from 'layouts/Main';
import React, { useEffect } from 'react';
import { webDesignViewHelmet } from 'components/useHelmet';
import { Helmet } from 'react-helmet';
import {
  CaseStudy2,
  CaseStudy3,
  Description,
  Form,
  Galleries,
  GetStarted,
  Hero,
  OurDesigns,
} from './components';

const WebDesign = () => {
  const theme = useTheme();
  useEffect(() => {
    const jarallaxInit = async () => {
      const jarallaxElems = document.querySelectorAll('.jarallax');
      if (!jarallaxElems || (jarallaxElems && jarallaxElems.length === 0)) {
        return;
      }

      const { jarallax } = await import('jarallax');
      jarallax(jarallaxElems, { speed: 0.2 });
    };

    jarallaxInit();
  });
  const styles = (bgImage) => ({
    position: 'absolute',
    objectFit: 'cover',
    fontFamily: 'object-fit: cover;',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    zIndex: -1,
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    backgroundPosition: 'center center',
    backgroundImage: `url(${bgImage})`,
    filter: theme.palette.mode === 'dark' ? 'brightness(0.7)' : 'none',
  });
  return (
    <Main colorInvert={true}>
      <Helmet>
        <title>{webDesignViewHelmet.title}</title>
        <meta
          name="description"
          content={webDesignViewHelmet.metaDescription1}
        />
        <meta name="keywords" content={webDesignViewHelmet.metaKeyWords} />
        <meta name="twitter:card" content="summary_large_image" />
        <meta
          name="twitter:site"
          content={webDesignViewHelmet.twitterCards.site}
        />
        <meta
          name="twitter:title"
          content={webDesignViewHelmet.twitterCards.title}
        />
        <meta
          name="twitter:description"
          content={webDesignViewHelmet.twitterCards.description}
        />
        <meta
          name="twitter:image"
          content={webDesignViewHelmet.twitterCards.image}
        />
        <meta
          property="og:site_name"
          content={webDesignViewHelmet.ogp.site_name}
        />
        <meta property="og:type" content={webDesignViewHelmet.ogp.type} />
        <meta property="og:title" content={webDesignViewHelmet.ogp.title} />
        <meta
          property="og:description"
          content={webDesignViewHelmet.ogp.description}
        />
        <meta property="og:url" content={webDesignViewHelmet.ogp.url} />
        <meta property="og:image" content={webDesignViewHelmet.ogp.image} />
        <meta property="og:locale" content={webDesignViewHelmet.ogp.locale} />
      </Helmet>
      <Box>
        <Hero />
      </Box>
      <Container>
        <Description />
      </Container>
      <Box>
        <OurDesigns />
      </Box>
      <Container>
        <Galleries />
      </Container>
      <Container paddingY={'0 !important'}>
        <CaseStudy2 />
      </Container>
      <Container>
        <CaseStudy3 />
      </Container>
      <Box
        position={'relative'}
        sx={{
          backgroundColor: theme.palette.alternate.main,
        }}
      >
        <Box
          paddingY={2}
          className={'jarallax'}
          data-jarallax
          data-speed="0.2"
          position={'relative'}
          minHeight={'100vh'}
          display={'flex'}
          alignItems={'center'}
          id="agency__portfolio-item--js-scroll"
        >
          <Box
            className={'jarallax-img'}
            sx={styles(
              'https://firebasestorage.googleapis.com/v0/b/topibro-tech.appspot.com/o/minified-images%2Fweb-design-jarallax-image-topibro.jpeg?alt=media&token=b2f65bd7-88d8-4fd5-8b17-15abb9910c7d',
            )}
          />
          <Box
            sx={{
              backgroundColor:
                theme.palette.alternate.main === '#1a2138' ? '#000' : '#016d5b',
              opacity: 0.5,
              height: '100%',
              zIndex: 0,
              position: 'absolute',
              width: '100%',
              top: 0,
            }}
          />
          <Container
            sx={{
              filter: 'brightness(1)',
            }}
          >
            <Form />
          </Container>
        </Box>
        <Box
          position={'relative'}
          sx={{
            backgroundColor: theme.palette.alternate.main,
          }}
        >
          <Box>
            <Container paddingY={5}>
              <GetStarted />
            </Container>
          </Box>
          <Box
            component={'svg'}
            preserveAspectRatio="none"
            xmlns="http://www.w3.org/2000/svg"
            x="0px"
            y="0px"
            viewBox="0 0 1920 100.1"
            sx={{
              width: '100%',
              marginBottom: theme.spacing(-1),
            }}
          >
            <path
              fill={theme.palette.background.paper}
              d="M0,0c0,0,934.4,93.4,1920,0v100.1H0L0,0z"
            ></path>
          </Box>
        </Box>
        <Box
          component={'svg'}
          preserveAspectRatio="none"
          xmlns="http://www.w3.org/2000/svg"
          x="0px"
          y="0px"
          viewBox="0 0 1920 100.1"
          sx={{
            width: '100%',
            marginBottom: theme.spacing(-1),
          }}
        >
          <path
            fill={theme.palette.background.paper}
            d="M0,0c0,0,934.4,93.4,1920,0v100.1H0L0,0z"
          ></path>
        </Box>
      </Box>
    </Main>
  );
};

export default WebDesign;
