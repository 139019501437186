import Box from '@mui/material/Box';
import { alpha, useTheme } from '@mui/material/styles';
// import useMediaQuery from '@mui/material/useMediaQuery';
import { assets } from 'assets/assets';
import Container from 'components/Container';
import Main from 'layouts/Main';
import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { indexViewHelmet } from '../../components/useHelmet';
import {
  Features,
  GetStarted,
  Goby,
  Hero,
  MainCards,
  Partners,
  Services,
  Team,
  // Partners,
  Technologies,
} from './components';

const IndexView = () => {
  const theme = useTheme();
  // const matches = useMediaQuery('(min-width:800px)');
  useEffect(() => {
    const jarallaxInit = async () => {
      const jarallaxElems = document.querySelectorAll('.jarallax');
      if (!jarallaxElems || (jarallaxElems && jarallaxElems.length === 0)) {
        return;
      }

      const { jarallax } = await import('jarallax');
      jarallax(jarallaxElems, { speed: 0.2 });
    };

    jarallaxInit();
  });

  const styles = (bgImage) => ({
    position: 'absolute',
    objectFit: 'cover',
    /* support for plugin https://github.com/bfred-it/object-fit-images */
    fontFamily: 'object-fit: cover;',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    zIndex: -1,
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    backgroundPosition: 'center center',
    backgroundImage: `url(${bgImage})`,
    filter: theme.palette.mode === 'dark' ? 'brightness(0.7)' : 'none',
  });

  const parallaxImages = assets.indexView.createWebsite;

  return (
    <Box sx={{ overflowX: 'hidden' }}>
      <Main bgcolor={'background.paper'}>
        <Helmet>
          <title>{indexViewHelmet.title}</title>
          <meta name="description" content={indexViewHelmet.metaDescription1} />
          <meta name="keywords" content={indexViewHelmet.metaKeyWords} />
          <meta name="twitter:card" content="summary_large_image" />
          <meta
            name="twitter:site"
            content={indexViewHelmet.twitterCards.site}
          />
          <meta
            name="twitter:title"
            content={indexViewHelmet.twitterCards.title}
          />
          <meta
            name="twitter:description"
            content={indexViewHelmet.twitterCards.description}
          />
          <meta
            name="twitter:image"
            content={indexViewHelmet.twitterCards.image}
          />
          <meta
            property="og:site_name"
            content={indexViewHelmet.ogp.site_name}
          />
          <meta property="og:type" content={indexViewHelmet.ogp.type} />
          <meta property="og:title" content={indexViewHelmet.ogp.title} />
          <meta
            property="og:description"
            content={indexViewHelmet.ogp.description}
          />
          <meta property="og:url" content={indexViewHelmet.ogp.url} />
          <meta property="og:image" content={indexViewHelmet.ogp.image} />
          <meta property="og:locale" content={indexViewHelmet.ogp.locale} />
        </Helmet>
        <Hero />
        <Container>
          <Services />
        </Container>
        <Container>
          <MainCards />
        </Container>
        <Container>
          <Technologies />
        </Container>
        <Box
          component={'a'}
          // target={'_blank'}
          // href={'https://fox-trailer.firebaseapp.com/'}
          className={'jarallax'}
          data-jarallax
          data-speed="0.2"
          position={'relative'}
          minHeight={'100vh'}
          display={'flex'}
          alignItems={'center'}
          id="agency__portfolio-item--js-scroll"
          style={{
            textDecoration: 'none',
          }}
        >
          <Box flexDirection={'row'} flexWrap={'wrap'}>
            {parallaxImages.map((item, index) => (
              <Box
                key={index + 1}
                className={'jarallax-img'}
                sx={styles(item)}
              />
            ))}
          </Box>

          <Box
            component={'div'}
            minHeight={'100vh'}
            style={{
              position: 'absolute',
              left: 0,
              right: 0,
              backgroundColor: alpha(theme.palette.common.black, 0.5),
            }}
          />
          <Container style={{ zIndex: 1 }}>
            <Goby />
          </Container>
        </Box>
        <Container>
          <Team />
        </Container>
        <Container maxWidth={1}>
          <Partners />
        </Container>
        <Box
          sx={{
            backgroundImage: `linear-gradient(to bottom, ${alpha(
              theme.palette.background.paper,
              0,
            )}, ${alpha(theme.palette.alternate.main, 1)} 100%)`,
            backgroundRepeat: 'repeat-x',
            position: 'relative',
          }}
        >
          <Container>
            <Features />
          </Container>
          <Box
            component={'svg'}
            preserveAspectRatio="none"
            xmlns="http://www.w3.org/2000/svg"
            x="0px"
            y="0px"
            viewBox="0 0 1920 100.1"
            sx={{
              width: '100%',
              marginBottom: theme.spacing(-1),
            }}
          >
            <path
              fill={theme.palette.background.paper}
              d="M0,0c0,0,934.4,93.4,1920,0v100.1H0L0,0z"
            ></path>
          </Box>
        </Box>
        <Container>
          <GetStarted />
        </Container>
      </Main>
    </Box>
  );
};

export default IndexView;
