import React from 'react';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import ElectricBoltIcon from '@mui/icons-material/ElectricBolt';
import { IoIosPeople } from 'react-icons/io';
import CellWifiIcon from '@mui/icons-material/CellWifi';
import LaptopIcon from '@mui/icons-material/Laptop';
import Grid from '@mui/material/Grid';

const Benefits = () => {
  const styling = {
    fieldset: { border: '5px solid #016D5B', padding: '20px' },
    coworking: {
      color: '#216d5b',
      fontSize: 70,
      marginBottom: 0,
      marginTop: 5,
    },
  };
  return (
    <Container sx={{ marginTop: { xl: 10, lg: 10, md: 25, sm: 30, xs: 12 } }}>
      <fieldset style={styling.fieldset}>
        <legend>
          <Typography
            variant="h4"
            sx={{
              fontWeight: 500,
              fontFamily: 'Roboto, sans-serif',
            }}
          >
            Features & Benefits
          </Typography>
        </legend>
        <Box
          sx={{
            display: {
              xl: 'flex',
              lg: 'flex',
              md: 'flex',
              sm: 'column',
              xs: 'column',
            },
            justifyContent: 'space-around',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
              marginX: 2,
              marginY: { xl: 2, lg: 2, md: 5, sm: 5, xs: 5 },
            }}
          >
            <ElectricBoltIcon
              sx={{
                border: '2px solid #016D5B',
                borderRadius: 360,
                color: 'primary.main',
                fontSize: 50,
                marginY: 2,
              }}
            />
            <Typography
              variant="p"
              sx={{
                fontSize: 20,
                fontFamily: 'Roboto, sans-serif',
                fontWeight: 400,
                textAlign: 'center',
              }}
            >
              Uninterrupted Power Supply
            </Typography>
          </Box>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
              marginX: 2,
              marginY: { xl: 2, lg: 2, md: 5, sm: 5, xs: 5 },
            }}
          >
            <IoIosPeople style={styling.coworking} />
            <Typography
              variant="p"
              sx={{
                fontSize: 20,
                fontFamily: 'Roboto, sans-serif',
                fontWeight: 400,
                textAlign: 'center',
                marginTop: 1,
              }}
            >
              Conducive Coworking space
            </Typography>
          </Box>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
              marginX: 2,
            }}
          >
            <CellWifiIcon
              sx={{
                color: 'primary.main',
                fontSize: 50,
                marginY: 2,
              }}
            />
            <Typography
              variant="p"
              sx={{
                fontSize: 20,
                fontFamily: 'Roboto, sans-serif',
                fontWeight: 400,
                textAlign: 'center',
              }}
            >
              (24/7) Fast Internet
            </Typography>
          </Box>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
              marginX: 2,
              marginY: { xl: 2, lg: 2, md: 5, sm: 5, xs: 5 },
            }}
          >
            <LaptopIcon
              sx={{
                color: 'primary.main',
                fontSize: 50,
                marginY: 2,
              }}
            />
            <Typography
              variant="p"
              sx={{
                fontSize: 20,
                fontFamily: 'Roboto, sans-serif',
                fontWeight: 400,
                textAlign: 'center',
              }}
            >
              Internship after training
            </Typography>
          </Box>
        </Box>
      </fieldset>
      <Grid container spacing={4} marginY={1}>
        <Grid item xl="4" lg="4" md="4" sm="12" xs="12">
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              backgroundColor: 'primary.main',
              color: 'white',
              fontFamily: 'Roboto, sans-serif',
              height: 100,
              padding: 5,
            }}
          >
            <Typography variant="p" sx={{ textAlign: 'center' }}>
              Starting from
            </Typography>
            <Typography variant="h3" sx={{ textAlign: 'center' }}>
              N20,000
            </Typography>
            <Typography variant="p" sx={{ textAlign: 'center' }}>
              Per Month
            </Typography>
          </Box>
        </Grid>
        <Grid item xl="4" lg="4" md="4" sm="12" xs="12">
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              backgroundColor: 'primary.main',
              color: 'white',
              fontFamily: 'Roboto, sans-serif',
              height: 100,
              padding: 3,
            }}
          >
            <Typography variant="p" sx={{ textAlign: 'center' }}>
              Morning:<b>10am-12pm</b>
            </Typography>
            <Typography variant="p" sx={{ textAlign: 'center' }}>
              Afternoon:<b>2pm-4pm</b>
            </Typography>
          </Box>
        </Grid>
        <Grid item xl="4" lg="4" md="4" sm="12" xs="12">
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              backgroundColor: 'primary.main',
              color: 'white',
              fontFamily: 'Roboto, sans-serif',
              height: 100,
              padding: 3,
            }}
          >
            <Typography variant="p" sx={{ textAlign: 'center' }}>
              Monday/Friday
            </Typography>
            <Typography variant="p" sx={{ textAlign: 'center' }}>
              Virtual/Online
            </Typography>
            <Typography variant="p" sx={{ textAlign: 'center' }}>
              Weekend Classes
            </Typography>
          </Box>
        </Grid>
      </Grid>
    </Container>
  );
};

export default Benefits;
