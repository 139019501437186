export const article = [
  {
    id: 1,
    hero: {
      backgroundImage:
        'https://firebasestorage.googleapis.com/v0/b/topibro-tech.appspot.com/o/minified-images%2FtNc%20group1%20(1).jpg?alt=media&token=a1167e4e-7a75-4126-8e3f-0fa9109325f2',
      backgroundText: 'Tech and Chills Meetup',
      avatar:
        'https://firebasestorage.googleapis.com/v0/b/topibro-tech.appspot.com/o/minified-images%2Ftopibro-logo-dark-theme.png?alt=media&token=44d8ee0a-b693-4135-b5fa-6c256b4ec85c',
      avatarTitle: 'Topibro Technologies',
      date: '05 Sept',
    },
    content: {
      text: 'It all went down on the 3rd of September,2022 at our Hub right here at Alimosho,Lagos as we hosted the maiden edition of  Tech and Chills ( September Edition) and it was massive!. For us, our message is simple, Every one who desires to start a career in Tech does not have to struggle. We understand how overwhelming this can be so we provided a soft landing for everyone.Bringing together a large pool of tech lovers and enthusiasts, it was all fun!fun!! fun and of course learning as we rubbed minds together on what it takes to break into the tech space even if your are a newbie.Our fire side chat was lit as our guests had premium access to our seasoned tech coaches cum speakers who also shared valuable real life experiences and tips about their own journey!',

      image1:
        'https://firebasestorage.googleapis.com/v0/b/topibro-tech.appspot.com/o/minified-images%2FtechNchills%20scrabble%20(1).jpg?alt=media&token=74b9f902-a8c5-4d6a-8486-4cf93c7e84da',
      quoteText:
        '“ Consistency and Discipline are two key factors that would help accelerate your Tech journey"',
      heading: 'We had a mind blowing panel session',
      headingText:
        'Did you hear about our Question and answer session? It was as real as it could get. Why? because not every one gets the chance to gain clarity about their tech career but our guests sure did! When we were not doing all that talking, we were having so much fun in the games arena! Imagine coming for a tech event and leaving as a prize winner. Oh yes It sure did happen! Lots of our attendees had fun and won prizes too!. Now what is a party without jollof,our guests were treated to mouth watering refreshment because when we said CHILLS, we actually meant it. Pictures speak louder than words you know take your time to savour the memories we created with our vibrant and energetic guests as we look forward to hosting another meetup in grand style.',
      picture1:
        'https://firebasestorage.googleapis.com/v0/b/topibro-tech.appspot.com/o/minified-images%2FtNc%20panel%20(1).jpg?alt=media&token=3ad774ca-3eb2-4907-8ce4-0280c5232def',
      picture2:
        'https://firebasestorage.googleapis.com/v0/b/topibro-tech.appspot.com/o/minified-images%2Fscrabble%20game-min.jpg?alt=media&token=658810f3-c325-460e-9c88-f3c2f313c0f2',
      picture3:
        'https://firebasestorage.googleapis.com/v0/b/topibro-tech.appspot.com/o/minified-images%2Fgrab%20a%20cup%20game%20(1).jpg?alt=media&token=a77f0e92-8ff8-4e8b-939a-8a408393f335',
      picture4:
        'https://firebasestorage.googleapis.com/v0/b/topibro-tech.appspot.com/o/minified-images%2FtNc%20attendees%20(1).jpg?alt=media&token=ad91828f-a409-4305-b09f-bb41f7d338c3',
      secondHeading: 'We are pumped for our next event',
      secondHeadingText:
        'We have built our service culture as we would not want you to miss out on anything at all. Every now and then, we would make our big announcement followed by an unveiling, as we have a long series of physical and virtual events lined for the last quarter of the year. We are building a community for all Tech lovers to get access to resource materials, global opportunities and done-for- you courses just to help you grow faster as you make  progress in your Tech journey.',
      image2:
        'https://firebasestorage.googleapis.com/v0/b/topibro-tech.appspot.com/o/ztH%20group2%20(1).jpg?alt=media&token=52a83849-38db-4114-b33d-7490bd4c7bf4',
      cardHeader: 'Zero to hero seminar',
      cardText1: 'Past Event',
      cardText2:
        'Bringing toghether a fine mix of professional and aspiring web developers, it was all fun and learning as we shared valuable tips on how to begin your web developer journey',
      closingText:
        'Did you miss out on  our Tech "n" Chills meet up? Keep an eye open as we are currently cooking something new already!.Be the first to know when you join our community.',
      avatar:
        'https://firebasestorage.googleapis.com/v0/b/topibro-tech.appspot.com/o/minified-images%2Ftopibro-logo2.png?alt=media&token=a27cd13f-8d1e-45b5-a191-b0ecde94bb28',
      title: 'Topibro Technologies',
      date: '05 Sept',
      facebooklink: 'https://facebook.com/topibrotech',
      instagramlink: 'https://instagram.com/topibrotech',
      twitterlink: 'https://twitter.com/topibrotech',
    },
  },

  {
    id: 2,
    hero: {
      backgroundImage:
        'https://firebasestorage.googleapis.com/v0/b/topibro-tech.appspot.com/o/zTH%20group%20(1).jpg?alt=media&token=a11bf489-707e-4fc3-b70b-54492f5cb9f7',
      backgroundText: 'Zero to Hero Seminar',
      avatar:
        'https://firebasestorage.googleapis.com/v0/b/topibro-tech.appspot.com/o/minified-images%2Ftopibro-logo-dark-theme.png?alt=media&token=44d8ee0a-b693-4135-b5fa-6c256b4ec85c',
      avatarTitle: 'Topibro Technologies',
      date: '05 March',
    },
    content: {
      text: 'On the 12th of March 2022, we had our hub filled with guest gathered for our tech workshop with one goal in mind - to raise awareness about the possibility of breaking entry into the world of tech with the right skills. The workshop was very exquisite as we played host to various seasoned top players in the diverse Tech industry as they took out time to give a detailed breakdown of what it entails to start a productive career.  we discovered a common belief that scares a lot of people who are looking to make this huge career decision and we set out to break this bias. we were able to indeed meet a yearning need as we had lots of zealous youths sign up for our workshop which was the first of its kind. we had the opportunity to host these amazing people and we ware going to give you all the juicy details',
      image1:
        'https://firebasestorage.googleapis.com/v0/b/topibro-tech.appspot.com/o/minified-images%2Ffaruk-topibro.jpeg?alt=media&token=0b663904-31cf-4323-a8c0-dda6aceafb25',
      quoteText:
        '“The first step to starting your tech journey is having the right mindset.”',
      heading: ' Your journey begins now',
      headingText:
        'Our guest were super delighted to have the opportunity to hear first hand information of what to expect and the hurdles they would likely face as they sail through their tech journey,we had an amazing open-house session where they were allowed to ask our speakers questions.The societal myths about entering into the tech space being difficult especially as a "newbie" was talked about and totally unmasked .There was a lot of learning and unlearning to do as the speakers opened our eyes to the endless possibilities available. However, some keywords were constantly spoken about "consistency, discipline,determination and constant practice" , as it was clearly stated that the journey is  not promised to be one without bumps. The sessions was finally brought to a beautiful end as we treated our guest to light refreshment and fun memories',
      picture1:
        'https://firebasestorage.googleapis.com/v0/b/topibro-tech.appspot.com/o/minified-images%2FztH%20panel2%20(1).jpg?alt=media&token=e4824245-2907-42be-8c35-13dc7cf9a415',
      picture2:
        'https://firebasestorage.googleapis.com/v0/b/topibro-tech.appspot.com/o/ztH%20spaeaker%20(1).jpg?alt=media&token=99b3a43c-de12-4369-8bd3-3c787d231283',
      picture3:
        'https://firebasestorage.googleapis.com/v0/b/topibro-tech.appspot.com/o/zTH%20group%20(1).jpg?alt=media&token=a11bf489-707e-4fc3-b70b-54492f5cb9f7',
      picture4:
        'https://firebasestorage.googleapis.com/v0/b/topibro-tech.appspot.com/o/ztH%20panel%20(1).jpg?alt=media&token=b2767187-c1bd-4931-b0d5-21a39d22c81a',
      secondHeading: ' We would love to see you our next event',
      secondHeadingText:
        'We have built our service culture as we would not want you to miss out on anything at all. Every now and then, we would make our big announcement followed by an unveiling as we have a long series of physical and virtual events lined for the last quarter of the year. We are building a community for all Tech lovers to get access to resources materials,global opportunity and done-for- you courses just to help you grow faster as you make  progress in your Tech journey.',
      image2:
        'https://firebasestorage.googleapis.com/v0/b/topibro-tech.appspot.com/o/minified-images%2Ftnc%20community%20(1).jpg?alt=media&token=cfa3eac8-86b5-4d9a-81c9-2ca47e48ae5f',
      cardHeader: 'Tech "N" Chills meetup',
      cardText1: 'Next Event',
      cardText2:
        'The maiden edition of TECH annd CHILLS was launched amidst laughter, fun and of course learning, we created a memorable experience that got everyone talking',
      closingText:
        'Did you miss out on our Zero to hero seminar? Keep an eye open as we are currently cooking something new already!.Be the first to know when you join our community.',
      image3: '',
      avatar:
        'https://firebasestorage.googleapis.com/v0/b/topibro-tech.appspot.com/o/minified-images%2Ftopibro-logo2.png?alt=media&token=a27cd13f-8d1e-45b5-a191-b0ecde94bb28',
      title: 'Topibro Tehnologies',
      date: '15 March',
      facebooklink: 'https://www.facebook.com/topibrotech/',
      instagramlink: 'https://www.instagram.com/topibrotech/',
      twitterlink: 'https://twitter.com/topibrotech',
    },
  },
];
