import FingerprintIcon from '@mui/icons-material/Fingerprint';
import LanguageIcon from '@mui/icons-material/Language';
import MyLocationIcon from '@mui/icons-material/MyLocation';
import SettingsIcon from '@mui/icons-material/Settings';
import SpatialTrackingIcon from '@mui/icons-material/SpatialTracking';
import TouchAppOutlinedIcon from '@mui/icons-material/TouchAppOutlined';
import { Container, Grid } from '@mui/material';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import React from 'react';

const PremiumFeature = () => {
  const features = [
    {
      heading: 'Fully Functional',
      description:
        'Functional, responsive, and friendly websites which give users the best experience.',
      icon: <TouchAppOutlinedIcon sx={{ fontSize: 100 }} />,
    },
    {
      heading: 'Finger print data',
      description:
        'Protect your identity and keep your data safe from identity and authentication thieves.',
      icon: <FingerprintIcon sx={{ fontSize: 100 }} />,
    },
    {
      heading: 'Tap navigation',
      description:
        'Easily navigate through pages with just a click using the tap navigation feature.',
      icon: <MyLocationIcon sx={{ fontSize: 100 }} />,
    },
    {
      heading: 'Location tracking',
      description:
        ' Find locations on digital pictures, search for businesses nearby and improve safety with the location tracking feature.',
      icon: <SpatialTrackingIcon sx={{ fontSize: 100 }} />,
    },
    {
      heading: 'Powerful Settings',
      description:
        'Customize and control each page with our self-explanatory settings.',
      icon: <SettingsIcon sx={{ fontSize: 100 }} />,
    },
    {
      heading: 'Multiple languages',
      description: 'Browse at your convenience in your preferred language.',
      icon: <LanguageIcon sx={{ fontSize: 100 }} />,
    },
  ];
  return (
    <Container
      data-aos={'fade-up'}
      data-aos-delay={100}
      data-aos-offset={100}
      data-aos-duration={600}
    >
      <Box
        sx={{
          textAlign: 'center',

          marginBottom: 10,
        }}
      >
        <Typography fontWeight={600} variant="h3">
          Explore premium features
        </Typography>
        <Typography
          variant="P"
          color="#84848C"
          sx={{
            maxWidth: {
              xl: '60%',
              lg: '60%',
              md: '60%',
              sm: '100%',
              xs: '100%',
            },
            margin: 'auto',
          }}
        >
          With user-friendly premium features, our mobile apps are designed
          exceptionally to give users an excellent experience and make the time
          spent while navigating through the pages worthwhile.``
        </Typography>
      </Box>
      <Box
        sx={{
          textAlign: 'center',
        }}
      >
        <Grid
          container
          spacing={{ xs: 1, md: 3 }}
          columns={{ xs: 4, sm: 8, md: 12 }}
        >
          {features.map((feature, i) => (
            <Grid item xs={12} sm={4} md={4} key={i}>
              <Box alignContent="center">
                {feature.icon}

                <Typography variant="h5">{feature.heading}</Typography>
                <Typography variant="p" color="#84848C">
                  {feature.description}
                </Typography>
              </Box>
            </Grid>
          ))}
        </Grid>
      </Box>
    </Container>
  );
};

export default PremiumFeature;
