// Training view SEO setup
export const trainingHelmet = {
  title: 'Topibrotech - Training',
  metaDescription1:
    'Register with us and become skilled in these tech courses Web-Development || Web-Design || Mobile-App-Development in just 3 months of our effective training',
  metaDescription2: '',
  metaKeyWords:
    'training,topibrotech, tech companies in Nigeria, tech school, computer science, development, web, design, top web development companies, companies, digital marketing, nigeria, web development, web design, react, javascript, nodejs',
  ogp: {
    title: 'Topibrotech - Training. Web and Mobile Development, Web Design', // property name og:title
    url: 'https://www.topibrotech.com/training', // property name og:url
    description:
      'Become skilled in these tech courses Web-Development || Web-Design || Mobile-App-Development in just 3 months of our effective training', // property name og:description
    type: 'website', // property name og:type
    image:
      'https://firebasestorage.googleapis.com/v0/b/topibro-tech.appspot.com/o/minified-images%2Ftraining_twitter.png?alt=media&token=b0b37396-2a9d-4805-a0df-eafdeba8ccad', // property name og:image
    locale: 'en_US', // property name og:locale
    site_name: 'topibro', // property name og:site_name
  },
  twitterCards: {
    card: 'summary_large_image', // name twitter:card
    site: '@topibrotech', // name twitter:site
    title: 'Topibro Technologies - Trainings', // name twitter:tittle
    description:
      'Register with us and become skilled in these tech courses Web-Development || Web-Design || Mobile-App-Development in just 3 months of our effective training', // name twitter:description
    image:
      'https://firebasestorage.googleapis.com/v0/b/topibro-tech.appspot.com/o/minified-images%2Ftraining_twitter.png?alt=media&token=b0b37396-2a9d-4805-a0df-eafdeba8ccad',
  },
};

// Index view SEO setup
export const indexViewHelmet = {
  title: 'Topibrotech',
  metaDescription1:
    'Topibrotech is one of the leading tech companies in Nigeria focused on rendering services such as WEB DEVELOPMENT, MOBILE-APP DEVELOPMENT, WEB-DESIGN, TRAINING and COWORKING SPACE.',
  metaDescription2: '',
  metaKeyWords:
    'topibrotech, tech companies in Nigeria, development, web, design, top web development companies, companies, digital marketing, nigeria, web development, web design, react, javascript, nodejs',
  ogp: {
    title:
      'Topibrotech - Web and Mobile Development, Web Design, Training and Coworking', // property name og:title
    url: 'https://www.topibrotech.com', // property name og:url
    description:
      'Topibrotech is one of the leading tech companies in Lagos Nigeria focused on rendering services such as WEB DEVELOPMENT, MOBILE-APP DEVELOPMENT, WEB-DESIGN, TRAINING and COWORKING SPACE.', // property name og:description
    type: 'website', // property name og:type
    image:
      'https://firebasestorage.googleapis.com/v0/b/topibro-tech.appspot.com/o/minified-images%2Fweb-development-training-page-topibro.png?alt=media&token=3afecaf7-1252-41f2-8d5f-1df58c03e7dc', // property name og:image
    locale: 'en_US', // property name og:locale
    site_name: 'topibro', // property name og:site_name
  },
  twitterCards: {
    card: 'summary_large_image', // name twitter:card
    site: '@topibrotech', // name twitter:site
    title: 'Topibro Technologies - Lagos', // name twitter:tittle
    description:
      'Topibrotech is one of the leading tech companies in Nigeria focused on rendering services such as WEB DEVELOPMENT, MOBILE-APP DEVELOPMENT, WEB-DESIGN, TRAINING and COWORKING SPACE.', // name twitter:description
    image:
      'https://firebasestorage.googleapis.com/v0/b/topibro-tech.appspot.com/o/minified-images%2Fweb-development-training-page-topibro.png?alt=media&token=3afecaf7-1252-41f2-8d5f-1df58c03e7dc',
  },
};

// mobile app development SEO setup
export const mobileAppDevViewHelmet = {
  title: 'Topibrotech - Best mobile app development company in Lagos, Nigeria',
  metaDescription1:
    'Get the best mobile app development company in Lagos, Nigeria. we deliver the best and most efficient apps seamlessly and timely',
  metaDescription2: '',
  metaKeyWords:
    'topibrotech, Best mobile app development company in Lagos Nigeria, tech companies in Nigeria, development, design, top web development companies, companies, digital marketing, nigeria, web development, web design, react native, javascript, nodejs',
  ogp: {
    title:
      'Topibrotech - Best mobile app development company in Lagos, Nigeria', // property name og:title
    url: 'https://www.topibrotech.com/mobile-app', // property name og:url
    description:
      'Get the best mobile app development company in Lagos, Nigeria. we deliver the best and most efficient apps seamlessly and timely.', // property name og:description
    type: 'website', // property name og:type
    image:
      'https://firebasestorage.googleapis.com/v0/b/topibro-tech.appspot.com/o/minified-images%2Fmobile-app-development-training-page-topibro.png?alt=media&token=98a28f79-6f4c-494f-a3b3-bec0d291626a', // property name og:image
    locale: 'en_US', // property name og:locale
    site_name: 'topibro', // property name og:site_name
  },
  twitterCards: {
    card: 'summary_large_image', // name twitter:card
    site: '@topibrotech', // name twitter:site
    title:
      'Topibrotech - Best mobile app development company in Lagos, Nigeria', // name twitter:tittle
    description:
      'Get the best mobile app development company in Lagos, Nigeria. we deliver the best and most efficient apps seamlessly and timely.', // name twitter:description
    image:
      'https://firebasestorage.googleapis.com/v0/b/topibro-tech.appspot.com/o/minified-images%2Fmobile-app-development-training-page-topibro.png?alt=media&token=98a28f79-6f4c-494f-a3b3-bec0d291626a',
  },
};

// web development SEO setup
export const webDevViewHelmet = {
  title: 'Topibrotech - Web development company in Lagos, Nigeria',
  metaDescription1:
    'Get the best web development company in Lagos, Nigeria. we deliver the best and most efficient web apps seamlessly and timely',
  metaDescription2: '',
  metaKeyWords:
    'topibrotech, Best web development company in Lagos Nigeria, tech companies in Nigeria, development, design, top web development companies, companies, digital marketing, nigeria, web development, web design, react native, javascript, nodejs',
  ogp: {
    title: 'Topibrotech - Best web development company in Lagos, Nigeria', // property name og:title
    url: 'https://www.topibrotech.com/web-development', // property name og:url
    description:
      'Get the best web development company in Lagos, Nigeria. we deliver the best and most efficient web apps seamlessly and timely.', // property name og:description
    type: 'website', // property name og:type
    image:
      'https://firebasestorage.googleapis.com/v0/b/topibro-tech.appspot.com/o/minified-images%2Fweb-development-training-page-topibro.png?alt=media&token=3afecaf7-1252-41f2-8d5f-1df58c03e7dc', // property name og:image
    locale: 'en_US', // property name og:locale
    site_name: 'topibro', // property name og:site_name
  },
  twitterCards: {
    card: 'summary_large_image', // name twitter:card
    site: '@topibrotech', // name twitter:site
    title:
      'Topibrotech - Best mobile app development company in Lagos, Nigeria', // name twitter:tittle
    description:
      'Get the best mobile app development company in Lagos, Nigeria. we deliver the best and most efficient apps seamlessly and timely.', // name twitter:description
    image:
      'https://firebasestorage.googleapis.com/v0/b/topibro-tech.appspot.com/o/minified-images%2Fmobile-app-development-training-page-topibro.png?alt=media&token=98a28f79-6f4c-494f-a3b3-bec0d291626a',
  },
};

// web design SEO setup
export const webDesignViewHelmet = {
  title: 'Topibrotech - The Best Web design company in Lagos, Nigeria',
  metaDescription1:
    'Get the best web design company in Lagos, Nigeria. we deliver the best and most efficient web apps seamlessly and timely',
  metaDescription2: '',
  metaKeyWords:
    'topibrotech, Best web design company in Lagos Nigeria, tech companies in Nigeria, development, design, top website design companies, top web design companies, companies, digital marketing, nigeria, web development, web design, javascript, website',
  ogp: {
    title: 'Topibrotech - Best web design company in Lagos, Nigeria', // property name og:title
    url: 'https://www.topibrotech.com/web-design', // property name og:url
    description:
      'Get the best web design company in Lagos, Nigeria. we deliver the best and most efficient web design services seamlessly and timely.', // property name og:description
    type: 'website', // property name og:type
    image:
      'https://firebasestorage.googleapis.com/v0/b/topibro-tech.appspot.com/o/minified-images%2Fwed-design-training-page-topibro.png?alt=media&token=91406ade-5485-44ba-97d6-c3fac7b914a7', // property name og:image
    locale: 'en_US', // property name og:locale
    site_name: 'topibro', // property name og:site_name
  },
  twitterCards: {
    card: 'summary_large_image', // name twitter:card
    site: '@topibrotech', // name twitter:site
    title: 'Topibrotech - Best web design company in Lagos, Nigeria', // name twitter:tittle
    description:
      'Get the best web design company in Lagos, Nigeria. we deliver the best and most efficient web design services seamlessly and timely.', // name twitter:description
    image:
      'https://firebasestorage.googleapis.com/v0/b/topibro-tech.appspot.com/o/minified-images%2Fwed-design-training-page-topibro.png?alt=media&token=91406ade-5485-44ba-97d6-c3fac7b914a7',
  },
};

// CoWorking Space SEO setup
export const coworkingViewHelmet = {
  title:
    'Topibrotech - The most conducive and reliable coworking space in Lagos, Nigeria',
  metaDescription1:
    'Get conference room, co-working space, training room, Virtual Office, executive office and mini-studio in Ijegun, Lagos.',
  metaDescription2: '',
  metaKeyWords:
    'topibrotech, business, email, office, learn, founder, Best coworking space in Lagos Nigeria, coworking spaces in Nigeria, suggest, top web design companies, companies, digital marketing, nigeria, web development, web design, javascript, website',
  ogp: {
    title:
      'Topibrotech - The most conducive and reliable coworking space in Lagos, Nigeria', // property name og:title
    url: 'https://www.topibrotech.com/coworkingspace', // property name og:url
    description:
      'Get conference room, co-working space, training room, Virtual Office, executive office and mini-studio in Ijegun, Lagos.', // property name og:description
    type: 'website', // property name og:type
    image:
      'https://firebasestorage.googleapis.com/v0/b/topibro-tech.appspot.com/o/%20coworking%20space%20-%20topibro.jpeg?alt=media&token=461e71b0-0455-4b71-b32d-5e80fe22dc46', // property name og:image
    locale: 'en_US', // property name og:locale
    site_name: 'topibro', // property name og:site_name
  },
  twitterCards: {
    card: 'summary_large_image', // name twitter:card
    site: '@topibrotech', // name twitter:site
    title:
      'Topibrotech - The most conducive and reliable coworking space in Lagos, Nigeria', // name twitter:tittle
    description:
      'Get conference room, co-working space, training room, Virtual Office, executive office and mini-studio in Ijegun, Lagos.', // name twitter:description
    image:
      'https://firebasestorage.googleapis.com/v0/b/topibro-tech.appspot.com/o/%20coworking%20space%20-%20topibro.jpeg?alt=media&token=461e71b0-0455-4b71-b32d-5e80fe22dc46',
  },
};
