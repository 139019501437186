import { Box, Typography } from '@mui/material';
// import React from 'react';
import { Helmet } from 'react-helmet';
import { React, useState, useEffect } from 'react';

// import './style.css';

const Challenge = () => {
  const [time, setTime] = useState();

  const start = new Date();
  start.setHours(19, 0, 0); // 7pm

  function pad(num) {
    return ('0' + parseInt(num)).substr(-2);
  }

  const tick = () => {
    const now = new Date();
    if (now > start) {
      // too late, go to tomorrow
      start.setDate(start.getDate() + 1);
    }
    const remain = (start - now) / 1000;
    const hh = pad((remain / 60 / 60) % 60);
    const mm = pad((remain / 60) % 60);
    const ss = pad(remain % 60);
    setTime(`${hh}h : ${mm}m : ${ss}s`);
    setTimeout(tick, 1000);
  };

  useEffect(() => {
    tick();
  }, []);

  return (
    <Box>
      <Helmet>
        <title>Challenge Trend - Topibro Technologies</title>
      </Helmet>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          backgroundImage:
            'url("https://www.w3schools.com/w3images/forestbridge.jpg")',
          height: '100vh',
          backgroundPosition: 'center',
          backgroundSize: 'cover',
          position: 'relative',
          color: 'white',
          fontFamily: 'Courier New, Courier, monospace',
          fontSize: '25px',
        }}
      >
        <Box
          // display={'flex'}
          // component="a"
          // href="/"
          // title="Topibro Tech"
          // width={{ xs: 60, md: 90 }}

          display={'flex'}
          component="a"
          href="/"
          title="topibro"
          width={{ xs: 100, md: 120 }}
          sx={{
            position: 'absolute',
            top: 0,
            left: '16px',
            margin: 2,
          }}
        >
          <Box
            component={'img'}
            src={
              //  'https://firebasestorage.googleapis.com/v0/b/topibro-tech.appspot.com/o/minified-images%2Ftopibro-logo2.png?alt=media&token=a27cd13f-8d1e-45b5-a191-b0ecde94bb28'
              'https://firebasestorage.googleapis.com/v0/b/topibro-tech.appspot.com/o/minified-images%2Ftopibro-logo-dark-theme.png?alt=media&token=44d8ee0a-b693-4135-b5fa-6c256b4ec85c'
            }
            height={1}
            width={1}
          />
        </Box>
        <Box
          sx={{
            position: 'absolute',
            // top: '50%',
            // left: '50%',
            // transform: 'translate("50%", "50%")',
            textAlign: 'center',
          }}
        >
          <h3>#BeTheFirst DAY 3</h3>
          <h1>SECRET RESULT</h1>
          <hr />
          {/* <h1>07:15pm</h1> */}
          <h1>XXXXXXXXX</h1>
          <p>(Result will be revealed here in {time})</p>
        </Box>
        <Box
          sx={{
            position: 'absolute',
            bottom: 0,
            left: '16px',
            margin: 2,
          }}
        >
          <Typography>#firstTo #firstToChallenge #topibrotech</Typography>
        </Box>
      </Box>
    </Box>
  );
};

export default Challenge;
