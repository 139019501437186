import React, { useEffect } from 'react';

import Container from 'components/Container';
import Main from 'layouts/Main';
import { Helmet } from 'react-helmet';

import Box from '@mui/material/Box';
import { useTheme } from '@mui/material/styles';

import { trainingHelmet } from '../../components/useHelmet';
import {
  Benefits,
  Courses,
  Description,
  Faculties,
  Features,
  Form,
  GetStarted,
  Hero,
} from './components';

const Training = () => {
  const styles = (bgImage) => ({
    position: 'absolute',
    objectFit: 'cover',
    /* support for plugin https://github.com/bfred-it/object-fit-images */
    fontFamily: 'object-fit: cover;',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    zIndex: -1,
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    backgroundPosition: 'center center',
    backgroundImage: `url(${bgImage})`,
    filter: theme.palette.mode === 'dark' ? 'brightness(0.7)' : 'none',
  });
  useEffect(() => {
    const jarallaxInit = async () => {
      const jarallaxElems = document.querySelectorAll('.jarallax');
      if (!jarallaxElems || (jarallaxElems && jarallaxElems.length === 0)) {
        return;
      }

      const { jarallax } = await import('jarallax');
      jarallax(jarallaxElems, { speed: 0.2 });
    };

    jarallaxInit();
  });
  const theme = useTheme();

  return (
    <Main colorInvert={true}>
      <Helmet>
        <title>{trainingHelmet.title}</title>
        <meta name="description" content={trainingHelmet.metaDescription1} />
        <meta name="keywords" content={trainingHelmet.metaKeyWords} />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:site" content={trainingHelmet.twitterCards.site} />
        <meta
          name="twitter:title"
          content={trainingHelmet.twitterCards.title}
        />
        <meta
          name="twitter:description"
          content={trainingHelmet.twitterCards.description}
        />
        <meta
          name="twitter:image"
          content={trainingHelmet.twitterCards.image}
        />
        <meta property="og:site_name" content={trainingHelmet.ogp.site_name} />
        <meta property="og:type" content={trainingHelmet.ogp.type} />
        <meta property="og:title" content={trainingHelmet.ogp.title} />
        <meta
          property="og:description"
          content={trainingHelmet.ogp.description}
        />
        <meta property="og:url" content={trainingHelmet.ogp.url} />
        <meta property="og:image" content={trainingHelmet.ogp.image} />
        <meta property="og:locale" content={trainingHelmet.ogp.locale} />
      </Helmet>
      {/* 
      <meta property="og:type" content="article" />
<meta property="og:title" content="Sammy the Shark" />
<meta property="og:description" content="Senior Selachimorpha at DigitalOcean" />
<meta property="og:url" content="https://html.sammy-codes.com/" />
<meta property="og:image" content="https://html.sammy-codes.com/images/large-profile.jpg" />
 */}
      <Box>
        <Hero />
      </Box>
      <Container>
        <Description />
      </Container>
      <Box>
        <Container>
          <Courses />
        </Container>
        <Benefits />
      </Box>
      <Container>
        <Features />
      </Container>
      <Box
        paddingY={2}
        className={'jarallax'}
        data-jarallax
        data-speed="0.2"
        position={'relative'}
        minHeight={'100vh'}
        display={'flex'}
        alignItems={'center'}
        id="agency__portfolio-item--js-scroll"
        opacity={0.5}
      >
        <Box
          className={'jarallax-img'}
          sx={styles(
            'https://images.pexels.com/photos/574070/pexels-photo-574070.jpeg?auto=compress&cs=tinysrgb&h=750&w=1260',
          )}
        />
        <Box
          sx={{
            backgroundColor:
              theme.palette.alternate.main === '#1a2138' ? '#000' : '#016d5b',
            opacity: 0.5,
            height: '100%',
            zIndex: 0,
            position: 'absolute',
            width: '100%',
            top: 0,
          }}
        />
        <Faculties />
      </Box>
      <Box
        paddingY={2}
        className={'jarallax'}
        data-jarallax
        data-speed="0.2"
        position={'relative'}
        minHeight={'100vh'}
        display={'flex'}
        alignItems={'center'}
        id="agency__portfolio-item--js-scroll"
        sx={{ marginTop: 5 }}
      >
        <Box
          className={'jarallax-img'}
          sx={styles(
            'https://images.pexels.com/photos/955389/pexels-photo-955389.jpeg?auto=compress&cs=tinysrgb&w=600',
          )}
        />
        <Box
          sx={{
            backgroundColor:
              theme.palette.alternate.main === '#1a2138' ? '#000' : '#016d5b',
            opacity: 0.5,
            height: '100%',
            zIndex: 0,
            position: 'absolute',
            width: '100%',
            top: 0,
          }}
        />
        <Container
          sx={{
            filter: 'brightness(1)',
          }}
        >
          <Form />
        </Container>
      </Box>
      <Box
        position={'relative'}
        sx={{
          backgroundColor: theme.palette.alternate.main,
        }}
      >
        <Box>
          <Container paddingY={5}>
            <GetStarted />
          </Container>
        </Box>
        <Box
          component={'svg'}
          preserveAspectRatio="none"
          xmlns="http://www.w3.org/2000/svg"
          x="0px"
          y="0px"
          viewBox="0 0 1920 100.1"
          sx={{
            width: '100%',
            marginBottom: theme.spacing(-1),
          }}
        >
          <path
            fill={theme.palette.background.paper}
            d="M0,0c0,0,934.4,93.4,1920,0v100.1H0L0,0z"
          ></path>
        </Box>
      </Box>
    </Main>
  );
};

export default Training;
