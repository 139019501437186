/* eslint-disable react/jsx-no-comment-textnodes */
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import React from 'react';

const Background = () => {
  const howWeCollectData = [
    {
      paragragh: () => {
        return (
          <Box>
            <Typography>
              <b>
                We collect Personal Data through your use of our Website and
                Application.
              </b>{' '}
              In other words, when you are using the website or application, we
              are aware of it and may gather, collect and record the information
              relating to such usage, either independently or through the help
              of third-party services as detailed below. This may include
              technical information and behavioral information such as the
              User’s Internet protocol (IP) address used to connect your
              computer to the Internet, your uniform resource locators (URL),
              operating system, type of browser, browser plug-in types and
              versions, screen resolution, Flash version, time zone setting, the
              User’s ‘click-stream’ on the website, the period of time the User
              visited the website, methods used to browse away from a page, and
              any phone number used to call our customer service number. We
              likewise may place cookies on your browsing devices (see section
              ‘Cookies’ below)..
            </Typography>
          </Box>
        );
      },
    },
    {
      paragragh: () => {
        return (
          <Box>
            <Typography>
              <b>We collect Personal Data</b> We collect Personal Data required
              to provide the Services when you or the Customer’s administrator
              register and open an account. In addition, we collect your
              personal data, when you provide us such information by entering it
              manually or via a Customer, whether through our application, in
              site visits, in the course of preparing a contract, or otherwise
              in engaging with us. We also collect Personal Data entered
              voluntarily by a Customer administrator, and it is the
              responsibility of every Customer administrator to ensure that they
              are entitled to provide such personal data to us for use in line
              with this Privacy Policy
            </Typography>
          </Box>
        );
      },
    },
    {
      paragragh: () => {
        return (
          <Box>
            <Typography>
              In addition, we may disclose your personal data to third parties:
              if all or substantially all of our assets are acquired by a third
              party including by way of a merger, share acquisition, asset
              purchase or any similar transaction, in which case personal data
              held by it about its customers will be one of the transferred
              assets. Likewise, we may transfer personal data to third parties
              if we are under a duty to disclose or share your personal data in
              order to comply with any legal or audit or compliance obligation,
              in the course of any legal or regulatory proceeding or
              investigation, or in order to enforce or apply our terms of supply
              terms and other agreements with you; or to protect the rights,
              property, or safety of TopIbro Tech, our customers, or others.
              This includes exchanging information with other companies and
              organizations for the purposes of fraud protection and credit risk
              reduction and to prevent cybercrime.
            </Typography>
          </Box>
        );
      },
    },
    {
      paragragh: () => {
        return (
          <Box>
            <Typography>
              For avoidance of doubt, TopIbro Tech may transfer and disclose
              non-personal data to third parties at its own discretion.
              <br />
              ******************
              <br /> We may keep Personal Data in a database which will be owned
              or controlled by the Controller. We currently store data on
              servers controlled by Topibro Technologies.
            </Typography>
          </Box>
        );
      },
    },
  ];

  return (
    <Container sx={{ my: 10 }} id="howweget">
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignContent: 'center',
        }}
      >
        <Box sx={{ dispaly: 'flex', justifyContent: 'left' }}>
          <Typography
            variant="h4"
            sx={{ fontWeight: 600, textAlign: 'left', my: 2 }}
          >
            How Do We Collect Personal Data From Users Of Topibro Tech
            Facilities And Services?
          </Typography>

          {howWeCollectData.map((paragraph) => (
            <Typography
              key={paragraph.paragragh()}
              variant="h6"
              sx={{
                fontWeight: 400,
                textAlign: 'left',
                my: 2,
                color: 'text.secondary',
              }}
            >
              {paragraph.paragragh()}
            </Typography>
          ))}
        </Box>
      </Box>
    </Container>
  );
};

export default Background;
