import AccessTimeIcon from '@mui/icons-material/AccessTime';
import ConnectWithoutContactIcon from '@mui/icons-material/ConnectWithoutContact';
import LocalOfferIcon from '@mui/icons-material/LocalOffer';
import MoneyIcon from '@mui/icons-material/Money';
import SportsKabaddiIcon from '@mui/icons-material/SportsKabaddi';
import WorkHistoryIcon from '@mui/icons-material/WorkHistory';
import { Button, Container } from '@mui/material';
import Box from '@mui/material/Box';
import { useTheme } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import React from 'react';

function Share() {
  const theme = useTheme();
  // // const isMd = useMediaQuery(theme.breakpoints.up('md'), {
  //   defaultMatches: true,
  // });

  const description = [
    {
      icon: (
        <ConnectWithoutContactIcon color={'primary'} sx={{ fontSize: 30 }} />
      ),
      text: 'Allows potential customers find you with ease.',
    },
    {
      icon: <AccessTimeIcon color={'primary'} sx={{ fontSize: 30 }} />,
      text: 'Expand your productive hours.',
    },
    {
      icon: <LocalOfferIcon color={'primary'} sx={{ fontSize: 30 }} />,
      text: 'Showcase your offers.',
    },
    {
      icon: <WorkHistoryIcon color={'primary'} sx={{ fontSize: 30 }} />,
      text: 'Boosts your brand credibilty.',
    },
    {
      icon: <MoneyIcon color={'primary'} sx={{ fontSize: 30 }} />,
      text: 'Maximize ROI.',
    },
    {
      icon: <SportsKabaddiIcon color={'primary'} sx={{ fontSize: 30 }} />,
      text: 'Opportunity to compete with other industry giants.',
    },
  ];
  return (
    <Container
      sx={{
        flexDirection: {
          xl: 'row',
          lg: 'row',
          md: 'column',
          sm: 'column',
          xs: 'column',
        },
        display: 'flex',
        justifyContent: 'space-around',
        marginBottom: 10,
      }}
      data-aos={'fade-up'}
      data-aos-offset={100}
      data-aos-duration={600}
    >
      <Box
        display="flex"
        flexDirection="column"
        sx={{
          marginY: 5,
          marginX: { xs: 'auto', sm: 'auto', md: 5, lg: 5, xl: 5 },
        }}
      >
        <Typography
          variant={'h4'}
          sx={{
            fontWeight: 600,
            color: 'main.primary',
            marginBottom: 4,
          }}
          maxWidth={'450px'}
          alignContent="center"
        >
          Why you need a website
        </Typography>
        <Box>
          {description.map((describe, i) => (
            <Typography
              key={i}
              variant="body2"
              sx={{
                color: '#84848C',
                fontSize: 18,
                fontWeight: 400,
                marginY: 2,
              }}
              display="flex"
            >
              <Typography marginX={2}>{describe.icon}</Typography>
              <Typography>{describe.text}</Typography>
            </Typography>
          ))}
          <Button
            variant="contained"
            component="a"
            href="contact-us"
            color={'primary'}
            width={'100%'}
            fontWeight={600}
            padding="5"
          >
            Get in touch
          </Button>
        </Box>
      </Box>

      <Box
        display="flex"
        marginY={5}
        justifyContent="center"
        alignItems="center"
        maxWidth={600}
        maxHeight={800}
        margin={'auto'}
      >
        <Box
          component={'img'}
          src="https://firebasestorage.googleapis.com/v0/b/topibro-tech.appspot.com/o/minified-images%2Fweb-dev-image2-topibro.jpeg?alt=media&token=b3aa5364-5f33-4d2b-8ee4-644d238fedbd"
          alt="topibro technology"
          sx={{
            filter: theme.palette.mode === 'dark' ? 'brightness(0.8)' : 'none',
            height: 1,
            width: 1,
            margin: 'auto',
          }}
        ></Box>
      </Box>
    </Container>
  );
}

export default Share;
