import Box from '@mui/material/Box';
import { Main } from 'layouts';
import React from 'react';
import Hero from './Hero';
import Container from 'components/Container';
import Service from './Service';
import VideoSection from './VideoSection';

const Mentor = () => {
  return (
    <div>
      <Main>
        <Box>
          <Hero />
        </Box>
        <Container>
          <VideoSection />
        </Container>
        <Container>
          <Service />
        </Container>
      </Main>
    </div>
  );
};

export default Mentor;
