/* eslint-disable react/no-unescaped-entities */
import React from 'react';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';

const VideoSection = () => {
  return (
    <Grid container alignItems={'center'} xs={12}>
      <Grid item xs={12} md={12}>
        <Box>
          <Box
            height={1}
            width={1}
            display={'block'}
            // justifyContent={'center'}
            // alignItems={'center'}
          >
            <Box height={1} width={1} maxHeight={700}>
              <Box
                component={'iframe'}
                title="video"
                width="100%"
                height="100%"
                minHeight={520}
                src="https://www.youtube.com/embed/ClIbn_IF31U"
                frameBorder="0"
                allow="accelerometer; encrypted-media; gyroscope; picture-in-picture"
                boxShadow={4}
                borderRadius={2}
              />
            </Box>
          </Box>
        </Box>
      </Grid>
    </Grid>
  );
};

export default VideoSection;
