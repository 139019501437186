/* eslint-disable react/no-unescaped-entities */
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import { useTheme } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import useMediaQuery from '@mui/material/useMediaQuery';
import React from 'react';
import { assets } from 'assets/assets';

const Story = () => {
  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.up('md'), {
    defaultMatches: true,
  });

  return (
    <Box>
      <Grid container spacing={4} direction={isMd ? 'row' : 'column'}>
        <Grid item container alignItems={'center'} xs={12} md={6}>
          <Box>
            <Typography variant={'h4'} gutterBottom sx={{ fontWeight: 700 }}>
              Our story
            </Typography>
            <Typography component={'p'} color={'text.secondary'}>
              The one-stop digital hub fully equipped to meet all your business
              needs as well as help you kick start your tech career.
              <br />
              <br />
              Situated in the heart of Lagos, we realized that our locality had
              no tech facility to harness all the young potentials around us as
              well as solve the problems most businesses face. We decided to
              solve these problems by providing the right atmosphere and making
              all the necessary tools available and accessible as well as
              introduce the need to jump on the opportunity to learn and skill
              up to the environment and amazingly,it was readily embraced.
              <br />
              <br /> Our goal is to train 100,000 young people.
              <br />
              <br /> Even if you have no prior knowledge, we would take you
              through a very practical foundation class which is everything you
              need to get started in breaking out into the tech space because we
              understand what it means to have the right skill set and fit into
              the global market.
              <br />
              <br /> That's not all! We also set up systems to create unique
              solutions for different businesses at different levels while
              providing a world standard co-working space experience for any one
              who walks into the hub.
            </Typography>
          </Box>
        </Grid>
        <Grid
          item
          container
          justifyContent="center"
          alignItems="center"
          xs={12}
          md={6}
        >
          <Box maxWidth={500} width={1}>
            <Box
              component={'img'}
              src={assets.aboutUsSection.storyImage}
              width={1}
              height={1}
              sx={{
                filter:
                  theme.palette.mode === 'dark' ? 'brightness(0.8)' : 'none',
              }}
            />
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default Story;
