import React from 'react';
import Box from '@mui/material/Box';
// import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
// import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import StarIcon from '@mui/icons-material/Star';
import Link from '@mui/material/Link';
// import NewReleasesOutlinedIcon from '@mui/icons-material/NewReleasesOutlined';
import AccessTimeOutlinedIcon from '@mui/icons-material/AccessTimeOutlined';
import LanguageOutlinedIcon from '@mui/icons-material/LanguageOutlined';
import SubtitlesOutlinedIcon from '@mui/icons-material/SubtitlesOutlined';
// import { format } from 'date-fns';
import ApplyCard from './ApplyCard';

const Hero = () => {
  const rating = [1, 2, 3, 4, 5];
  return (
    <Box
      sx={{
        backgroundColor: 'black',
        minHeight: '40vh',
        p: 5,
        display: 'flex',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          width: { xs: '90%', md: '60%' },
        }}
      >
        <Box sx={{ my: 2 }}>
          <Typography variant="h4" sx={{ color: 'white' }}>
            Learn Web design The Complete Web Course
          </Typography>
        </Box>
        <Box>
          <Typography variant="body1" sx={{ color: 'white' }}>
            Learn A-Z everything about web design from the basics to advanced
            topics!
          </Typography>
        </Box>
        <Box sx={{ my: 1, display: 'flex', flexDirection: 'row' }}>
          <Link href="#">
            {' '}
            <Typography sx={{ color: 'white', fontSize: 15 }}>5.0</Typography>
          </Link>
          <Typography>
            {rating.map((star, i) => {
              return (
                <StarIcon
                  key={i}
                  sx={{ color: 'yellow', fontSize: 15, py: 'auto' }}
                />
              );
            })}
          </Typography>
          <Typography sx={{ color: 'white', fontSize: 15 }}>
            <Link href="#">(3,854 ratings)</Link> 22,039 students
          </Typography>
        </Box>
        <Box sx={{ my: 1 }}>
          <Typography sx={{ color: 'white' }}>
            Created by <Link href="#">David Eric</Link>
          </Typography>
        </Box>
        <Box sx={{ my: 1, display: 'flex', flexDirection: 'row' }}>
          <Box sx={{ display: 'flex' }}>
            <AccessTimeOutlinedIcon sx={{ color: 'white', mx: 0.5 }} />
            <Typography sx={{ color: 'white' }}>Last Updated</Typography>
          </Box>
          <Box sx={{ display: 'flex', ml: 2 }}>
            <LanguageOutlinedIcon sx={{ color: 'white', mx: 0.5 }} />
            <Typography sx={{ color: 'white' }}>English</Typography>
          </Box>
          <Box sx={{ display: 'flex', ml: 2 }}>
            <SubtitlesOutlinedIcon sx={{ color: 'white', mx: 0.5 }} />
            <Typography sx={{ color: 'white' }}>English</Typography>
          </Box>
        </Box>
      </Box>
      <Box sx={{ ml: 5, display: { xs: 'none', md: 'block' } }}>
        <ApplyCard />
      </Box>
    </Box>
  );
};
export default Hero;
