/* eslint-disable react/no-unescaped-entities */
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import { useTheme } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import useMediaQuery from '@mui/material/useMediaQuery';
import React from 'react';

const initialWhoWeAre = `We are a digital agency comprising a wide range of professionals. From web designers, developers, digital marketing experts, project managers, technicians as well as graphic designers all playing unique roles in ensuring developers and future developers with a lot of potential are not giving up on development every day because they don't have the guidance they need and don't know which path to follow. 
Bridging the digital gap between tech dreams and a word class tech career, this program is valuable to fellow developers who are passionate about making an impact and adding value by teaching programming to newbies and developers looking to level up their current skillset. We help you reach out to people and have a positive impact on the developer community at the same time.
Our core services are hinged on helping you Get into web development , guide you through your career path,
answer your  questions and teach what goes into real-world development. Let's get the ball rolling.`;

const Mentee = () => {
  // const [readMore, setReadMore] = useState(true);
  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.up('md'), {
    defaultMatches: true,
  });
  // const readMoreToggle = () => {
  //   setReadMore((prev) => !prev);
  // };
  return (
    <Box data-aos="fade-up">
      <Typography
        variant={'h3'}
        sx={{
          fontWeight: 400,
          fontFamily: 'Roboto, sans-serif',
          textAlign: 'center',
          marginY: 4,
          textTransform: 'capitalize',
        }}
      >
        Become a mentor
      </Typography>
      <Grid container spacing={4} direction={isMd ? 'row-reverse' : 'column'}>
        <Grid
          item
          container
          alignItems={'center'}
          justifyContent="center"
          xs={12}
          md={6}
        >
          <Box>
            <Typography variant={'h4'} gutterBottom sx={{ fontWeight: 700 }}>
              Become a Mentor
            </Typography>
            <Typography component={'p'} color={'text.secondary'}>
              {initialWhoWeAre}
            </Typography>
            <Button variant="outlined" href="/onlineplatform/mentor">
              Get started
            </Button>
          </Box>
        </Grid>
        <Grid
          item
          container
          justifyContent="center"
          alignItems="center"
          xs={12}
          md={6}
        >
          <Box maxWidth={700} width={1}>
            <Box
              component={'img'}
              src={
                'https://images.pexels.com/photos/6266980/pexels-photo-6266980.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2'
              }
              width={'100%'}
              height={1}
              sx={{
                filter:
                  theme.palette.mode === 'dark' ? 'brightness(0.8)' : 'none',
              }}
            />
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default Mentee;
