import 'aos/dist/aos.css';
import 'react-image-lightbox/style.css';
import 'react-lazy-load-image-component/src/effects/blur.css';
import 'slick-carousel/slick/slick-theme.css';
import 'slick-carousel/slick/slick.css';
import './App.css';

import React from 'react';

// Import the functions you need from the SDKs you need
import { initializeApp } from 'firebase/app';
import { BrowserRouter } from 'react-router-dom';

import Page from './components/Page';
import Routes from './Routes';

const firebaseConfig = {
  apiKey: 'AIzaSyAOXcWM43tQiuPq8lcUk0gP8L_XqSR40FI',
  authDomain: 'topibro-tech.firebaseapp.com',
  projectId: 'topibro-tech',
  storageBucket: 'topibro-tech.appspot.com',
  messagingSenderId: '135391293825',
  appId: '1:135391293825:web:1a63a2965fbfd08770b880',
  measurementId: 'G-R4QH9MYC2K',
};

const App = () => {
  // TODO: Add SDKs for Firebase products that you want to use
  // https://firebase.google.com/docs/web/setup#available-libraries

  // Your web app's Firebase configuration
  // For Firebase JS SDK v7.20.0 and later, measurementId is optional

  // Initialize Firebase
  initializeApp(firebaseConfig);
  return (
    <Page>
      <BrowserRouter>
        <Routes />
      </BrowserRouter>
    </Page>
  );
};

export default App;
