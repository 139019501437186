import CheckIcon from '@mui/icons-material/Check';
import CloudDoneIcon from '@mui/icons-material/CloudDone';
import EngineeringIcon from '@mui/icons-material/Engineering';
import HighlightAltIcon from '@mui/icons-material/HighlightAlt';
import InsertEmoticonIcon from '@mui/icons-material/InsertEmoticon';
import ScreenSearchDesktopIcon from '@mui/icons-material/ScreenSearchDesktop';
import { Container } from '@mui/material';
import Box from '@mui/material/Box';
import { useTheme } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import React from 'react';
import { assets } from 'assets/assets';

function Description() {
  const theme = useTheme();
  // // const isMd = useMediaQuery(theme.breakpoints.up('md'), {
  //   defaultMatches: true,
  // });

  const description = [
    {
      icon: <CheckIcon color={'primary'} />,
      text: 'We integrate SEO which allows your site rank well on google search.',
    },
    {
      icon: <CheckIcon color={'primary'} />,
      text: 'User data are secured using double layer security protocol.',
    },
    {
      icon: <CheckIcon color={'primary'} />,
      text: 'Great user experience and customer interaction.',
    },
    {
      icon: <CheckIcon color={'primary'} />,
      text: 'Strategically placed call to action buttons allow users take actions almost immediately.',
    },
    {
      icon: <CheckIcon color={'primary'} />,
      text: 'Our team will always be available for maintenance and upgrade.',
    },
  ];

  const icons = [
    { icon: <ScreenSearchDesktopIcon sx={{ fontSize: 30 }} /> },
    { icon: <CloudDoneIcon sx={{ fontSize: 30 }} /> },
    { icon: <InsertEmoticonIcon sx={{ fontSize: 30 }} /> },
    { icon: <HighlightAltIcon sx={{ fontSize: 30 }} /> },
    { icon: <EngineeringIcon sx={{ fontSize: 30 }} /> },
  ];
  return (
    <Container
      sx={{
        flexDirection: {
          xl: 'row-reverse',
          lg: 'row-reverse',
          md: 'column',
          sm: 'column',
          xs: 'column',
        },
        display: 'flex',
        justifyContent: 'space-around',
        marginBottom: 10,
        overflow: 'hidden',
      }}
      data-aos={'fade-up'}
      data-aos-delay={100}
      data-aos-offset={100}
      data-aos-duration={600}
    >
      <Box
        display="flex"
        flexDirection="column"
        sx={{
          marginY: 5,
          marginX: { xs: 'auto', sm: 'auto', md: 5, lg: 5, xl: 5 },
          overflow: 'hidden',
          width: 1,
        }}
      >
        <Typography
          variant={'h4'}
          sx={{
            fontWeight: 600,
            color: 'main.primary',
            marginBottom: 4,
          }}
          alignContent="center"
        >
          What you will get from our service
        </Typography>
        <Box>
          {description.map((describe, i) => (
            <Typography
              key={i}
              variant="body2"
              sx={{
                color: '#84848C',
                fontSize: 18,
                fontWeight: 400,
                marginY: 2,
                overflow: 'hidden',
              }}
              display="flex"
            >
              <Typography marginX={2}>{describe.icon}</Typography>
              <Typography>{describe.text}</Typography>
            </Typography>
          ))}
        </Box>
        <Box display="flex" flexDirection="row">
          {icons.map((icon, i) => (
            <Typography
              key={i}
              sx={{
                backgroundColor: 'theme.palette.alternate.main',
                padding: 1,
                border: '1px solid rgba(0, 0, 0, 0.1)',
                margin: 'auto',
                textAlign: 'center',
              }}
              color={'primary'}
            >
              {icon.icon}
            </Typography>
          ))}
        </Box>
      </Box>

      <Box
        display="flex"
        marginY={5}
        justifyContent="center"
        alignItems="center"
        maxWidth={600}
        maxHeight={800}
        margin={'auto'}
      >
        <Box
          component={'img'}
          src={assets.webDevelopment.whatYouGet}
          alt="topibro technology"
          sx={{
            filter: theme.palette.mode === 'dark' ? 'brightness(0.8)' : 'none',
            width: 1,
            height: 1,
            margin: 'auto',
          }}
        ></Box>
      </Box>
    </Container>
  );
}

export default Description;
