import ConnectWithoutContactOutlinedIcon from '@mui/icons-material/ConnectWithoutContactOutlined';
import LibraryBooksOutlinedIcon from '@mui/icons-material/LibraryBooksOutlined';
import UploadIcon from '@mui/icons-material/Upload';
import { Button, Container } from '@mui/material';
import Box from '@mui/material/Box';
import { useTheme } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import MyLocationIcon from '@mui/icons-material/MyLocation';
import React from 'react';
import { assets } from 'assets/assets';

function Share() {
  const theme = useTheme();
  // // const isMd = useMediaQuery(theme.breakpoints.up('md'), {
  //   defaultMatches: true,
  // });

  const description = [
    {
      icon: (
        <ConnectWithoutContactOutlinedIcon
          color={'primary'}
          sx={{ fontSize: 30 }}
        />
      ),
      text: 'connect with friends effortlessly.',
    },
    {
      icon: (
        <LibraryBooksOutlinedIcon color={'primary'} sx={{ fontSize: 30 }} />
      ),
      text: 'Share your business ideas with the right minds.',
    },
    {
      icon: <MyLocationIcon color={'primary'} sx={{ fontSize: 30 }} />,
      text: 'Put your business on google map and let people find you seamlessly.',
    },
    {
      icon: <UploadIcon color={'primary'} sx={{ fontSize: 30 }} />,
      text: 'Upload your App on Playstore and App store for downloads.',
    },
  ];
  return (
    <Container
      sx={{
        flexDirection: {
          xl: 'row',
          lg: 'row',
          md: 'row',
          sm: 'column',
          xs: 'column',
        },
        display: 'flex',
        justifyContent: 'space-around',
        marginBottom: 10,
      }}
      data-aos={'fade-right'}
      data-aos-delay={100}
      data-aos-offset={100}
      data-aos-duration={600}
    >
      <Box
        display="flex"
        flexDirection="column"
        sx={{
          marginTop: 5,
        }}
      >
        <Typography
          variant={'h4'}
          sx={{
            fontWeight: 600,
            color: 'main.primary',
            marginBottom: 4,
          }}
          maxWidth={'450px'}
          alignContent="center"
        >
          Share your business and connect with the world easily
        </Typography>
        <Box>
          {description.map((describe, i) => (
            <Typography
              key={i}
              variant="body2"
              sx={{
                color: '#84848C',
                fontSize: 18,
                fontWeight: 400,
                marginY: 2,
              }}
              display="flex"
            >
              <Typography marginX={2}>{describe.icon}</Typography>
              <Typography>{describe.text}</Typography>
            </Typography>
          ))}
          <Button
            variant="contained"
            component="a"
            href="contact-us"
            color={'primary'}
            width={'100%'}
            fontWeight={600}
            padding="5"
          >
            Get in touch
          </Button>
        </Box>
      </Box>

      <Box
        display="flex"
        flexGrow={1}
        marginY={5}
        justifyContent="center"
        alignItems="center"
      >
        <Box
          component={'img'}
          src={assets.mobileDev.shareSection}
          alt="topibro mobile app development"
          sx={{
            filter: theme.palette.mode === 'dark' ? 'brightness(0.8)' : 'none',
          }}
        ></Box>
      </Box>
    </Container>
  );
}

export default Share;
