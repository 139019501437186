import MailIcon from '@mui/icons-material/Mail';
import { Link } from '@mui/material';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import PropTypes from 'prop-types';
import React from 'react';

const MailtoButton = ({ label, mailto, colorInvert }) => {
  return (
    // <Link
    //   to="#"
    //   onClick={(e) => {
    //     window.location.href = mailto;
    //     e.preventDefault();
    //   }}
    // >
    //   {label}
    // </Link>
    <Link
      underline="none"
      component="a"
      href={`mailto:${mailto}`}
      color={colorInvert ? 'common.white' : 'text.primary'}
      sx={{ display: 'flex', alignItems: 'center', paddingBottom: 1 }}
    >
      <MailIcon
        sx={{
          marginRight: { xl: 0.5, lg: 0.5, md: 0.5, sm: 1.5, xs: 1.5 },
          fontSize: 20,
        }}
      />
      <Box
        padding={0.5}
        display={'inline-flex'}
        borderRadius={1}
        marginRight={2}
      >
        <Typography
          variant={'caption'}
          sx={{ lineHeight: 1 }}
          display={{ xl: 'block', lg: 'block', sm: 'none', xs: 'none' }}
          fontSize={15}
        >
          {label}
        </Typography>
      </Box>
    </Link>
  );
};

MailtoButton.propTypes = {
  mailto: PropTypes.string,
  label: PropTypes.string,
  colorInvert: PropTypes.bool,
};
export default MailtoButton;
