import Box from '@mui/material/Box';
import { useTheme } from '@mui/material/styles';
import Container from 'components/Container';
import Main from 'layouts/Main';
import React from 'react';
import { Helmet } from 'react-helmet';
import { mobileAppDevViewHelmet } from '../../components/useHelmet';
import {
  Carousel,
  Description,
  GetStarted,
  Hero,
  HowItWorks,
  PremiumFeature,
  Share,
} from './components';

const MobileApp = () => {
  const theme = useTheme();

  return (
    <Main>
      <Helmet>
        <title>{mobileAppDevViewHelmet.title}</title>
        <meta
          name="description"
          content={mobileAppDevViewHelmet.metaDescription1}
        />
        <meta name="keywords" content={mobileAppDevViewHelmet.metaKeyWords} />
        <meta name="twitter:card" content="summary_large_image" />
        <meta
          name="twitter:site"
          content={mobileAppDevViewHelmet.twitterCards.site}
        />
        <meta
          name="twitter:title"
          content={mobileAppDevViewHelmet.twitterCards.title}
        />
        <meta
          name="twitter:description"
          content={mobileAppDevViewHelmet.twitterCards.description}
        />
        <meta
          name="twitter:image"
          content={mobileAppDevViewHelmet.twitterCards.image}
        />
        <meta
          property="og:site_name"
          content={mobileAppDevViewHelmet.ogp.site_name}
        />
        <meta property="og:type" content={mobileAppDevViewHelmet.ogp.type} />
        <meta property="og:title" content={mobileAppDevViewHelmet.ogp.title} />
        <meta
          property="og:description"
          content={mobileAppDevViewHelmet.ogp.description}
        />
        <meta property="og:url" content={mobileAppDevViewHelmet.ogp.url} />
        <meta property="og:image" content={mobileAppDevViewHelmet.ogp.image} />
        <meta
          property="og:locale"
          content={mobileAppDevViewHelmet.ogp.locale}
        />
      </Helmet>
      <Box>
        <Hero />
      </Box>
      <Container marginBottom={5}>
        <PremiumFeature />
      </Container>
      <Box
        sx={{
          backgroundColor: theme.palette.alternate.main,
          alignContent: 'center',
        }}
      >
        <Description />
      </Box>
      <Box
        sx={{
          backgroundColor: theme.palette.alternate.main,
          alignContent: 'center',
        }}
      >
        <Share />
      </Box>
      <Box>
        <HowItWorks />
      </Box>
      <Container>
        <Carousel />
      </Container>
      <Box
        component={'svg'}
        preserveAspectRatio="none"
        xmlns="http://www.w3.org/2000/svg"
        x="0px"
        y="0px"
        viewBox="0 0 1920 100.1"
        sx={{
          width: '100%',
          marginBottom: theme.spacing(-1),
        }}
      >
        <path
          fill={theme.palette.background.default}
          d="M0,0c0,0,934.4,93.4,1920,0v100.1H0L0,0z"
        ></path>
      </Box>
      {/* <Container>
        <Box
          sx={{
            position: 'relative',
            '&::after': {
              position: 'absolute',
              content: '""',
              width: '60%',
              zIndex: 1,
              top: 0,
              left: 0,
              height: '70%',
              backgroundSize: '18px 18px',
              backgroundImage: `radial-gradient(${alpha(
                theme.palette.primary.dark,
                0.4,
              )} 20%, transparent 20%)`,
              opacity: 0.2,
            },
          }}
        >
          <Box position={'relative'} zIndex={2}>
            <About />
          </Box>
        </Box>
      </Container> */}
      {/* <Container>
        <Features />
      </Container>
      <Box position={'relative'} bgcolor={'alternate.main'}>
        <Container>
          <Pricings />
        </Container>
        <Box
          component={'svg'}
          preserveAspectRatio="none"
          xmlns="http://www.w3.org/2000/svg"
          x="0px"
          y="0px"
          viewBox="0 0 1920 100.1"
          sx={{
            width: '100%',
            marginBottom: theme.spacing(-1),
          }}
        >
          <path
            fill={theme.palette.background.default}
            d="M0,0c0,0,934.4,93.4,1920,0v100.1H0L0,0z"
          ></path>
        </Box>
      </Box> */}

      {/* <Divider />
      <Container>
        <Download />
      </Container> */}
      <Container>
        <GetStarted />
      </Container>
    </Main>
  );
};

export default MobileApp;
