import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import React from 'react';
import TopNavBottom from './TopNavBottom';

const Footer = () => {
  // const theme = useTheme();
  // const { mode } = theme.palette;

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Box
          display={'flex'}
          justifyContent={'space-between'}
          alignItems={'center'}
          width={1}
          flexDirection={{ xs: 'column', sm: 'row' }}
        >
          {/* <Box
            display={'flex'}
            component="a"
            href="/"
            title="Topibro Tech"
            width={80}
          >
            <Box
              component={'img'}
              src={
                mode === 'light'
                  ? 'https://firebasestorage.googleapis.com/v0/b/topibro-tech.appspot.com/o/topibro.svg?alt=media&token=3556d326-3c3d-48e7-be8e-38bd78be718f'
                  : 'https://firebasestorage.googleapis.com/v0/b/topibro-tech.appspot.com/o/topibro-designs.png?alt=media&token=b34141cf-d4d0-4e50-b51f-b74e2351a158'
              }
              height={1}
              width={1}
            />
          </Box> */}
          <Box>
            <Box marginTop={1}>
              <TopNavBottom />
            </Box>
          </Box>
        </Box>
      </Grid>
      <Grid item xs={12}>
        <Typography
          align={'center'}
          variant={'subtitle2'}
          color="text.secondary"
          gutterBottom
        >
          &copy; Topibro Tech. 2022, All rights reserved
        </Typography>
        <Typography
          align={'center'}
          variant={'caption'}
          color="text.secondary"
          component={'p'}
        >
          When you visit or interact with our sites, services or tools, we or
          our authorised service providers may use cookies for storing
          information to help provide you with a better, faster and safer
          experience and for marketing purposes.
        </Typography>
      </Grid>
    </Grid>
  );
};

export default Footer;
