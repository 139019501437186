import Box from '@mui/material/Box';
import { useTheme } from '@mui/material/styles';
import Container from 'components/Container';
import Main from 'layouts/Main';
import React from 'react';
import { webDevViewHelmet } from 'components/useHelmet';
import { Helmet } from 'react-helmet';
import {
  About,
  Description,
  Features,
  GetStarted,
  Hero,
  Services,
  Share,
  // Svg,
} from './components';

const WebBasic = () => {
  const theme = useTheme();

  return (
    <Main colorInvert={true}>
      <Helmet>
        <title>{webDevViewHelmet.title}</title>
        <meta name="description" content={webDevViewHelmet.metaDescription1} />
        <meta name="keywords" content={webDevViewHelmet.metaKeyWords} />
        <meta name="twitter:card" content="summary_large_image" />
        <meta
          name="twitter:site"
          content={webDevViewHelmet.twitterCards.site}
        />
        <meta
          name="twitter:title"
          content={webDevViewHelmet.twitterCards.title}
        />
        <meta
          name="twitter:description"
          content={webDevViewHelmet.twitterCards.description}
        />
        <meta
          name="twitter:image"
          content={webDevViewHelmet.twitterCards.image}
        />
        <meta
          property="og:site_name"
          content={webDevViewHelmet.ogp.site_name}
        />
        <meta property="og:type" content={webDevViewHelmet.ogp.type} />
        <meta property="og:title" content={webDevViewHelmet.ogp.title} />
        <meta
          property="og:description"
          content={webDevViewHelmet.ogp.description}
        />
        <meta property="og:url" content={webDevViewHelmet.ogp.url} />
        <meta property="og:image" content={webDevViewHelmet.ogp.image} />
        <meta property="og:locale" content={webDevViewHelmet.ogp.locale} />
      </Helmet>
      <Box
        position={'relative'}
        sx={{
          backgroundColor:
            theme.palette.alternate.main === '#1a2138' ? '#000' : '#016d5b',
          filter: theme.palette.mode === 'dark' ? 'brightness(0.7)' : 'none',
          marginTop: -16,
          paddingTop: 13,
        }}
      >
        <Box
        // sx={{
        //   backgroundImage:
        //     "url('https://firebasestorage.googleapis.com/v0/b/topibro-tech.appspot.com/o/minified-images%2Fweb%20dev%20and%20design%20hero%20background.png?alt=media&token=fb3162a9-d41c-4180-a8b1-e83d99a53261')",
        //   backgroundPosition: 'right',
        //   backgroundSize: 'contain',
        //   backgroundRepeat: ' no-repeat',
        // }}
        >
          <Hero />
        </Box>

        {/* <Box
          component={'svg'}
          preserveAspectRatio="none"
          xmlns="http://www.w3.org/2000/svg"
          x="0px"
          y="0px"
          viewBox="0 0 1920 100.1"
          sx={{
            width: '100%',
            marginBottom: theme.spacing(-1),
          }}
        >
          <path
            fill={theme.palette.background.paper}
            d="M0,0c0,0,934.4,93.4,1920,0v100.1H0L0,0z"
          ></path>
        </Box> */}
      </Box>
      {/* <Box>
        <Svg />
      </Box> */}
      <Container marginTop={{ xl: 0, lg: 0, md: 0, sm: 10, xs: 10 }}>
        <Description />
      </Container>
      <Container marginTop={-10}>
        <Share />
      </Container>
      <Container paddingTop={'0 !important'} marginTop={-10}>
        <Services />
      </Container>
      {/* <Container maxWidth={1} paddingTop={'0 !important'}>
        <Partners />
      </Container> */}
      <Box
        position={'relative'}
        sx={{
          backgroundColor: theme.palette.alternate.main,
        }}
      >
        <Box>
          {/* <Container>
            <Folio />
          </Container> */}
          <Container paddingTop={'0 !important'}>
            <About />
          </Container>
        </Box>
        <Box
          component={'svg'}
          preserveAspectRatio="none"
          xmlns="http://www.w3.org/2000/svg"
          x="0px"
          y="0px"
          viewBox="0 0 1920 100.1"
          sx={{
            width: '100%',
            marginBottom: theme.spacing(-1),
          }}
        >
          <path
            fill={theme.palette.background.paper}
            d="M0,0c0,0,934.4,93.4,1920,0v100.1H0L0,0z"
          ></path>
        </Box>
      </Box>
      <Box bgcolor={'primary.main'}>
        <Features />
      </Box>

      <Box
        position={'relative'}
        sx={{
          backgroundColor: theme.palette.alternate.main,
        }}
      >
        <Box>
          <Container paddingY={5}>
            <GetStarted />
          </Container>
        </Box>
        <Box
          component={'svg'}
          preserveAspectRatio="none"
          xmlns="http://www.w3.org/2000/svg"
          x="0px"
          y="0px"
          viewBox="0 0 1920 100.1"
          sx={{
            width: '100%',
            marginBottom: theme.spacing(-1),
          }}
        >
          <path
            fill={theme.palette.background.paper}
            d="M0,0c0,0,934.4,93.4,1920,0v100.1H0L0,0z"
          ></path>
        </Box>
      </Box>
    </Main>
  );
};

export default WebBasic;
