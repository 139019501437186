import * as React from 'react';
import { useState } from 'react';
import Autocomplete from '@mui/material/Autocomplete';
import axios from 'axios';

// import DialogContentText from '@mui/material/DialogContentText';
import Alert from '@mui/material/Alert';
import Button from '@mui/material/Button';
import Collapse from '@mui/material/Collapse';
import Dialog from '@mui/material/Dialog';
// import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';

const baseUrl = 'https://api.topibrotech.com/api/v1/seminar/register';
const initFormData = {
  firstName: '',
  lastName: '',
  email: '',
  phone: '',
  companyName: '',
};

export default function FormDialog() {
  const [open, setOpen] = React.useState(false);
  const [formData, setFormData] = useState(initFormData);
  // const [formError, setFormError] = useState({ type: '', message: '' });
  const [formError, setFormError] = useState({});
  const [disabled, setDisabled] = useState(false);
  const [apiFeedBack, setApiFeedBack] = useState({ type: '', message: '' });
  // console.log('Form Details', formData);
  const top100Films = [
    { label: 'Web Design' },
    { label: 'Web Development' },
    { label: 'UI/UX' },
    { label: 'Graphic Design' },
    { label: 'Data Analysis' },
  ];
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleSubmit = async () => {
    if (!formData.firstName) {
      return setFormError({
        firstName: 'first name is required!',
      });
    } else if (!formData.lastName) {
      return setFormError({
        lastName: 'Last name is required!',
      });
    } else if (!formData.email) {
      return setFormError({
        email: 'email is required',
      });
    } else if (!formData.phone) {
      return setFormError({
        phone: 'phone Number is required',
      });
    } else if (!formData.companyName) {
      return setFormError({
        phone: 'Company Name is required',
      });
    } else {
      setFormError({});
      setDisabled(true);
      setApiFeedBack({ type: '', message: '' });
      try {
        const resp = await axios.post(baseUrl, { ...formData });
        console.log(resp.data);
        setDisabled(false);
        setFormData(initFormData);
        setApiFeedBack({
          type: 'success',
          message: 'Successfully registered!',
        });

        setTimeout(() => {
          window.location.href =
            'https://www.meetup.com/zero-2-hero/events/287859086/';
        }, 2000);
      } catch (error) {
        setDisabled(false);
        setApiFeedBack({ type: 'error', message: error.response.data.message });
      }
    }
  };

  return (
    <div>
      <Button
        sx={{ width: '100%' }}
        size={'large'}
        variant="contained"
        onClick={handleClickOpen}
      >
        Get Started
      </Button>
      {/* <form onSubmit={handleSubmit}> */}
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle> Fill in your details</DialogTitle>
        <DialogContent>
          <Grid container spacing={4}>
            <Grid item xs={12} sm={6}>
              <TextField
                // autoFocus
                margin="dense"
                name="firstName"
                label="First Name"
                type="text"
                color="primary"
                size="medium"
                fullWidth
                variant="outlined"
                value={formData.firstName}
                onChange={(e) => {
                  return setFormData((prev) => ({
                    ...prev,
                    firstName: e.target.value,
                  }));
                }}
                // error={formError.type === 'firstName'}
                // helperText={
                //   formError.type === 'firstName' ? formError.message : ''
                error={formError?.firstName}
                helperText={formError?.firstName}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                // autoFocus
                margin="dense"
                name="lastName"
                label="Last Name"
                type="text"
                fullWidth
                variant="outlined"
                value={formData.lastName}
                onChange={(e) => {
                  return setFormData((prev) => ({
                    ...prev,
                    lastName: e.target.value,
                  }));
                }}
                error={formError?.lastName}
                helperText={formError?.lastName}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                // autoFocus
                margin="dense"
                name="email"
                label="Email Address"
                type="email"
                fullWidth
                variant="outlined"
                value={formData.email}
                onChange={(e) => {
                  return setFormData((prev) => ({
                    ...prev,
                    email: e.target.value,
                  }));
                }}
                error={formError?.email}
                helperText={formError?.email}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                // autoFocus
                margin="dense"
                name="phone"
                label="Phone Number"
                type="number"
                fullWidth
                variant="outlined"
                value={formData.phone}
                onChange={(e) => {
                  return setFormData((prev) => ({
                    ...prev,
                    phone: e.target.value,
                  }));
                }}
                error={formError?.phone}
                helperText={formError?.phone}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                // autoFocus
                margin="dense"
                name="companyName"
                label="Company Name"
                type="text"
                fullWidth
                variant="outlined"
                value={formData.companyName}
                onChange={(e) => {
                  return setFormData((prev) => ({
                    ...prev,
                    companyName: e.target.value,
                  }));
                }}
                error={formError?.companyName}
                helperText={formError?.companyName}
              />
            </Grid>
            <Grid item xs={12}>
              <Autocomplete
                disablePortal
                id="combo-box-demo"
                options={top100Films}
                sx={{ width: 300 }}
                renderInput={(params) => (
                  <TextField {...params} label="Course" />
                )}
              />
            </Grid>
            <Grid item xs={6}>
              <Button
                variant="outlined"
                size="large"
                color="primary"
                fullWidth
                onClick={handleClose}
              >
                Cancel
              </Button>
            </Grid>
            <Grid item xs={6}>
              <Button
                size="large"
                variant="contained"
                color="primary"
                fullWidth
                type="submit"
                onClick={() => handleSubmit()}
                disabled={disabled}
              >
                Submit
              </Button>
            </Grid>
          </Grid>
          <Collapse in={apiFeedBack.type}>
            <Grid mt={4}>
              <Alert
                onClose={() => setApiFeedBack({ type: '', message: '' })}
                severity={apiFeedBack.type}
                sx={{ width: '100%' }}
              >
                {apiFeedBack.message}
              </Alert>
            </Grid>
          </Collapse>
        </DialogContent>
      </Dialog>
      {/* </form> */}
    </div>
  );
}
