import CheckIcon from '@mui/icons-material/Check';
import DraftsIcon from '@mui/icons-material/Drafts';
import NotificationsIcon from '@mui/icons-material/Notifications';
import VideocamIcon from '@mui/icons-material/Videocam';
import { Container } from '@mui/material';
import Box from '@mui/material/Box';
import { useTheme } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import React from 'react';
import { assets } from 'assets/assets';

function Description() {
  const theme = useTheme();
  // // const isMd = useMediaQuery(theme.breakpoints.up('md'), {
  //   defaultMatches: true,
  // });

  const description = [
    {
      icon: <CheckIcon color={'primary'} />,
      text: 'make it easier to step back from your site to see what you need to make the right decisions at the right time.',
    },
    {
      icon: <CheckIcon color={'primary'} />,
      text: 'make it simple for businesses to take all the steps they need to increase their profit while maximizing their efficiency.',
    },
    {
      icon: <CheckIcon color={'primary'} />,
      text: ' allow you to track and optimize your site and improve your conversion rate.',
    },
    {
      icon: <CheckIcon color={'primary'} />,
      text: 'help you improve your apps performance!.',
    },
  ];
  return (
    <Container
      sx={{
        flexDirection: {
          xl: 'row-reverse',
          lg: 'row-reverse',
          md: 'row-reverse',
          sm: 'column',
          xs: 'column',
        },
        display: 'flex',
        justifyContent: 'space-around',
        marginBottom: 10,
      }}
      data-aos={'fade-left'}
      data-aos-delay={100}
      data-aos-offset={100}
      data-aos-duration={600}
    >
      <Box
        display="flex"
        flexDirection="column"
        sx={{
          marginTop: 5,
        }}
      >
        <Typography
          variant={'h4'}
          sx={{
            fontWeight: 600,
            color: 'main.primary',
            marginBottom: 4,
          }}
          maxWidth={'450px'}
          alignContent="center"
        >
          Work smart work with powerful Mobile App tools which are designed to
        </Typography>
        <Box maxWidth={700}>
          {description.map((describe, i) => (
            <Typography
              key={i}
              variant="body2"
              sx={{
                color: '#84848C',
                fontSize: 18,
                fontWeight: 400,
                marginY: 2,
              }}
              display="flex"
            >
              <Typography marginX={2}>{describe.icon}</Typography>
              <Typography>{describe.text}</Typography>
            </Typography>
          ))}
        </Box>
        <Box display="flex" flexDirection="row">
          <Typography
            sx={{
              backgroundColor: 'theme.palette.alternate.main',
              padding: 1,
              border: '1px solid rgba(0, 0, 0, 0.1)',
              marginRight: 4,
              textAlign: 'center',
            }}
            color={'primary'}
          >
            <NotificationsIcon sx={{ fontSize: 30 }} />
          </Typography>
          <Typography
            sx={{
              backgroundColor: 'theme.palette.alternate.main',
              padding: 1,
              border: '1px solid rgba(0, 0, 0, 0.1)',
              marginRight: 4,
              textAlign: 'center',
            }}
            color={'primary'}
          >
            <DraftsIcon sx={{ fontSize: 30 }} />
          </Typography>
          <Typography
            sx={{
              backgroundColor: 'theme.palette.alternate.main',
              padding: 1,
              border: '1px solid rgba(0, 0, 0, 0.1)',
              textAlign: 'center',
            }}
            color={'primary'}
          >
            <VideocamIcon sx={{ fontSize: 30 }} />
          </Typography>
        </Box>
      </Box>

      <Box
        display="flex"
        flexGrow={1}
        marginY={5}
        justifyContent="center"
        alignItems="center"
      >
        <Box
          component={'img'}
          src={assets.mobileDev.workSmart}
          alt="topibro - mobile app development"
          sx={{
            filter: theme.palette.mode === 'dark' ? 'brightness(0.8)' : 'none',
          }}
        ></Box>
      </Box>
    </Container>
  );
}

export default Description;
