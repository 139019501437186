/* eslint-disable react/jsx-no-comment-textnodes */
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import React from 'react';

const DataSubjectRight = () => {
  const dataSubjectRight = [
    {
      paragragh: () => {
        return (
          <Box id="datasubject">
            <Typography>
              It is clarified for the removal of doubt, that where personal data
              is provided by a customer being the data subject’s employer,{' '}
              <b>
                such data subject rights will have to be effected through that
                customer.
              </b>
              In addition, data subject rights cannot be exercised in a manner
              inconsistent with the rights of TopIbro Technologies employees and
              staff, with TopIbro proprietary rights, and third party rights. As
              such, job references, reviews, internal notes and assessments,
              documents and notes including proprietary information or forms of
              intellectual property, cannot be accessed or erased or rectified.
              In addition, these rights may not be exercisable where they relate
              to data that is not in a structured form, for example emails, or
              where other exemptions apply. If processing occurs based on
              consent, data subjects may have a right to withdraw their consent.
            </Typography>
          </Box>
        );
      },
    },
    {
      paragragh: () => {
        return (
          <Box>
            <Typography>
              <b>We collect Personal Data</b> We collect Personal Data required
              to provide the Services when you or the Customer’s administrator
              register and open an account. In addition, we collect your
              personal data, when you provide us such information by entering it
              manually or via a Customer, whether through our application, in
              site visits, in the course of preparing a contract, or otherwise
              in engaging with us. We also collect Personal Data entered
              voluntarily by a Customer administrator, and it is the
              responsibility of every Customer administrator to ensure that they
              are entitled to provide such personal data to us for use in line
              with this Privacy Policy
            </Typography>
          </Box>
        );
      },
    },
    {
      paragragh: () => {
        return (
          <Box>
            <Typography>
              In addition, we may disclose your personal data to third parties:
              if all or substantially all of our assets are acquired by a third
              party including by way of a merger, share acquisition, asset
              purchase or any similar transaction, in which case personal data
              held by it about its customers will be one of the transferred
              assets. Likewise, we may transfer personal data to third parties
              if we are under a duty to disclose or share your personal data in
              order to comply with any legal or audit or compliance obligation,
              in the course of any legal or regulatory proceeding or
              investigation, or in order to enforce or apply our terms of supply
              terms and other agreements with you; or to protect the rights,
              property, or safety of TopIbro Tech, our customers, or others.
              This includes exchanging information with other companies and
              organizations for the purposes of fraud protection and credit risk
              reduction and to prevent cybercrime.
            </Typography>
          </Box>
        );
      },
    },
    {
      paragragh: () => {
        return (
          <Box>
            <Typography>
              For avoidance of doubt, TopIbro Tech may transfer and disclose
              non-personal data to third parties at its own discretion.
              <br />
              ******************
              <br /> We may keep Personal Data in a database which will be owned
              or controlled by the Controller. We currently store data on
              servers controlled by Topibro Technologies.
            </Typography>
          </Box>
        );
      },
    },
  ];

  return (
    <Container sx={{ my: 10 }}>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignContent: 'center',
        }}
      >
        <Box sx={{ dispaly: 'flex', justifyContent: 'left' }}>
          <Typography
            variant="h4"
            sx={{ fontWeight: 600, textAlign: 'left', my: 2 }}
          >
            DATA SUBJECT RIGHTS
          </Typography>

          {dataSubjectRight.map((paragraph) => (
            <Typography
              key={paragraph.paragragh()}
              variant="h6"
              sx={{
                fontWeight: 400,
                textAlign: 'left',
                my: 2,
                color: 'text.secondary',
              }}
            >
              {paragraph.paragragh()}
            </Typography>
          ))}
        </Box>
      </Box>
    </Container>
  );
};

export default DataSubjectRight;
