import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import { Button, DialogActions } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';
import PropTypes from 'prop-types';
import * as React from 'react';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function SuccessMessage({
  openSuccessModal,
  setOpenSuccessModal,
}) {
  return (
    <div>
      <Dialog
        open={openSuccessModal}
        TransitionComponent={Transition}
        keepMounted
        // onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle sx={{ m: '0 auto' }}>
          {'Registration Succesful'}
        </DialogTitle>
        <CheckCircleOutlineIcon
          color="success"
          fontSize="large"
          sx={{ fontSize: 70, m: '0 auto' }}
        />
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            We cannot wait to see you at our event!
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenSuccessModal(false)}>Okay</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
SuccessMessage.propTypes = {
  openSuccessModal: PropTypes.bool,
  setOpenSuccessModal: PropTypes.func,
};
