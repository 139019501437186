import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import FavoriteBorderOutlinedIcon from '@mui/icons-material/FavoriteBorderOutlined';
import LocationOnOutlinedIcon from '@mui/icons-material/LocationOnOutlined';
import PriceChangeOutlinedIcon from '@mui/icons-material/PriceChangeOutlined';
import { Container } from '@mui/material';
import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';
import Typography from '@mui/material/Typography';
import React from 'react';
import { assets } from 'assets/assets';
// import { LazyLoadImage } from 'react-lazy-load-image-component';

const Benefits = () => {
  const CardDetails = [
    {
      header: 'Office wherever you need',
      body: 'Hybrid work and reduce your teams commute',
      icon: <LocationOnOutlinedIcon sx={{ fontSize: 30 }} />,
    },
    {
      header: 'Grow your startup culture',
      body: 'Keep face-to-face part of your team dynamic.',
      icon: <FavoriteBorderOutlinedIcon sx={{ fontSize: 30 }} />,
    },
    {
      header: 'Cut costs and get more',
      body: 'Save up to 60% compared to traditional leases.',
      icon: <PriceChangeOutlinedIcon sx={{ fontSize: 30 }} />,
    },
  ];
  return (
    <Container sx={{ marginBottom: 20 }} overflow={'hidden'}>
      <Box
        sx={{
          backgroundColor: '#f2f7ff',
          borderRadius: 360,
          position: 'absolute',
          zIndex: 0,
          right: 10,
          top: 1900,
          width: '60%',
          height: '80%',
          overflow: 'hidden',
        }}
      />

      <Box
        sx={{
          display: 'flex',
          flexDirection: {
            xl: 'column',
            lg: 'column',
            md: 'column',
            sm: 'column-reverse',
            xs: 'column-reverse',
          },
          zIndex: 10,
        }}
      >
        <Box
          sx={{
            width: '100%',
            // height: '60vh',
            margin: 'auto',
            zIndex: 10,
            objectFit: 'cover',
          }}
        >
          <Box
            component={'img'}
            src={assets.coworkingSpace.benefits1}
            sx={{
              width: '100%',
              height: '100%',
              objectFit: 'fill',
              borderRadius: { xl: 10, lg: 10, md: 10, sm: 0, xs: 0 },
              zIndex: 10,
            }}
          />
        </Box>
        <Box
          sx={{
            display: 'flex',
            flexDirection: {
              xl: 'row',
              lg: 'row',
              md: 'row',
              sm: 'column',
              xs: 'column',
            },
            justifyContent: 'space-between',
            zIndex: 10,
            flexWrap: 'nowrap',
          }}
          data-aos={'fade-up'}
          data-aos-offset={100}
          data-aos-duration={600}
        >
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
            }}
          >
            <Typography
              variant="h2"
              component="h1"
              color="primary"
              sx={{
                textAlign: {
                  xl: 'left',
                  lg: 'left',
                  md: 'center',
                  sm: 'center',
                  xs: 'center',
                },
                fontWeight: 700,
                marginBottom: 0,
              }}
            >
              The benefits
            </Typography>
            <Typography
              variant="body"
              sx={{
                textAlign: {
                  xl: 'left',
                  lg: 'left',
                  md: 'center',
                  sm: 'center',
                  xs: 'center',
                },
                width: { xl: 400, lg: 400, md: '90%', sm: '90%', xs: '90%' },
                margin: 'auto',
              }}
            >
              Topibro Tech is a bespoke solution, tailored for your business to
              provide the scale and flexibility you need to grow.
            </Typography>
          </Box>
          <Box
            sx={{
              display: 'flex',
              flexDirection: {
                xl: 'row',
                lg: 'row',
                md: 'row',
                sm: 'column',
                xs: 'column',
              },
              marginTop: { xl: -15, lg: -15, md: -15, sm: 5, xs: 5 },
              flexWrap: 'nowrap',
              padding: 5,
              overflow: 'auto',
            }}
          >
            {CardDetails.map((detail, i) => (
              <Card
                key={i}
                sx={{
                  borderRadius: 5,
                  marginLeft: { xl: 5, lg: 5, md: 5, sm: 'auto', xs: 'auto' },
                  minWidth: {
                    xl: 250,
                    lg: 250,
                    md: 250,
                    sm: '100%',
                    xs: '100%',
                  },
                  margin: 'auto',
                  marginY: 3,
                }}
                data-aos={'fade-up'}
                data-aos-offset={100}
                data-aos-duration={600 * i}
              >
                <CardHeader
                  avatar={
                    <Avatar
                      sx={{
                        backgroundColor: 'transparent',
                        color: 'primary.main',
                      }}
                    >
                      {detail.icon}
                    </Avatar>
                  }
                />
                <CardContent>
                  <Typography
                    variant="h5"
                    sx={{
                      color: 'primary',
                      marginBottom: 2,
                      fontWeight: 400,
                    }}
                  >
                    {detail.header}
                  </Typography>
                  <Typography>{detail.body}</Typography>
                </CardContent>
                <CardActions>
                  <Button
                    endIcon={<ArrowForwardIcon />}
                    size="small"
                    component={'a'}
                    href={'contact-us'}
                  >
                    Learn More
                  </Button>
                </CardActions>
              </Card>
            ))}
          </Box>
        </Box>
      </Box>
    </Container>
  );
};

export default Benefits;
