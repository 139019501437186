import * as React from 'react';
import Button from '@mui/material/Button';

import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';

import List from '@mui/material/List';

import ListItemButton from '@mui/material/ListItemButton';

import ListItemText from '@mui/material/ListItemText';

import NavigateNextIcon from '@mui/icons-material/NavigateNext';

import { pageslist } from './pageslist';

export default function CourseList() {
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <Button variant="outlined" onClick={handleClickOpen}>
        Get Started
      </Button>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle> Pick your Course</DialogTitle>
        <DialogContent sx={{ width: '300px' }}>
          <List>
            {pageslist.map((pages, i) => {
              return (
                <ListItemButton key={i} component="a" href={pages.href}>
                  <ListItemText primary={pages.title} /> <NavigateNextIcon />
                </ListItemButton>
              );
            })}
          </List>
        </DialogContent>
      </Dialog>
    </div>
  );
}
