import React from 'react';

import {
  About as AboutView,
  BlogArticle as BlogArticleView,
  BlogReach as BlogReachView,
  CareerOpening as CareerOpeningView,
  Challenge as ChallengeView,
  ContactUs as ContactUsView,
  Coworking as CoworkingView,
  Create as CreateView,
  IndexView,
  Mentor as MentorView,
  MobileApp as MobileAppView,
  NotFoundCover as NotFoundCoverView,
  NotFound as NotFoundView,
  OnlinePlatform as OnlinePlatformView,
  PrivacyPolicy as PrivacyPolicyView,
  SeminarEvent as SeminarEventView,
  Training as TrainingView,
  WebDesign as WebDesignView,
  WebDevelopment as WebDevView,
  LogIn as LogInView,
  CourseDetails as CourseDetailsView,
} from 'views';

const routes = [
  {
    path: '/',
    renderer: (params = {}) => <IndexView {...params} />,
  },
  // {
  //   path: '/home',
  //   renderer: (params = {}) => <HomeView {...params} />,
  // },
  {
    path: '/training',
    renderer: (params = {}) => <TrainingView {...params} />,
  },

  {
    path: '/contact-us',
    renderer: (params = {}) => <ContactUsView {...params} />,
  },
  {
    path: '/coworkingspace',
    renderer: (params = {}) => <CoworkingView {...params} />,
  },
  {
    path: '/career-opening',
    renderer: (params = {}) => <CareerOpeningView {...params} />,
  },

  {
    path: '/seminar-event',
    renderer: (params = {}) => <SeminarEventView {...params} />,
  },
  {
    path: '/mobile-app-development',
    renderer: (params = {}) => <MobileAppView {...params} />,
  },
  {
    path: '/about',
    renderer: (params = {}) => <AboutView {...params} />,
  },
  {
    path: '/blog',
    renderer: (params = {}) => <BlogReachView {...params} />,
  },
  {
    path: '/web-development',
    renderer: (params = {}) => <WebDevView {...params} />,
  },

  {
    path: '/web-design',
    renderer: (params = {}) => <WebDesignView {...params} />,
  },

  {
    path: '/privacy-policy',
    renderer: (params = {}) => <PrivacyPolicyView {...params} />,
  },
  {
    path: '/not-found',
    renderer: (params = {}) => <NotFoundView {...params} />,
  },
  {
    path: '/not-found-cover',
    renderer: (params = {}) => <NotFoundCoverView {...params} />,
  },
  {
    path: '/blog/:id',
    renderer: (params = {}) => <BlogArticleView {...params} />,
  },
  {
    path: '/challenge',
    renderer: (params = {}) => <ChallengeView {...params} />,
  },
  {
    path: '/onlineplatform',
    renderer: (params = {}) => <OnlinePlatformView {...params} />,
  },
  {
    path: '/onlineplatform/mentor',
    renderer: (params = {}) => <MentorView {...params} />,
  },
  {
    path: '/onlineplatform/create',
    renderer: (params = {}) => <CreateView {...params} />,
  },
  {
    path: '/onlineplatform/login',
    renderer: (params = {}) => <LogInView {...params} />,
  },
  {
    path: '/onlineplatform/coursedetails',
    renderer: (params = {}) => <CourseDetailsView {...params} />,
  },
];

export default routes;
