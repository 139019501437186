/* eslint-disable react/no-unescaped-entities */
import { colors } from '@mui/material';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import { useTheme } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import useMediaQuery from '@mui/material/useMediaQuery';
import React from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { assets } from 'assets/assets';

const CaseStudy3 = () => {
  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.up('md'), {
    defaultMatches: true,
  });

  return (
    <Box
      padding={{ xs: 2, sm: 4 }}
      borderRadius={2}
      bgcolor={theme.palette.mode === 'light' ? colors.red[50] : '#DEB22F'}
      data-aos={'fade-up'}
    >
      <Typography
        variant="h4"
        color="text.primary"
        textAlign="center"
        fontWeight={600}
        marginY={2}
        sx={{
          borderBottomLeftRadius: 20,
          borderTopRightRadius: 20,
          padding: 3,
          bgColor: colors.green[50],
          opacity: 0.9,
        }}
      >
        BASIC PRINCIPLES OF MOBILE APP DESIGN
        <Typography variant="body2" color="text.primary" textAlign={'center'}>
          App design is a combination of UI and UX design to build out a usable
          piece of software.
        </Typography>
      </Typography>
      <Grid
        container
        spacing={isMd ? 4 : 2}
        flexDirection={{ xs: 'column', md: 'row' }}
      >
        <Grid item xs={12} md={8}>
          <Grid container spacing={isMd ? 4 : 2}>
            <Grid
              item
              xs={12}
              sx={{
                '& .lazy-load-image-background.lazy-load-image-loaded': {
                  width: '100%',
                  height: '100%',
                },
              }}
            >
              <Box
                component={LazyLoadImage}
                height={1}
                width={1}
                src={assets.webDesign.mobilePhone}
                alt="..."
                effect="blur"
                borderRadius={2}
                maxWidth={1}
                maxHeight={400}
                sx={{
                  objectFit: 'cover',
                }}
              />
            </Grid>
            <Grid item xs={6}>
              <Typography
                variant={'h6'}
                fontWeight={700}
                gutterBottom
                textTransform={'uppercase'}
              >
                Make it Easy
              </Typography>
              <Typography component={'p'}>
                Prove your app's value by giving users what they want right
                away. Normally, when someone downloads an app, he or she wants
                to use it for performing a specific task. Try to make a great
                first impression once they open your app. Don't interrupt them
                while they're using your app or at least don't interrupt them
                immediately.
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography
                variant={'h6'}
                fontWeight={700}
                gutterBottom
                textTransform={'uppercase'}
              >
                predictable navigation
              </Typography>
              <Typography component={'p'}>
                People get used to the design patterns they encounter every day.
                For instance, they will expect to be able to swipe through
                pictures. Make sure you adhere to the typical navigation methods
                of the Internet.
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} md={4}>
          <Box
            display={'flex'}
            flexDirection={'column'}
            justifyContent={{ xs: 'flex-start', md: 'space-between' }}
            height={1}
          >
            <Box>
              <Typography
                variant={'h6'}
                fontWeight={700}
                gutterBottom
                textTransform={'uppercase'}
              >
                F pattern vs. Z pattern
              </Typography>
              <Typography
                color={
                  theme.palette.mode === 'light'
                    ? 'text.secondary'
                    : 'text.primary'
                }
              >
                Most users aren't going to read text from start to finish. They
                are more likely to scan multiple paragraphs of text. The first
                few words of the opening paragraphs get the most attention.
                <br />
                <br />
                Users scan visual content in a Z-shaped pattern. Place your most
                important visuals in the areas where they are most likely to be
                seen by the users, following the Z-shape pattern on your
                webpages.
              </Typography>
            </Box>
            <Box
              component={Card}
              marginTop={{ xs: 2, md: 0 }}
              boxShadow={0}
              borderRadius={2}
            >
              <CardContent sx={{ padding: { xs: 2, sm: 4 } }}>
                <Box
                  component="img"
                  height={'100%'}
                  width={1}
                  src={assets.webDesign.priinciplesWoman}
                  alt="..."
                  maxWidth={80}
                  marginBottom={2}
                  sx={{
                    filter: theme.palette.mode === 'dark' ? 'none' : 'none',
                    borderRadius: '100%',
                  }}
                />
                <Typography
                  variant="p"
                  component={'em'}
                  color={
                    theme.palette.mode === 'light'
                      ? 'text.secondary'
                      : 'text.primary'
                  }
                >
                  "Topibro Technology is one of best design companies in the
                  market today. Their work is high quality and I have never had
                  a problem - not even once. I am confident that anyone who has
                  the opportunity to work with them will be more than satisfied
                  with the final product."
                </Typography>
                <Box marginTop={{ xs: 2, sm: 4 }}>
                  <Typography variant={'subtitle1'} sx={{ fontWeight: 700 }}>
                    Silver Andrey
                  </Typography>
                  <Divider variant="fullWidth" />
                  <Typography color="text.secondary">CEO - iSELL26</Typography>
                </Box>
              </CardContent>
            </Box>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default CaseStudy3;
