import * as React from 'react';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';

const LogIn = () => {
  return (
    <Box
      component="form"
      sx={{
        width: '100%',
      }}
      noValidate
      autoComplete="off"
    >
      <div>
        <Grid container>
          <Grid item xs={12} sm={12}>
            <TextField
              margin="dense"
              name="email"
              label="Email Address"
              type="email"
              fullWidth
              variant="outlined"
              value=""
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              id="outlined-password-input"
              label="Password"
              type="password"
              autoComplete="current-password"
            />
          </Grid>
          <Grid item xs={6}>
            <Button
              sx={{ width: '100%' }}
              size={'large'}
              variant="contained"
              //   onClick={#}
            >
              Log In
            </Button>
          </Grid>
        </Grid>
      </div>
    </Box>
  );
};

export default LogIn;
