import LinkedInIcon from '@mui/icons-material/LinkedIn';
import TwitterIcon from '@mui/icons-material/Twitter';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import { alpha, useTheme } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import React from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { assets } from 'assets/assets';

const mock = [
  {
    name: 'Adelaja Ibrahim',
    title: 'Lead Developer',
    twitter: 'https://twitter.com/adescode',
    linkedin: 'https://www.linkedin.com/company/adescode',
    photo: assets.indexView.teamPics.ibrahim,
    subtitle:
      'Adelaja, a renowned software developer that is skilled on both mobile and web app development',
    icon: (
      <svg
        height={24}
        width={24}
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        stroke="currentColor"
      >
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={2}
          d="M15 15l-2 5L9 9l11 4-5 2zm0 0l5 5M7.188 2.239l.777 2.897M5.136 7.965l-2.898-.777M13.95 4.05l-2.122 2.122m-5.657 5.656l-2.12 2.122"
        />
      </svg>
    ),
  },
  {
    name: 'Enaholo Akhere',
    title: 'MERN Developer',
    twitter: 'https://twitter.com/i_code_ena',
    linkedin: 'https://www.linkedin.com/in/enaholo-akhere-2253a213a',
    photo: assets.indexView.teamPics.desmond,
    subtitle:
      'An IT certified tutor and a full stack developer skilled on MERN stack technologies.',
    icon: (
      <svg
        height={24}
        width={24}
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        stroke="currentColor"
      >
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={2}
          d="M7 21a4 4 0 01-4-4V5a2 2 0 012-2h4a2 2 0 012 2v12a4 4 0 01-4 4zm0 0h12a2 2 0 002-2v-4a2 2 0 00-2-2h-2.343M11 7.343l1.657-1.657a2 2 0 012.828 0l2.829 2.829a2 2 0 010 2.828l-8.486 8.485M7 17h.01"
        />
      </svg>
    ),
  },
  {
    name: 'Madu Williams',
    title: 'Training Instructor',
    twitter: 'https://www.twitter.com/Brainbox_willy',
    linkedin: 'https://www.linkedin.com/in/prodigywilly',
    photo: assets.indexView.teamPics.williams,
    subtitle:
      'A FrontEnd Developer(Web Design & Development Instructor proficient in React, Redux, and other web technologies).',
    icon: (
      <svg
        height={24}
        width={24}
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        stroke="currentColor"
      >
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={2}
          d="M10 20l4-16m4 4l4 4-4 4M6 16l-4-4 4-4"
        />
      </svg>
    ),
  },
];

const Faculties = () => {
  const theme = useTheme();
  return (
    <Container
      data-aos={'fade-up'}
      data-aos-offset={100}
      data-aos-duration={600}
      display="flex"
      flexDirection="center"
    >
      <Box
        justifyContent="center"
        marginY={5}
        display="flex"
        flexDirection={'column'}
      >
        <Typography
          variant="h1"
          fontWeight={600}
          textAlign="center"
          textTransform={'uppercase'}
          sx={{
            width: 'fit-content',
            margin: 'auto',
            color: 'white',
            filter: 0.9,
          }}
        >
          FACULTIES
        </Typography>
        <Typography
          variant="p"
          fontWeight={400}
          textAlign="center"
          color="white"
          sx={{ filter: 0.9 }}
        >
          Meet our professional tutors
        </Typography>
      </Box>
      <Grid container spacing={2} justifyContent="center">
        {mock.map((item, i) => (
          <Grid item xs={12} md={4} key={i} justifyContent="center">
            <Box
              width={1}
              height={1}
              data-aos={'fade-up'}
              data-aos-delay={i * 100}
              data-aos-offset={100}
              data-aos-duration={600}
              marginY={3}
              justifyContent="center"
            >
              <Box
                display={'flex'}
                flexDirection={'column'}
                alignItems={'center'}
                justifyContent={'center'}
              >
                <Box width={300} height={300}>
                  <Box
                    component={LazyLoadImage}
                    width={1}
                    height={1}
                    marginBottom={2}
                    bgcolor={alpha(theme.palette.primary.main, 0.1)}
                    src={item.photo}
                    alt="topibrotech, training"
                    display="flex"
                    justifyContent={'center'}
                    sx={{
                      objectFit: 'cover',
                      borderRadius: 50,
                      padding: 2,
                      border: '2px solid white',
                    }}
                  />
                </Box>
                <Typography
                  variant={'h6'}
                  gutterBottom
                  sx={{ fontWeight: 700, filter: 0.9 }}
                  textAlign={'center'}
                  textTransform="capitalize"
                  color="white"
                >
                  {item.name}
                </Typography>
                <Typography
                  sx={{
                    textAlign: 'center',
                    filter: 0.9,
                  }}
                  color="white"
                >
                  {item.title}
                </Typography>
                <Typography
                  sx={{
                    textAlign: 'center',
                    filter: 0.9,
                  }}
                  color="white"
                >
                  {item.subtitle}
                </Typography>
              </Box>
              <Box textAlign="center" marginY={3} alignItems="center">
                <Box
                  component={'a'}
                  href={item.linkedin}
                  color="white"
                  textDecoration="none"
                  paddingRight={3}
                  textAlign="center"
                >
                  <LinkedInIcon
                    sx={{
                      fontSize: 25,
                    }}
                  />
                </Box>
                <Box
                  component={'a'}
                  href={item.twitter}
                  color="white"
                  textDecoration="none"
                  textAlign="center"
                >
                  <TwitterIcon
                    sx={{
                      fontSize: 25,
                    }}
                  />
                </Box>
              </Box>
            </Box>
          </Grid>
        ))}
      </Grid>
    </Container>
  );
};

export default Faculties;
