/* eslint-disable react/jsx-no-comment-textnodes */
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import React from 'react';

const Background = () => {
  const background = [
    {
      paragragh: `This Policy explains the manner in which we (Topibro Technologies) collect, process and use your personal data. It includes all services rendered by or  patronized under Topibro Technologies (Topibro Tech)
    We are regulated by the relevant data protection authorities, laws and regulations in all the jurisdictions in which we operate`,
    },
    {
      paragragh: `Please read this Policy carefully and ensure that you understand it.  Your acceptance of this Policy is required. 
    This Policy (the “Privacy Policy“) explains the types of information we may collect from Users or that Users may provide when using our Services, and which may likewise be collected from website visitors, employment candidates and others.`,
    },
    {
      paragragh: `This Policy also describes Topibro Tech’s practices for collecting, using, maintaining and processing information, including through the Topibro Technologies website.`,
    },
    {
      paragragh: `
    Users who wish to use the Services may be asked to provide Topibro Tech, either directly or through the company administrator, with certain information including Personal Data and Sensitive Information as further detailed in this Privacy Policy (“Data“.`,
    },
    {
      paragragh: `Topibro Tech’s use of this Data in connection with the Services will be undertaken in accordance with this Privacy Policy.
    Please read the following carefully to understand our practices regarding your personal data and how we will treat it.`,
    },
  ];

  return (
    <Container id="background">
      <Box sx={{ height: 400, my: 5 }}>
        <Box
          component={'img'}
          src={
            'https://cdn.pixabay.com/photo/2018/03/21/15/04/regulation-3246979_960_720.jpg'
            // 'https://firebasestorage.googleapis.com/v0/b/topibro-tech.appspot.com/o/minified-images%2FPrivacy-Policy-Image.png?alt=media&token=ba4c7f41-8baf-4f19-bc68-5ac8ac77f30e'
          }
          sx={{
            width: '100%',
            height: '100%',
          }}
        />
      </Box>

      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignContent: 'center',
        }}
      >
        <Box sx={{ dispaly: 'flex', justifyContent: 'left' }}>
          <Typography
            variant="h3"
            sx={{ fontWeight: 600, textAlign: 'left', my: 2 }}
          >
            {`Topibro's Privacy Notice`}
          </Typography>
          <Typography variant="h6" sx={{ textAlign: 'left', my: 2 }}>
            Last updated July 21, 2022
          </Typography>
          <Typography
            variant="h6"
            sx={{ fontWeight: 600, textAlign: 'left', my: 2 }}
          >
            Background:
          </Typography>

          {background.map((paragraph) => (
            <Typography
              key={paragraph.paragragh}
              variant="h6"
              sx={{
                fontWeight: 400,
                textAlign: 'left',
                my: 2,
                color: 'text.secondary',
              }}
            >
              {paragraph.paragragh}
            </Typography>
          ))}
        </Box>
      </Box>
    </Container>
  );
};

export default Background;
