/* eslint-disable react/no-unescaped-entities */
import emailjs from '@emailjs/browser';
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
// import * as yup from 'yup';
import MenuItem from '@mui/material/MenuItem';
import Snackbar from '@mui/material/Snackbar';
import { useTheme } from '@mui/material/styles';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import Container from 'components/Container';
// import { useFormik } from 'formik';
import React, { useRef, useState } from 'react';
import GoogleMap from './GoogleMap';

// const validationSchema = yup.object({
//   firstName: yup
//     .string()
//     .trim()
//     .min(2, 'Please enter a valid name')
//     .max(50, 'Please enter a valid name')
//     .required('Please specify your first name'),
//   lastName: yup
//     .string()
//     .trim()
//     .min(2, 'Please enter a valid name')
//     .max(50, 'Please enter a valid name')
//     .required('Please specify your last name'),
//   email: yup
//     .string()
//     .trim()
//     .email('Please enter a valid email address')
//     .required('Email is required.'),
//   message: yup.string().trim().required('Please specify your message'),
//   training: yup
//     .string()
//     .trim()
//     .min(2, 'Please enter a valid name')
//     .max(50, 'Please enter a valid name')
//     .required('Please specify your first name'),
//   interest: yup
//     .string()
//     .trim()
//     .min(2, 'Please enter a valid name')
//     .max(50, 'Please enter a valid name')
//     .required('Please specify your first name'),
// });

const initialFormData = {
  firstname: '',
  lastname: '',
  interest: '',
  phone: '',
  email: '',
  message: '',
};

const Contact = () => {
  // const [courses, setCourses] = useState('Select a course');

  const coursesList = [
    { label: 'Coworking', value: 'coworking' },
    { label: 'Training', value: 'training' },
    { label: 'Seminar', value: 'seminar' },
    { label: 'Web Design', value: 'web_design' },
    { label: 'Web Development', value: 'web_development' },
    { label: 'Mobile Development', value: 'mobile_app_Development' },
  ];

  const theme = useTheme();

  const LeftSide = () => {
    const [openAlert, setOpenAlert] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [formData, setFormData] = useState(initialFormData);
    console.log(formData);

    const form = useRef();

    const sendEmail = (e) => {
      e.preventDefault();
      console.log(e.target);

      setIsLoading(true);
      emailjs
        .sendForm(
          'service_ipm3eqx',
          'template_fycqgh6',
          e.target,
          'user_s7ajq5XRUNguQ8PK5ZAgR',
        )
        .then(
          (result) => {
            console.log(result);
            setOpenAlert(true);
            setIsLoading(false);
            e.target.reset();
            setFormData(initialFormData);
          },
          (error) => {
            console.log(error.text);
          },
        );
    };

    return (
      <Box>
        <Box marginBottom={4}>
          <Typography variant={'h3'} sx={{ fontWeight: 700 }} gutterBottom>
            Contact us
          </Typography>
        </Box>
        <Box>
          <form ref={form} onSubmit={sendEmail}>
            <Grid container spacing={4}>
              <Grid item xs={12} sm={6}>
                <TextField
                  sx={{ height: 54 }}
                  required={true}
                  label="First name"
                  variant="outlined"
                  color="primary"
                  size="medium"
                  name="firstname"
                  fullWidth
                  value={formData.firstname}
                  onChange={(e) => {
                    return setFormData((prev) => ({
                      ...prev,
                      firstname: e.target.value,
                    }));
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  required={true}
                  sx={{ height: 54 }}
                  label="Last name"
                  variant="outlined"
                  color="primary"
                  size="medium"
                  name="lastname"
                  fullWidth
                  value={formData.lastname}
                  onChange={(e) => {
                    return setFormData((prev) => ({
                      ...prev,
                      lastname: e.target.value,
                    }));
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  required={true}
                  select
                  label="Interests"
                  fullWidth
                  name="interest"
                  helperText="Please select your interest"
                  variant="outlined"
                  value={formData.interest}
                  onChange={(e) => {
                    return setFormData((prev) => ({
                      ...prev,
                      interest: e.target.value,
                    }));
                  }}
                >
                  {coursesList
                    .sort((a, b) => (a.value > b.value ? 1 : -1))
                    .map((option) => (
                      <MenuItem key={option.value} value={option.value}>
                        {option.label}
                      </MenuItem>
                    ))}
                </TextField>
              </Grid>
              <Grid item xs={12}>
                <TextField
                  sx={{ height: 54 }}
                  required={true}
                  label="Email"
                  type="email"
                  variant="outlined"
                  color="primary"
                  size="medium"
                  name="email"
                  fullWidth
                  value={formData.email}
                  onChange={(e) => {
                    return setFormData((prev) => ({
                      ...prev,
                      email: e.target.value,
                    }));
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  sx={{ height: 54 }}
                  required={true}
                  label="Phone"
                  type={'number'}
                  variant="outlined"
                  color="primary"
                  size="medium"
                  name="phone"
                  fullWidth
                  value={formData.phone}
                  onChange={(e) => {
                    return setFormData((prev) => ({
                      ...prev,
                      phone: e.target.value,
                    }));
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  required={true}
                  label="Message"
                  multiline
                  rows={6}
                  variant="outlined"
                  color="primary"
                  size="medium"
                  name="message"
                  fullWidth
                  value={formData.message}
                  onChange={(e) => {
                    return setFormData((prev) => ({
                      ...prev,
                      message: e.target.value,
                    }));
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <Button
                  disabled={isLoading}
                  sx={{ height: 54, minWidth: 150 }}
                  variant="contained"
                  color="primary"
                  size="medium"
                  type="submit"
                >
                  {isLoading ? 'Sending...' : 'Send Message'}
                </Button>
                <Snackbar
                  anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                  autoHideDuration={6000}
                  open={openAlert}
                  onClose={() => setOpenAlert(false)}
                  key={'bottomcenter'}
                >
                  <Alert severity="success">
                    <AlertTitle>Email sent</AlertTitle>
                    You'll get feedback from us soon<strong>Thank you!</strong>
                  </Alert>
                </Snackbar>
              </Grid>

              <Grid item xs={12}>
                <Typography color="text.secondary">
                  We'll get back to you in 24hours.
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Divider />
              </Grid>
              <Grid item xs={12}>
                <Box>
                  <Typography component="p" variant="body2" align="left">
                    By clicking on "submit" you agree to our{' '}
                    <Box
                      component="a"
                      href="privacy-policy"
                      color={theme.palette.text.primary}
                      fontWeight={'700'}
                    >
                      Privacy Policy
                    </Box>
                    ,{' '}
                    {/* <Box
                      component="a"
                      href=""
                      color={theme.palette.text.primary}
                      fontWeight={'700'}
                    >
                      Data Policy
                    </Box>{' '}
                    and{' '}
                    <Box
                      component="a"
                      href=""
                      color={theme.palette.text.primary}
                      fontWeight={'700'}
                    >
                      Cookie Policy
                    </Box> */}
                    .
                  </Typography>
                </Box>
              </Grid>
            </Grid>
          </form>
        </Box>
      </Box>
    );
  };

  return (
    <Box
      sx={{
        width: 1,
        height: 1,
        overflow: 'hidden',
      }}
    >
      <Container paddingX={0} paddingY={0} maxWidth={{ sm: 1, md: 1236 }}>
        <Box
          display={'flex'}
          flexDirection={{ xs: 'column', md: 'row' }}
          position={'relative'}
        >
          <Box
            display={'flex'}
            alignItems={'center'}
            width={1}
            order={{ xs: 2, md: 1 }}
          >
            <Container>
              <LeftSide />
            </Container>
          </Box>
          <Box
            sx={{
              flex: { xs: '0 0 100%', md: '0 0 50%' },
              position: 'relative',
              maxWidth: { xs: '100%', md: '50%' },
              minHeight: { xs: 300, md: 600 },
              order: { xs: 1, md: 2 },
            }}
          >
            <Box
              sx={{
                width: { xs: 1, md: '50vw' },
                height: '100%',
                position: 'relative',
              }}
            >
              <Box
                sx={{
                  width: '100%',
                  height: '100%',
                  overflow: 'hidden',
                }}
              >
                <Box
                  sx={{
                    overflow: 'hidden',
                    left: '0%',
                    width: 1,
                    height: 1,
                    position: { xs: 'relative', md: 'absolute' },
                  }}
                >
                  <GoogleMap />
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
      </Container>
      <Divider />
    </Box>
  );
};

export default Contact;
