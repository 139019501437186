/* eslint-disable react/no-unescaped-entities */
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import { useTheme } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import useMediaQuery from '@mui/material/useMediaQuery';
import React, { useState } from 'react';
import { assets } from 'assets/assets';

const initialWhoWeAre = `We are a digital agency comprising a wide range of professionals. From web designers, developers, digital marketing experts, project managers, technicians as well as graphic designers all playing unique roles in ensuring client job delivery is on an excellent par as it is our watch word.


Bridging the digital gap between business ideas and their prospective clients, we provide custom solutions based on the specific need of the clients

Our core values are hinged on superior service delivery, excellent user experience and solution- oriented systems. Our integrated business model guarantees easy access to all our services, time saving and cost- effectiveness.


Our clientele include businesses, government agencies, start ups, multinationals, SME's etc .


We believe that with the right tools in place, technology can scale up your business beyond what you can ever imagine and we are set to make it happen.
`;
const WhoWeAre = () => {
  const [readMore, setReadMore] = useState(true);
  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.up('md'), {
    defaultMatches: true,
  });
  const readMoreToggle = () => {
    setReadMore((prev) => !prev);
  };
  return (
    <Box>
      <Grid container spacing={4} direction={isMd ? 'row-reverse' : 'column'}>
        <Grid
          item
          container
          alignItems={'center'}
          justifyContent="center"
          xs={12}
          md={6}
        >
          <Box>
            <Typography variant={'h4'} gutterBottom sx={{ fontWeight: 700 }}>
              Who we are
            </Typography>
            <Typography component={'p'} color={'text.secondary'}>
              {initialWhoWeAre
                .split(' ')
                .slice(0, readMore ? 100 : initialWhoWeAre.length - 1)
                .join(' ')}
              {readMore && '...'}
            </Typography>
            <Button variant="outlined" onClick={readMoreToggle}>
              {readMore ? 'Read More' : 'Read Less'}
            </Button>
          </Box>
        </Grid>
        <Grid
          item
          container
          justifyContent="center"
          alignItems="center"
          xs={12}
          md={6}
        >
          <Box maxWidth={700} width={1}>
            <Box
              component={'img'}
              src={assets.aboutUsSection.whoWeAre}
              width={'100%'}
              height={1}
              sx={{
                filter:
                  theme.palette.mode === 'dark' ? 'brightness(0.8)' : 'none',
              }}
            />
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default WhoWeAre;
