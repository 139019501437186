import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Grid from '@mui/material/Grid';
import ListItemText from '@mui/material/ListItemText';
import Typography from '@mui/material/Typography';
import { assets } from 'assets/assets';
import React from 'react';

const Team = () => {
  return (
    <Box>
      <Box marginBottom={4}>
        {/* <Typography
          sx={{
            textTransform: 'uppercase',
            fontWeight: 'medium',
          }}
          gutterBottom
          color={'secondary'}
          align={'center'}
        >
          Our team
        </Typography> */}
        <Typography
          fontWeight={700}
          variant={'h4'}
          align={'center'}
          sx={{
            textTransform: 'uppercase',
          }}
        >
          Meet Our team
        </Typography>
      </Box>
      <Grid container spacing={2}>
        {[
          {
            name: 'Adelaja Ibrahim',
            title: 'Lead developer',
            altText: 'topibrotech, software developer',
            avatar: assets.indexView.teamPics.ibrahim,
          },
          {
            name: 'Fabiyi Johnson',
            title: 'Fullstack Developer',
            altText: 'topibrotech, software developer',
            avatar: assets.indexView.teamPics.fabiyi,
          },
          {
            name: 'Nkem Anita',
            title: 'Admin Officer',
            altText: 'topibrotech, Admin Officer',
            avatar: assets.indexView.teamPics.anita,
          },
          {
            name: 'Saheed Olayioye',
            title: 'Software Developer',
            altText: 'topibrotech, Software Developer',
            avatar: assets.indexView.teamPics.saheed,
          },
          {
            name: 'Oshininioluwa Joshua',
            title: 'Graphics Designer',
            altText: 'topibrotech, Graphics designer',
            avatar: assets.indexView.teamPics.joshua,
          },
          {
            name: 'Ogbbonna  Kennedy',
            title: 'Digital Marketer',
            altText: 'topibrotech, Digital Marketer',
            avatar: assets.indexView.teamPics.kennedy,
          },
          {
            name: 'Jesutofunmi George',
            title: 'Ui/Ux  Designer',
            altText: 'topibrotech, UI/UX Designer',
            avatar: assets.indexView.teamPics.george,
          },

          {
            name: 'Nwakire Osinakachi',
            title: 'Data Analysis Tutor',
            altText: 'topibrotech, Data Analysis',
            avatar: assets.indexView.teamPics.osinakachi,
          },

          {
            name: 'Oseh Maurice',
            title: 'Fullstack Developer',
            altText: 'topibrotech, software developer',
            avatar: assets.indexView.teamPics.maurice,
          },
        ].map((item, i) => (
          <Grid
            item
            xs={12}
            sm={6}
            md={3}
            key={i}
            data-aos={'fade-up'}
            data-aos-delay={i * 100}
            data-aos-offset={100}
            data-aos-duration={600}
          >
            <Card
              sx={{
                boxShadow: 0,
                background: 'transparent',
                backgroundImage: 'none',
              }}
            >
              <Box
                component={CardMedia}
                borderRadius={2}
                width={1}
                height={1}
                minHeight={320}
                image={item.avatar}
              />
              <Box
                component={CardContent}
                bgcolor={'transparent'}
                marginTop={-5}
              >
                <Box component={Card}>
                  <CardContent>
                    <ListItemText primary={item.name} secondary={item.title} />
                  </CardContent>
                </Box>
              </Box>
            </Card>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default Team;
