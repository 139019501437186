import { useTheme } from '@emotion/react';
import FacebookIcon from '@mui/icons-material/Facebook';
import InstagramIcon from '@mui/icons-material/Instagram';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import PhoneIcon from '@mui/icons-material/Phone';
import TwitterIcon from '@mui/icons-material/Twitter';
import Box from '@mui/material/Box';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import PropTypes from 'prop-types';
import React from 'react';
import MailtoButton from './MailtoButton';

const TopNav = ({ colorInvert = false }) => {
  const theme = useTheme();
  return (
    <Box
      display={'flex'}
      justifyContent={'flex-end'}
      alignItems={'center'}
      zIndex={theme.zIndex.appBar}
    >
      <Box
        sx={{
          flexGrow: 1,
          marginRight: { xs: 1, sm: 2 },
          display: 'flex',
        }}
      >
        <Link
          underline="none"
          component="a"
          href="tel:+2349134079164"
          color={colorInvert ? 'common.white' : 'text.primary'}
          sx={{
            display: 'flex',
            flexDirection: 'row',
          }}
        >
          <PhoneIcon
            sx={{
              marginRight: { xl: 0.5, lg: 0.5, md: 0.5, sm: 1.5, xs: 1.5 },
              fontSize: 20,
            }}
          />
          <Box
            padding={0.5}
            display={'inline-flex'}
            borderRadius={1}
            marginRight={2}
          >
            <Typography
              variant={'caption'}
              sx={{ lineHeight: 1 }}
              display={{ xl: 'block', lg: 'block', sm: 'none', xs: 'none' }}
              fontSize={15}
            >
              +234 913 407 9164
            </Typography>
          </Box>
        </Link>
        <MailtoButton
          label="info@topibrotech.com"
          mailto="info@topibrotech.com"
          colorInvert={colorInvert}
        />
        <Link
          underline="none"
          component="a"
          target="_blank"
          href="http://maps.google.com/?q=Topibro+Technologies"
          color={colorInvert ? 'common.white' : 'text.primary'}
          sx={{
            display: 'flex',
            flexDirection: 'row',
          }}
        >
          <LocationOnIcon
            sx={{
              marginRight: { xl: 0.5, lg: 0.5, md: 0.5, sm: 1.5, xs: 1.5 },
              fontSize: 20,
            }}
          />
          <Box
            padding={0.5}
            display={'inline-flex'}
            borderRadius={1}
            marginRight={2}
          >
            <Typography
              variant={'caption'}
              sx={{ lineHeight: 1 }}
              display={{ xl: 'block', lg: 'block', sm: 'none', xs: 'none' }}
              fontSize={15}
            >
              Topibro Technologies Ijegun, Lagos.
            </Typography>
          </Box>
        </Link>
      </Box>
      <Box
        marginRight={{ xs: 1, sm: 2 }}
        sx={{ lineHeight: 1 }}
        display={{
          xl: 'block',
          lg: 'block',
          md: 'block',
          sm: 'none',
          xs: 'none',
        }}
      >
        <Link
          underline="none"
          component="a"
          href="https://facebook.com/topibrotech"
          color={colorInvert ? 'common.white' : 'text.primary'}
        >
          <FacebookIcon sx={{ fontSize: 20 }} />
        </Link>
      </Box>
      <Box
        marginRight={{ xs: 1, sm: 2 }}
        sx={{ lineHeight: 1 }}
        display={{
          xl: 'block',
          lg: 'block',
          md: 'block',
          sm: 'none',
          xs: 'none',
        }}
      >
        <Link
          underline="none"
          component="a"
          href="https://twitter.com/topibrotech"
          color={colorInvert ? 'common.white' : 'text.primary'}
        >
          <TwitterIcon sx={{ fontSize: 20 }} />
        </Link>
      </Box>
      <Box
        marginRight={{ xs: 1, sm: 2 }}
        sx={{ lineHeight: 1 }}
        display={{
          xl: 'block',
          lg: 'block',
          md: 'block',
          sm: 'none',
          xs: 'none',
        }}
      >
        <Link
          underline="none"
          component="a"
          href="https://instagram.com/topibrotech"
          color={colorInvert ? 'common.white' : 'text.primary'}
        >
          <InstagramIcon sx={{ fontSize: 20 }} />
        </Link>
      </Box>
      <Box
        marginRight={{ xs: 1, sm: 2 }}
        sx={{ lineHeight: 1 }}
        display={{
          xl: 'block',
          lg: 'block',
          md: 'block',
          sm: 'none',
          xs: 'none',
        }}
      >
        <Link
          underline="none"
          component="a"
          href="https://www.linkedin.com/company/topibrotech"
          color={colorInvert ? 'common.white' : 'text.primary'}
        >
          <LinkedInIcon sx={{ fontSize: 20 }} />
        </Link>
      </Box>
      {/* <Box
        sx={{
          backgroundColor: colorInvert ? 'text.primary' : 'common.white',
          borderRadius: 50,
          padding: 0.3,
        }}
      >
        <ThemeModeToggler />
      </Box> */}
    </Box>
  );
};

TopNav.propTypes = {
  colorInvert: PropTypes.bool,
};

export default TopNav;
