/* eslint-disable react/no-unescaped-entities */
import { colors } from '@mui/material';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import { useTheme } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import useMediaQuery from '@mui/material/useMediaQuery';
import React from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { assets } from 'assets/assets';

const CaseStudy2 = () => {
  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.up('md'), {
    defaultMatches: true,
  });

  return (
    <Box
      padding={{ xs: 2, sm: 4 }}
      borderRadius={2}
      bgcolor={
        theme.palette.mode === 'light' ? colors.green[50] : colors.red[900]
      }
      data-aos={'fade-up'}
    >
      <Typography
        variant="h4"
        color="text.primary"
        textAlign="center"
        fontWeight={600}
        marginY={2}
        sx={{
          borderBottomLeftRadius: 20,
          borderTopRightRadius: 20,
          padding: 3,
          bgColor: colors.green[50],
          opacity: 0.9,
        }}
      >
        PRINCIPLES OF GOOD WEBSITE DESIGN
        <Typography variant="body2" color="text.primary" textAlign={'center'}>
          An effective website design should fulfil its intended function by
          conveying its particular message whilst simultaneously engaging the
          visitor.
        </Typography>
      </Typography>
      <Grid
        container
        spacing={isMd ? 4 : 2}
        flexDirection={{ xs: 'column-reverse', md: 'row' }}
      >
        <Grid item xs={12} md={4}>
          <Box
            display={'flex'}
            flexDirection={'column'}
            justifyContent={{ xs: 'flex-start', md: 'space-between' }}
            height={1}
          >
            <Box>
              <Typography variant={'h6'} fontWeight={700} gutterBottom>
                WEBSITE PURPOSE
              </Typography>
              <Typography
                color={
                  theme.palette.mode === 'light'
                    ? 'text.secondary'
                    : 'text.primary'
                }
                opacity={0.9}
              >
                Your website needs to accommodate the needs of the user. Having
                a simple clear intention on all pages will help the user
                interact with what you have to offer.
              </Typography>
            </Box>
            <Box
              component={Card}
              marginTop={{ xs: 2, md: 0 }}
              boxShadow={0}
              borderRadius={2}
            >
              <CardContent sx={{ padding: { xs: 2, sm: 4 } }}>
                <Box
                  component="img"
                  height={1}
                  width={1}
                  src={assets.webDesign.principlesMan}
                  alt="..."
                  maxWidth={80}
                  marginBottom={2}
                  sx={{
                    filter: theme.palette.mode === 'dark' ? 'none' : 'none',
                    borderRadius: '100%',
                  }}
                />
                <Typography
                  component={'em'}
                  variant="p"
                  color={
                    theme.palette.mode === 'light'
                      ? 'text.secondary'
                      : 'text.primary'
                  }
                >
                  "Topibro Technology has been a reliable and responsive design
                  company I can rely on for years. They always deliver high
                  quality work quickly and effectively. I have never been
                  disappointed with their work."
                </Typography>
                <Box marginTop={{ xs: 2, sm: 4 }}>
                  <Typography variant={'subtitle1'} sx={{ fontWeight: 700 }}>
                    Akinremi Julius
                  </Typography>
                  <Divider variant="fullWidth" />
                  <Typography color="text.secondary">MD - Food Mart</Typography>
                </Box>
              </CardContent>
            </Box>
          </Box>
        </Grid>
        <Grid item xs={12} md={8}>
          <Grid container spacing={isMd ? 4 : 2}>
            <Grid
              item
              xs={12}
              sx={{
                '& .lazy-load-image-background.lazy-load-image-loaded': {
                  width: '100%',
                  height: '100%',
                },
              }}
            >
              <Box
                component={LazyLoadImage}
                height={1}
                width={1}
                src={assets.webDesign.principlesLaptop}
                alt="..."
                effect="blur"
                borderRadius={2}
                maxWidth={1}
                maxHeight={400}
                sx={{
                  objectFit: 'cover',
                }}
              />
            </Grid>
            <Grid item xs={6}>
              <Typography variant={'h6'} fontWeight={700} gutterBottom>
                SIMPLICITY
              </Typography>
              <Typography component={'p'}>
                Simplicity is the best way to go when considering the user
                experience and the usability of your website. Below are ways to
                achieve simplicity through design.
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography variant={'h6'} fontWeight={700} gutterBottom>
                CONTENT
              </Typography>
              <Typography component={'p'}>
                An effective website has both great design and great content.
                Using compelling language great content can attract and
                influence visitors by converting them into customers.
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
};

export default CaseStudy2;
