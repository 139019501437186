/* eslint-disable react/no-unescaped-entities */
import { Link } from '@mui/material';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import React from 'react';

const Application = () => {
  return (
    <Box>
      <Box
        display={'flex'}
        justifyContent={'space-between'}
        alignItems={{ xs: 'flex-start', sm: 'center' }}
        flexDirection={{ xs: 'column', sm: 'row' }}
        sx={{
          marginBottom: '-16',
        }}
      >
        <Box>
          <Typography fontWeight={700} variant={'h5'} gutterBottom>
            Interested in joining our team?
          </Typography>
          <Typography>Hit us up and we'll get in touch with you.</Typography>
        </Box>
        <Link component={'a'} href="career-opening">
          <Box display="flex" marginTop={{ xs: 2, md: 0 }}>
            <Button variant="contained" color="primary" size="large">
              Apply
            </Button>
          </Box>
        </Link>
      </Box>
    </Box>
  );
};

export default Application;
