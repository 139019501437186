import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import ListItem from '@mui/material/ListItem';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import { useTheme } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import React from 'react';

const mock = [
  {
    title: 'Step 1 – Web Strategy/Creative Storytelling.',
    subtitle: `We start by understanding your business – your brand, your 
      history, your unique selling points, and of course, your goals. We 
      review your existing website (if you have one), and then 
      familiarize ourselves with your industry to understand best 
      practices. We also make sure we understand the goals of your 
      target audience – this is critical to the success of your website. 
      Finally, we come up with a plan for the website – the architecture,
      wireframes, and content.`,
    icon: (
      <svg
        width={42}
        height={42}
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        stroke="currentColor"
      >
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={2}
          d="M20.618 5.984A11.955 11.955 0 0112 2.944a11.955 11.955 0 01-8.618 3.04A12.02 12.02 0 003 9c0 5.591 3.824 10.29 9 11.622 5.176-1.332 9-6.03 9-11.622 0-1.042-.133-2.052-.382-3.016zM12 9v2m0 4h.01"
        />
      </svg>
    ),
  },
  {
    title: 'Step 2 – Fantastic Design.',
    subtitle: `The brain processes images faster than words. So when 
      designing, we create the right combination of images, colours, 
      fonts, space, and calls-to-action to attract and connect with your 
      audience.
      This ensures that visitors flow through the website, consume 
      content, build trust, and intuitively take the required action.`,
    icon: (
      <svg
        width={42}
        height={42}
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        stroke="currentColor"
      >
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={2}
          d="M12 18h.01M8 21h8a2 2 0 002-2V5a2 2 0 00-2-2H8a2 2 0 00-2 2v14a2 2 0 002 2z"
        />
      </svg>
    ),
  },
  {
    title: 'Step 3 – Development/Invisible Technology.',
    subtitle: `Once we have nailed the design, we move on to the development 
      phase where we build the website theme, add your content, and 
      ensure everything works perfectly.
      By combining these essential elements we build websites that 
      focus on user experience, lead generation, and company 
      reputation.
      In addition to this, if required, we handle complex integrations 
      involving APIs, portals and CRMs.`,
    icon: (
      <svg
        width={42}
        height={42}
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        stroke="currentColor"
      >
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={2}
          d="M9.75 17L9 20l-1 1h8l-1-1-.75-3M3 13h18M5 17h14a2 2 0 002-2V5a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z"
        />
      </svg>
    ),
  },
  {
    title: 'Step 4 – Launch & Maintenance',
    subtitle:
      'When the project is ready, we help you to launch it and push it live. After that, we meet with your team to train them on how to edit, update and scale it.',
    icon: (
      <svg
        width={42}
        height={42}
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        stroke="currentColor"
      >
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={2}
          d="M16 8v8m-4-5v5m-4-2v2m-2 4h12a2 2 0 002-2V6a2 2 0 00-2-2H6a2 2 0 00-2 2v12a2 2 0 002 2z"
        />
      </svg>
    ),
  },
];

const Services = () => {
  const theme = useTheme();

  return (
    <Box>
      <Box marginBottom={4}>
        <Typography
          variant={'h4'}
          sx={{
            textTransform: 'uppercase',
            fontWeight: 'bolder',
          }}
          gutterBottom
          align={'center'}
        >
          Our Process
        </Typography>
      </Box>
      <Grid container spacing={4}>
        {mock.map((item, i) => (
          <Grid key={i} item xs={12} md={6}>
            <ListItem
              component="div"
              disableGutters
              sx={{
                alignItems: 'flex-start',
                padding: 0,
              }}
            >
              <ListItemAvatar>
                <Box color={theme.palette.primary.main}>{item.icon}</Box>
              </ListItemAvatar>
              <Accordion>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <Typography>{item.title}</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography>{item.subtitle}</Typography>
                </AccordionDetails>
              </Accordion>
            </ListItem>
          </Grid>
        ))}
      </Grid>
      <Box
        marginTop={10}
        display={'flex'}
        justifyContent={'center'}
        data-aos={'fade-up'}
      >
        <Button
          component={'a'}
          href="/contact-us"
          color={'primary'}
          variant={'contained'}
          size={'large'}
        >
          Contact us
        </Button>
      </Box>
    </Box>
  );
};

export default Services;
