import { Container, Typography } from '@mui/material';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardMedia from '@mui/material/CardMedia';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import React from 'react';
import Slider from 'react-slick';
import { assets } from 'assets/assets';

const images = [
  assets.mobileDev.carousel5,
  assets.mobileDev.carousel4,
  assets.mobileDev.carousel3,
  assets.mobileDev.carousel2,
  assets.mobileDev.carousel1,
];

const Carousel = () => {
  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.up('md'), {
    defaultMatches: true,
  });

  const sliderOpts = {
    dots: true,
    arrows: false,
    infinite: true,
    slidesToShow: isMd ? 3 : 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
  };

  return (
    <Box>
      <Box>
        <Container width={1} margin="auto">
          <Typography variant="h4" fontWeight={600} textAlign="center">
            Interface - Beautiful and simple layout
          </Typography>
          <Typography
            variant="body1"
            width={'60%'}
            margin={'4px auto'}
            color="text.secondary"
            textAlign="center"
          >
            We design clean and responsive layouts for your application.
          </Typography>
        </Container>
        <Slider {...sliderOpts}>
          {images.map((media, i) => (
            <Box key={i} padding={{ xs: 1, md: 2, lg: 3 }}>
              <Box
                display={'block'}
                width={1}
                height={'100%'}
                sx={{
                  textDecoration: 'none',
                  transition: 'all .2s ease-in-out',
                  '&:hover': {
                    transform: `translateY(-${theme.spacing(1 / 2)})`,
                  },
                }}
              >
                <Box
                  component={Card}
                  width={1}
                  height={'70vh'}
                  display={'flex'}
                  flexDirection={'column'}
                  sx={{ backgroundImage: 'none' }}
                >
                  <CardMedia
                    image={media}
                    alt="topibro - mobile app development"
                    sx={{
                      position: 'relative',
                      height: '100%',
                      overflow: 'hidden',
                    }}
                  ></CardMedia>
                </Box>
              </Box>
            </Box>
          ))}
        </Slider>
      </Box>
    </Box>
  );
};

export default Carousel;
