import FacebookIcon from '@mui/icons-material/Facebook';
import InstagramIcon from '@mui/icons-material/Instagram';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import MailIcon from '@mui/icons-material/Mail';
import PhoneIcon from '@mui/icons-material/Phone';
import TwitterIcon from '@mui/icons-material/Twitter';
import { Button, Container } from '@mui/material';
import Box from '@mui/material/Box';
import Link from '@mui/material/Link';
import { useTheme } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import PropTypes from 'prop-types';
import React from 'react';

const TopNavBottom = ({ colorInvert = false }) => {
  const theme = useTheme();
  const { mode } = theme.palette;
  const services = [
    {
      service: 'Web Development',
      path: 'web-dev',
    },
    {
      service: 'Mobile Development',
      path: 'mobile-app',
    },
    {
      service: 'Web Design',
      path: 'web-design',
    },
  ];
  return (
    <Container
      sx={{
        display: 'flex',
        justifyContent: 'space-around',
        alignItems: 'left',
        width: '90vw',
        margin: 0,
        flexDirection: {
          xl: 'row',
          lg: 'row',
          md: 'row',
          sm: 'column',
          xs: 'column',
        },
      }}
    >
      <Box
        // display={'flex'}
        // component="a"
        // href="/"
        // title="Topibro Tech"
        // width={{ xs: 60, md: 90 }}

        display={'flex'}
        component="a"
        href="/"
        title="topibro"
        width={{ xs: 100, md: 120 }}
        height={1}
      >
        <Box
          component={'img'}
          src={
            mode === 'light' && !colorInvert
              ? 'https://firebasestorage.googleapis.com/v0/b/topibro-tech.appspot.com/o/minified-images%2Ftopibro-logo2.png?alt=media&token=a27cd13f-8d1e-45b5-a191-b0ecde94bb28'
              : 'https://firebasestorage.googleapis.com/v0/b/topibro-tech.appspot.com/o/minified-images%2Ftopibro-logo-dark-theme.png?alt=media&token=44d8ee0a-b693-4135-b5fa-6c256b4ec85c'
          }
          height={'100%'}
          width={1}
        />
      </Box>
      <Box
        sx={{
          textAlign: 'left',
          paddingBottom: 4,
          display: 'flex',
          justifyContent: 'left',
          flexDirection: 'column',
        }}
      >
        <Typography
          variant="h5"
          paddingBottom={1}
          sx={{
            fontSize: { xl: 18, lg: 18, md: 18, sm: 16, xs: 16 },
          }}
        >
          Services
        </Typography>
        {services.map((service, index) => {
          return (
            <Link
              key={index}
              underline="none"
              component="a"
              href={service.path}
              sx={{ display: 'flex', alignItems: 'left' }}
            >
              <Box>
                <Button
                  sx={{
                    marginBottom: 0.5,
                    padding: 0,
                    textAlign: 'left',
                  }}
                >
                  <Typography
                    variant={'caption'}
                    sx={{
                      fontSize: 15,
                      color: colorInvert ? 'common.white' : 'text.primary',
                    }}
                  >
                    {service.service}
                  </Typography>
                </Button>
              </Box>
            </Link>
          );
        })}
      </Box>
      <Box
        sx={{
          marginRight: { xs: 1, sm: 2 },
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'left',
          paddingBottom: 5,
        }}
      >
        <Box
          sx={{
            textAlign: 'left',
            paddingBottom: 1,
          }}
        >
          <Typography
            variant="h5"
            sx={{
              fontSize: { xl: 18, lg: 18, md: 18, sm: 16, xs: 16 },
            }}
          >
            Contact Us
          </Typography>
        </Box>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'left',
          }}
        >
          <Link
            underline="none"
            component="a"
            href="tel:+2349134079164"
            color={colorInvert ? 'common.white' : 'text.primary'}
            sx={{
              display: 'flex',
              alignItems: 'center',
              paddingBottom: 1,
              width: { sx: '100%', sm: '100%' },
            }}
          >
            <PhoneIcon
              sx={{
                marginRight: { xl: 0.5, lg: 0.5, md: 0.5, sm: 1.5, xs: 1.5 },
                fontSize: 20,
              }}
            />
            <Box
              padding={0.5}
              display={'inline-flex'}
              borderRadius={1}
              marginRight={2}
            >
              <Typography
                variant={'caption'}
                display="block"
                sx={{
                  fontSize: 15,
                  lineHeight: 1,
                }}
              >
                +234 913 407 9164
              </Typography>
            </Box>
          </Link>

          <Link
            underline="none"
            component="a"
            target="_blank"
            href="mailto:info@topibrotech.com"
            color={colorInvert ? 'common.white' : 'text.primary'}
            sx={{
              display: 'flex',
              flexDirection: 'row',
              paddingBottom: 1,
            }}
          >
            <MailIcon
              sx={{
                marginRight: { xl: 0.5, lg: 0.5, md: 0.5, sm: 1.5, xs: 1.5 },
                fontSize: 20,
              }}
            />
            <Box
              padding={0.5}
              display={'inline-flex'}
              borderRadius={1}
              marginRight={2}
            >
              <Typography
                variant={'caption'}
                display="block"
                sx={{
                  fontSize: 15,
                  lineHeight: 1,
                }}
              >
                info@topibrotech.com
              </Typography>
            </Box>
          </Link>
          <Link
            underline="none"
            component="a"
            target="_blank"
            href="http://maps.google.com/?q=Topibro+Technologies"
            color={colorInvert ? 'common.white' : 'text.primary'}
            sx={{
              display: 'flex',
              flexDirection: 'row',
              paddingBottom: 1,
            }}
          >
            <LocationOnIcon
              sx={{
                marginRight: { xl: 0.5, lg: 0.5, md: 0.5, sm: 1.5, xs: 1.5 },
                fontSize: 20,
              }}
            />
            <Box
              padding={0.5}
              display={'inline-flex'}
              borderRadius={1}
              marginRight={2}
            >
              <Typography
                variant={'caption'}
                display="block"
                sx={{
                  fontSize: 15,
                  lineHeight: 1,
                }}
              >
                Topibro Technologies Ijegun, Lagos.
              </Typography>
            </Box>
          </Link>
        </Box>
      </Box>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'left',
        }}
      >
        <Box
          sx={{
            textAlign: 'left',
            paddingBottom: 1,
          }}
        >
          <Typography
            variant="h5"
            sx={{
              fontSize: { xl: 18, lg: 18, md: 18, sm: 16, xs: 16 },
            }}
          >
            Find Us On
          </Typography>
        </Box>
        <Link
          underline="none"
          component="a"
          href="https://facebook.com/topibrotech"
          color={colorInvert ? 'common.white' : 'text.primary'}
          display="flex"
        >
          <Box
            padding={0.5}
            display={'inline-flex'}
            borderRadius={1}
            marginRight={2}
          >
            <FacebookIcon
              sx={{
                marginRight: { xl: 0.5, lg: 0.5, md: 0.5, sm: 1.5, xs: 1.5 },
                fontSize: 20,
              }}
            />
            <Typography
              variant={'caption'}
              display="block"
              sx={{
                fontSize: 15,
                lineHeight: 1,
              }}
            >
              Facebook
            </Typography>
          </Box>
        </Link>
        <Link
          underline="none"
          component="a"
          href="https://twitter.com/topibrotech"
          color={colorInvert ? 'common.white' : 'text.primary'}
          display="flex"
        >
          <Box
            padding={0.5}
            display={'inline-flex'}
            borderRadius={1}
            marginRight={2}
          >
            <TwitterIcon
              sx={{
                marginRight: { xl: 0.5, lg: 0.5, md: 0.5, sm: 1.5, xs: 1.5 },
                fontSize: 20,
              }}
            />
            <Typography
              variant={'caption'}
              display="block"
              sx={{
                fontSize: 15,
                lineHeight: 1,
              }}
            >
              Twitter
            </Typography>
          </Box>
        </Link>
        <Link
          underline="none"
          component="a"
          href="https://instagram.com/topibrotech"
          color={colorInvert ? 'common.white' : 'text.primary'}
          display="flex"
        >
          <Box
            padding={0.5}
            display={'inline-flex'}
            borderRadius={1}
            marginRight={2}
          >
            <InstagramIcon
              sx={{
                marginRight: { xl: 0.5, lg: 0.5, md: 0.5, sm: 1.5, xs: 1.5 },
                fontSize: 20,
              }}
            />
            <Typography
              variant={'caption'}
              display="block"
              sx={{
                fontSize: 15,
                lineHeight: 1,
              }}
            >
              Instagram
            </Typography>
          </Box>
        </Link>
        <Link
          underline="none"
          component="a"
          href="https://www.linkedin.com/company/topibrotech/"
          color={colorInvert ? 'common.white' : 'text.primary'}
          display="flex"
        >
          <Box
            padding={0.5}
            display={'inline-flex'}
            borderRadius={1}
            marginRight={2}
          >
            <LinkedInIcon
              sx={{
                marginRight: { xl: 0.5, lg: 0.5, md: 0.5, sm: 1.5, xs: 1.5 },
                fontSize: 20,
              }}
            />
            <Typography
              variant={'caption'}
              display="flex"
              sx={{
                fontSize: 15,
                lineHeight: 1,
              }}
            >
              LinkedIn
            </Typography>
          </Box>
        </Link>
      </Box>
    </Container>
  );
};

TopNavBottom.propTypes = {
  colorInvert: PropTypes.bool,
};

export default TopNavBottom;
