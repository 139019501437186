import React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
// import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import PlayCircleFilledIcon from '@mui/icons-material/PlayCircleFilled';
import NewspaperIcon from '@mui/icons-material/Newspaper';
import CardMembershipIcon from '@mui/icons-material/CardMembership';
const TheCourseInclude = () => {
  const courseExpectations = [
    {
      icon: <PlayCircleFilledIcon sx={{ fontSize: 40 }} />,
      text: '25 hours of video',
    },
    {
      icon: <NewspaperIcon sx={{ fontSize: 40 }} />,
      text: '10 articles + resources',
    },
    {
      icon: <CardMembershipIcon sx={{ fontSize: 40 }} />,
      text: 'Certificate of completion',
    },
  ];
  return (
    <Box sx={{ m: 2 }}>
      <Box sx={{ mb: 4 }}>
        <Typography variant="h5">This course includes: </Typography>
      </Box>
      <Box sx={{}}>
        <Grid container spacing={2}>
          {courseExpectations.map((CE) => {
            return (
              <Grid item xs={12} md={4} key={CE.text}>
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    border: '1px solid gray',
                    p: 4,
                  }}
                >
                  <Box sx={{ mb: 5 }}>{CE.icon}</Box>
                  <Box>
                    <Typography>{CE.text}</Typography>
                  </Box>
                </Box>
              </Grid>
            );
          })}
        </Grid>
      </Box>
    </Box>
  );
};
export default TheCourseInclude;
