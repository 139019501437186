/* eslint-disable react/jsx-no-comment-textnodes */
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import React from 'react';

const DataRetention = () => {
  const dataRetention = [
    {
      paragragh: () => {
        return (
          <Box>
            <Typography>
              Topibro Tech will retain personal data it processes only for as
              long as required in our view, to provide the Service and as
              necessary to comply with our legal obligations, to resolve
              disputes and to enforce agreements. We will also retain personal
              data to meet any audit, compliance and business best-practices.
            </Typography>
          </Box>
        );
      },
    },
    {
      paragragh: () => {
        return (
          <Box>
            <Typography>
              Data that is no longer retained may be anonymized or deleted.
              Likewise, some metadata and statistical information concerning the
              use of the Service are not subject to the deletion procedures in
              this policy and may be retained by TopIbro Technologies. We will
              not be able to identify you from this data. Some data may also be
              retained on our third-party
            </Typography>
          </Box>
        );
      },
    },
  ];

  return (
    <Container sx={{ my: 10 }} id="dataretention">
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignContent: 'center',
        }}
      >
        <Box sx={{ dispaly: 'flex', justifyContent: 'left' }}>
          <Typography
            variant="h4"
            sx={{ fontWeight: 600, textAlign: 'left', my: 2 }}
          >
            DATA RETENTION
          </Typography>

          {dataRetention.map((paragraph) => (
            <Typography
              key={paragraph.paragragh()}
              variant="h6"
              sx={{
                fontWeight: 400,
                textAlign: 'left',
                my: 2,
                color: 'text.secondary',
              }}
            >
              {paragraph.paragragh()}
            </Typography>
          ))}
        </Box>
      </Box>
    </Container>
  );
};

export default DataRetention;
