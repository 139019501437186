import React, { useState } from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import { Button, Link } from '@mui/material';

import AccessAlarmIcon from '@mui/icons-material/AccessAlarm';

const ApplyCard = () => {
  const [loading, setLoading] = useState(false);
  // const [open, setOpen] = useState(false);

  const handleCourseRegistration = () => {
    setLoading(true);
  };

  return (
    <>
      <Box sx={{ display: 'none' }}>
        <Paper
          sx={{
            height: { xs: '80%', md: '80%' },
            width: { xs: '100%', md: '50%' },
            position: 'relative',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            p: 2,
          }}
        >
          <Box
            sx={{
              margin: 'auto',
              position: 'relative',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
            }}
          >
            <Box
              sx={{
                width: 150,
                height: 150,
                bgcolor: 'black',
                p: 3,
                margin: 'auto',
                mb: 4,
              }}
            >
              <Box
                src="/topibro-logo-dark-theme.png"
                alt="topibro-logo"
                height={1}
                width={1}
                component="img"
              />
            </Box>
            <Box
              sx={{
                margin: 'auto',
              }}
            >
              <Typography variant="h1" sx={{ textAlign: 'center' }}>
                Payment Link
              </Typography>
            </Box>
            <Box
              sx={{
                margin: 'auto',
                alignSelf: 'center',
              }}
            >
              <Typography
                sx={{
                  textAlign: 'center',
                  p: 2,
                  fontSize: { xs: 13, md: 20 },
                }}
              >
                <Link href={'#'} passHref={'#'}>
                  hiiiiii
                </Link>
              </Typography>
            </Box>
          </Box>
        </Paper>
      </Box>
      <Card sx={{}}>
        <CardMedia
          component="img"
          height="140"
          image="https://mui.com/static/images/cards/contemplative-reptile.jpg"
          alt="green iguana"
          sx={{ cursor: 'auto', border: '4px solid green' }}
        />
        <CardContent sx={{ display: 'flex', flexDirection: 'column' }}>
          <Box
            sx={{
              display: 'flex',
              mb: 1,
            }}
          >
            <Typography
              variant="body1"
              component={'div'}
              sx={{ fontWeight: 'bolder', fontSize: '3rem' }}
            >
              70,000
            </Typography>
            <Typography sx={{ display: 'flex', fontSize: 15 }}>
              <Typography
                variant="body1"
                sx={{
                  fontWeight: 'light',
                  textDecoration: 'line-through',
                  color: 'red',
                  fontSize: 12,
                }}
              >
                #150,000
              </Typography>
              <Typography sx={{ fontSize: 12 }}>70% off</Typography>
            </Typography>
          </Box>
          <Box sx={{ mb: 4, display: 'flex', alignItems: 'center' }}>
            <Typography sx={{ p: 0.5 }}>
              <AccessAlarmIcon sx={{ fontSize: 18 }} />
            </Typography>
            <Typography sx={{ p: 0.5 }}>
              <span style={{ fontWeight: 'bolder' }}>10 days</span> left at this
              price!
            </Typography>
          </Box>
          <Box sx={{ display: 'flex', flexDirection: 'column' }}>
            {!loading ? (
              <Button
                // disabled={loading}
                variant="outlined"
                sx={{ my: 0.5, borderRadius: 'none' }}
                onClick={handleCourseRegistration}
              >
                Apply
              </Button>
            ) : (
              <Box
                sx={{
                  width: 50,
                  height: 50,
                  margin: 'auto',
                  position: 'relative',
                }}
              >
                {/* <IsLoading isLoading={loading} size={50} /> */}
              </Box>
            )}
          </Box>
        </CardContent>
      </Card>
    </>
  );
};

export default ApplyCard;
