import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Main from 'layouts/Main';
import React, { useRef } from 'react';
import {
  Background,
  DataRetention,
  DataSubjectRight,
  HowWeCollectData,
  TableOfContent,
} from './components';

const PrivacyPolicy = () => {
  const backGroundRef = useRef();
  const howWeCollectDataRef = useRef();
  const dataRetentionRef = useRef();
  const dataSubjectRightRef = useRef();

  const refs = {
    backGroundRef,
    howWeCollectDataRef,
    dataRetentionRef,
    dataSubjectRightRef,
  };

  return (
    <Main>
      <Container>
        <Box ref={backGroundRef}>
          <Background />
        </Box>
        <Box>
          <TableOfContent refs={refs} />
        </Box>
        <Box ref={howWeCollectDataRef}>
          <HowWeCollectData />
        </Box>
        <Box ref={dataRetentionRef}>
          <DataRetention />
        </Box>
        <Box ref={dataSubjectRightRef}>
          <DataSubjectRight />
        </Box>
      </Container>
    </Main>
  );
};

export default PrivacyPolicy;
