import React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Avatar from '@mui/material/Avatar';
import Container from 'components/Container';
import ListItemText from '@mui/material/ListItemText';
// import { alpha } from '@mui/material/styles';
import { useTheme, alpha } from '@mui/material/styles';

import PropTypes from 'prop-types';
const Hero = ({ articleData }) => {
  const theme = useTheme();
  console.log('hero', articleData.backgroundImage);

  return (
    <Box
      position={'relative'}
      sx={{
        backgroundImage: `url("${articleData.backgroundImage}")`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        marginTop: -16,
        paddingTop: 13,
        '&:after': {
          position: 'absolute',
          content: '" "',
          width: '100%',
          height: '100%',
          top: 0,
          right: 0,
          bottom: 0,
          left: 0,
          zIndex: 1,
          background: '#161c2d',
          opacity: 0.6,
        },
      }}
    >
      <Container
        zIndex={3}
        position={'relative'}
        minHeight={{ xs: 300, sm: 400, md: 600 }}
        maxHeight={600}
        display={'flex'}
        alignItems={'center'}
        justifyContent={'center'}
      >
        <Box>
          <Box marginBottom={2}>
            <Typography
              variant="h2"
              align={'center'}
              sx={{
                fontWeight: 700,
                color: theme.palette.common.white,
              }}
            >
              {articleData.backgroundText}
            </Typography>
          </Box>
          <Box display={'flex'} alignItems={'center'}>
            <Avatar
              sx={{ objectFit: 'cover', height: 1, marginRight: 1 }}
              src={articleData.avatar}
            />
            <ListItemText
              sx={{ margin: 0 }}
              primary={articleData.avatarTitle}
              secondary={articleData.date}
              primaryTypographyProps={{
                variant: 'h6',
                sx: { color: 'common.white' },
              }}
              secondaryTypographyProps={{
                sx: { color: alpha('#ffffff', 0.8) },
              }}
            />
          </Box>
        </Box>
      </Container>
      <Box
        component={'svg'}
        preserveAspectRatio="none"
        xmlns="http://www.w3.org/2000/svg"
        x="0px"
        y="0px"
        viewBox="0 0 1920 100.1"
        width={1}
        maxHeight={120}
        bottom={0}
        position={'absolute'}
        zIndex={2}
      >
        <path
          fill={theme.palette.alternate.main}
          d="M0,0c0,0,934.4,93.4,1920,0v100.1H0L0,0z"
        ></path>
      </Box>
    </Box>
  );
};

Hero.propTypes = {
  articleData: PropTypes.obj,
};

export default Hero;
