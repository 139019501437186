export const pageslist = [
  {
    title: 'Web Design',
    href: '/onlineplatform/coursedetails',
  },
  {
    title: 'Web Development',
    href: '/onlineplatform/coursedetails',
  },
  {
    title: 'Data Analysis',
    href: '/webdevelopmentcourse',
  },

  {
    title: 'UI/Ux',
    href: '/webdevelopmentcourse',
  },

  {
    title: 'Graphic Design',
    href: '/webdevelopmentcourse',
  },
];
