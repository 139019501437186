import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import React from 'react';

const OurDesigns = () => {
  const ourDesign = `We design to our customer's delight`;
  return (
    <Box
      sx={{
        textAlign: 'center',
        marginY: 3,
      }}
    >
      <Typography
        variant="h5"
        sx={{
          color: 'main.primary',
        }}
        component="h1"
      >
        Our Designs
      </Typography>
      <Typography
        variant="h3"
        sx={{
          color: 'main.primary.light',
          fontWeight: 700,
          component: 'h2',
        }}
      >
        {ourDesign}
      </Typography>
    </Box>
  );
};

export default OurDesigns;
