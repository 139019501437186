import React from 'react';
import Box from '@mui/material/Box';
import { useTheme } from '@mui/material/styles';
import Grid from '@mui/material/Grid';
// import useMediaQuery from '@mui/material/useMediaQuery';
import { article } from 'utils/Post';

import Main from 'layouts/Main';
import Container from 'components/Container';
import {
  Content,
  FooterNewsletter,
  Hero,
  // SidebarArticles,
  // SidebarNewsletter,
  // SimilarStories,
} from './components';
import { useParams } from 'react-router-dom';

const BlogArticle = () => {
  const { id } = useParams();

  const articleData = article[id - 1];
  console.log('articleData', articleData.hero);
  const theme = useTheme();
  // const isMd = useMediaQuery(theme.breakpoints.up('md'), {
  //   defaultMatches: true,
  // });

  return (
    <Main colorInvert={true}>
      <Box>
        <Hero articleData={articleData.hero} />
        <Container>
          <Grid container spacing={4}>
            <Grid item xs={12}>
              <Content
                articleData={articleData.content}
                articleid={articleData.id}
              />
            </Grid>
            {/* <Grid item xs={12} md={3}>
              <Box>
                <SidebarArticles />
              </Box>
              <SidebarNewsletter />
            </Grid> */}
          </Grid>
        </Container>
        <Box
          component={'svg'}
          preserveAspectRatio="none"
          xmlns="http://www.w3.org/2000/svg"
          x="0px"
          y="0px"
          viewBox="0 0 1920 100.1"
          sx={{
            marginBottom: -1,
            width: 1,
          }}
        >
          <path
            fill={theme.palette.alternate.main}
            d="M0,0c0,0,934.4,93.4,1920,0v100.1H0L0,0z"
          ></path>
        </Box>
      </Box>
      <Box bgcolor={'alternate.main'}>
        {/* <Container>
          <SimilarStories />
        </Container> */}
        <Container>
          <FooterNewsletter />
        </Container>
        <Box
          component={'svg'}
          preserveAspectRatio="none"
          xmlns="http://www.w3.org/2000/svg"
          x="0px"
          y="0px"
          viewBox="0 0 1920 100.1"
          sx={{
            marginBottom: -1,
            width: 1,
          }}
        >
          <path
            fill={theme.palette.background.paper}
            d="M0,0c0,0,934.4,93.4,1920,0v100.1H0L0,0z"
          ></path>
        </Box>
      </Box>
    </Main>
  );
};

export default BlogArticle;
