import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import { useTheme } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import React from 'react';
import { assets } from 'assets/assets';

function Hero() {
  const theme = useTheme();
  //  // const isMd = useMediaQuery(theme.breakpoints.up('md'), {

  return (
    <Box
      height={'85vh'}
      minHeight={'700px'}
      position={'relative'}
      sx={{
        marginTop: -16,
        paddingTop: 13,
        display: 'flex',
        overflow: 'hidden',
        flexDirection: 'row',
        justifyContent: 'center',
        backgroundColor: theme.palette.alternate.main,
        background: `url(${assets.trainingSection.waterDropBackground}) no-repeat center`,
        backgroundSize: 'cover',
      }}
    >
      <Box
        sx={{
          backgroundColor:
            theme.palette.alternate.main === '#1a2138' ? '#1a2138' : '#016D5B',
          opacity: 0.7,
          height: '100%',
          zIndex: 0,
          position: 'absolute',
          width: '100%',
          top: 0,
        }}
      />
      <Box
        sx={{
          display: 'flex',
          flexDirection: {
            xl: 'row',
            lg: 'row',
            md: 'row',
            sm: 'column',
            xs: 'column',
          },
          width: { xl: '80vw', lg: '80vw', md: '90vw', sm: '90vw', xs: '90vw' },
          margin: 'auto',
          // backgroundColor: '#016D5B',
          zIndex: 1,
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flex: 1,
            flexDirection: 'column',
            marginTop: { lg: 10 },
            alignItems: { xs: 'center' },
          }}
        >
          <Typography
            variant="h1"
            color="white"
            sx={{
              fontWeight: 700,
              fontSize: '5rem',
            }}
            // zIndex={10}
          >
            Learn a
          </Typography>

          <Typography
            variant={'h2'}
            sx={{
              fontSize: '3rem',
              backgroundColor: 'white',
              color: 'primary.main',
              width: 'fit-content',
              padding: 1,
              fontWeight: 700,
              borderRadius: 1,
              // textAlign: 'center',
              // textAlign: { sm: 'center' },
            }}
          >
            Tech Skill
          </Typography>

          <Typography
            variant="h1"
            color="white"
            sx={{
              fontWeight: 700,
              fontSize: {
                xl: '3.5rem',
                lg: '3.5rem',
                md: '3rem',
                sm: '2.5rem',
                xs: '3rem',
              },
              textAlign: 'center',
            }}
            // zIndex={10}
          >
            in just 3 months
          </Typography>

          <Typography
            variant={'h5'}
            sx={{
              backgroundColor: 'white',
              color: 'primary.main',
              width: 'fit-content',
              padding: 1,
              fontWeight: 700,
              borderRadius: 1,
              marginTop: 1,
              textAlign: { sm: 'center' },
            }}
          >
            Join Us Today and Get Certified
          </Typography>

          <Button
            variant="contained"
            backgroundColor="primary"
            component="a"
            href="contact-us"
            underline="none"
            border="0.5 solid white"
            sx={{
              marginTop: 3,
              alignSelf: 'center',
            }}
          >
            Get in touch
          </Button>
        </Box>
        <Box
          flex={1}
          sx={{
            // marginBottom: { xl: -5, lg: -10, md: -15, sm: -20, xs: -25 },
            height: { lg: 550, md: 420, sm: 'none', xs: 'none' },
            display: {
              xl: 'flex',
              lg: 'flex',
              md: 'flex',
              sm: 'none',
              xs: 'none',
            },
          }}
        >
          <Box
            component={'img'}
            src={assets.trainingSection.heroImage}
            width={1}
            height={1}
            sx={{
              boxShadow: '5px 5px 10px solid white',
              padding: 1,
              objectFit: 'cover',
            }}
          />
        </Box>
      </Box>
      <div
        className="custom-shape-divider-bottom-1653904320"
        style={{ zIndex: 2 }}
      >
        <svg
          data-name="Layer 1"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 1200 120"
          preserveAspectRatio="none"
        >
          <path
            d="M321.39,56.44c58-10.79,114.16-30.13,172-41.86,82.39-16.72,168.19-17.73,250.45-.39C823.78,31,906.67,72,985.66,92.83c70.05,18.48,146.53,26.09,214.34,3V0H0V27.35A600.21,600.21,0,0,0,321.39,56.44Z"
            className="shape-fill"
          ></path>
        </svg>
      </div>
    </Box>
  );
}

export default Hero;
