import React from 'react';
import { Main } from 'layouts';
import Hero from './Hero';
import Container from 'components/Container';
import VideoSection from './VideoSection';
import Service from './Service';
const Create = () => {
  return (
    <div>
      {' '}
      <Main>
        <Hero />
        <Container>
          <VideoSection />
        </Container>
        <Container>
          <Service />
        </Container>
      </Main>
    </div>
  );
};

export default Create;
