import React from 'react';
import Box from '@mui/material/Box';
import Hero from './Hero';
import WhatYouWillLearn from './WhatYouWillLearn';
import TheCourseInclude from './TheCourseInclude';
import Description from './Description';

import ApplyCard from './ApplyCard';

const CourseDetails = () => {
  return (
    <Box>
      <Box sx={{ mb: 2 }}>
        <Hero />
      </Box>
      <Box
        sx={{
          position: { xs: 'none', md: 'fixed' },
          right: 28,
          bottom: { sm: 130, md: 150, lg: 210, xl: 220 },
          width: { xs: 'full-width', md: 350 },
          display: { xs: 'block', md: 'none' },
        }}
      >
        <ApplyCard />
      </Box>
      <Box sx={{ mb: 2, p: { xs: 2, md: 0 } }}>
        <WhatYouWillLearn />
      </Box>
      <Box sx={{ mb: 2, p: { xs: 2, md: 0 } }}>
        <TheCourseInclude />
      </Box>
      <Box sx={{ mb: 2, mt: 3, p: { xs: 2, md: 0 } }}>
        <Description />
      </Box>
    </Box>
  );
};
export default CourseDetails;
