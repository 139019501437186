import React, { useEffect, useState } from 'react';

import PropTypes from 'prop-types';
// import { useLocation } from 'react-router-dom';

// import { useLocation } from 'react-router-dom';
import MenuIcon from '@mui/icons-material/Menu';
import { Link } from '@mui/material';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import { alpha, useTheme } from '@mui/material/styles';

import { NavItem } from './components';

const Topbar = ({ onSidebarOpen, pages, colorInvert = false }) => {
  // const location = useLocation();
  // console.log('location', window.location.pathname);
  const theme = useTheme();
  const { mode } = theme.palette;
  const [activeLink, setActiveLink] = useState('');
  useEffect(() => {
    setActiveLink(window && window.location ? window.location.pathname : '');
  }, []);
  const {
    service: servicePages,
    about: aboutPage,
    seminar: eventPage,
    blog: blogPage,
    mentor: mentorPage,
    create: createPage,
  } = pages;
  // {location.pathname === '/onlineplatform' ? ()}

  const hasActiveLink = (link) => link === activeLink;
  const isOnlinePage = window.location?.pathname?.includes('/onlineplatform');

  // const onlinePages = {
  //   mentor: mentorPage,
  // };

  return (
    <Box
      display={'flex'}
      justifyContent={'space-between'}
      alignItems={'center'}
      width={1}
    >
      <Box
        // display={'flex'}
        // component="a"
        // href="/"
        // title="Topibro Tech"
        // width={{ xs: 60, md: 90 }}

        display={'flex'}
        component="a"
        href="/"
        title="topibro"
        width={{ xs: 100, md: 120 }}
      >
        <Box
          component={'img'}
          src={
            mode === 'light' && !colorInvert
              ? 'https://firebasestorage.googleapis.com/v0/b/topibro-tech.appspot.com/o/minified-images%2Ftopibro-logo2.png?alt=media&token=a27cd13f-8d1e-45b5-a191-b0ecde94bb28'
              : 'https://firebasestorage.googleapis.com/v0/b/topibro-tech.appspot.com/o/minified-images%2Ftopibro-logo-dark-theme.png?alt=media&token=44d8ee0a-b693-4135-b5fa-6c256b4ec85c'
          }
          height={1}
          width={1}
        />
      </Box>

      <Box sx={{ display: { xs: 'none', md: 'flex' } }} alignItems={'center'}>
        {isOnlinePage ? (
          <>
            <Box marginLeft={4}>
              <Link
                underline="none"
                component="a"
                href={mentorPage[0].href}
                color={colorInvert ? 'common.white' : 'text.primary'}
                sx={{ display: 'flex', alignItems: 'center' }}
                fontWeight={hasActiveLink(mentorPage[0].href) ? 700 : 400}
              >
                {mentorPage[0].title}
              </Link>
            </Box>
            <Box marginLeft={4}>
              <Link
                underline="none"
                component="a"
                href={createPage[0].href}
                color={colorInvert ? 'common.white' : 'text.primary'}
                sx={{ display: 'flex', alignItems: 'center' }}
                fontWeight={hasActiveLink(createPage[0].href) ? 700 : 400}
              >
                {createPage[0].title}
              </Link>
            </Box>
            <Box marginLeft={4}>
              <Button
                variant="contained"
                color="primary"
                component="a"
                target="blank"
                href="login"
                size="large"
              >
                Log In
              </Button>
            </Box>
          </>
        ) : (
          <>
            <Box>
              <NavItem
                title={'Services'}
                id={'services-page'}
                items={servicePages}
                colorInvert={colorInvert}
              />
            </Box>
            <Box marginLeft={4}>
              <Link
                underline="none"
                component="a"
                href={aboutPage[0].href}
                color={colorInvert ? 'common.white' : 'text.primary'}
                sx={{ display: 'flex', alignItems: 'center' }}
                fontWeight={hasActiveLink(aboutPage[0].href) ? 700 : 400}
              >
                {aboutPage[0].title}
              </Link>
            </Box>
            <Box marginLeft={4}>
              <Link
                underline="none"
                component="a"
                href={eventPage[0].href}
                color={colorInvert ? 'common.white' : 'text.primary'}
                sx={{ display: 'flex', alignItems: 'center' }}
                fontWeight={hasActiveLink(eventPage[0].href) ? 700 : 400}
              >
                {eventPage[0].title}
              </Link>
            </Box>
            <Box marginLeft={4}>
              <Link
                underline="none"
                component="a"
                href={blogPage[0].href}
                color={colorInvert ? 'common.white' : 'text.primary'}
                sx={{ display: 'flex', alignItems: 'center' }}
                fontWeight={hasActiveLink(blogPage[0].href) ? 700 : 400}
              >
                {blogPage[0].title}
              </Link>
            </Box>
            {/* edit here  below */}

            <Box marginLeft={4}>
              <Button
                variant="contained"
                color="primary"
                component="a"
                target="blank"
                href="contact-us"
                size="large"
              >
                Contact
              </Button>
            </Box>
          </>
        )}
      </Box>
      <Box sx={{ display: { xs: 'block', md: 'none' } }} alignItems={'center'}>
        <Button
          onClick={() => onSidebarOpen()}
          aria-label="Menu"
          variant={'outlined'}
          sx={{
            borderRadius: 2,
            minWidth: 'auto',
            padding: 1,
            borderColor: alpha(theme.palette.divider, 0.2),
          }}
        >
          <MenuIcon
            sx={{
              backgroundColor: colorInvert ? 'common.white' : 'text.primary',
              color: colorInvert ? 'text.primary' : 'common.white',
            }}
          />
        </Button>
      </Box>
    </Box>
  );
};

Topbar.propTypes = {
  onSidebarOpen: PropTypes.func,
  pages: PropTypes.object,
  colorInvert: PropTypes.bool,
};

export default Topbar;
