import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import PropTypes from 'prop-types';
import React from 'react';

const TableOfContent = ({ refs }) => {
  console.log(refs);
  const tableOfContent = [
    { background: 'Background', ref: refs.backGroundRef },
    {
      background:
        'How do we collect personal data from users of topibro tech facilities and services?',
      ref: refs.howWeCollectDataRef,
    },
    { background: 'data retention', ref: refs.dataRetentionRef },
    { background: 'data subject rights', ref: refs.dataSubjectRightRef },
  ];
  return (
    <Box>
      <Typography
        variant="h3"
        sx={{
          fontWeight: 200,
          textAlign: 'left',
          my: 2,
          textTransform: 'capitalize',
        }}
      >
        Table of content
      </Typography>
      {tableOfContent.map((contents, i) => (
        <Box
          key={new Date()}
          sx={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'left',
            alignItems: 'center',
          }}
        >
          <Typography sx={{ marginY: 1, ml: 2 }}>{i + 1}.</Typography>
          {}
          <Typography
            component="a"
            onClick={() =>
              contents.ref.current.scrollIntoView({ behavior: 'smooth' })
            }
            sx={{
              textTransform: 'capitalize',
              display: 'flex',
              flexDirection: 'column',
              color: 'purple',
              cursor: 'pointer',
              marginY: 1,
              ml: 2,
              textDecoration: 'none',
              '&hover': {
                textDecoration: 'true',
                color: 'red',
              },
            }}
          >
            {contents.background}
          </Typography>
        </Box>
      ))}
    </Box>
  );
};

TableOfContent.propTypes = {
  refs: PropTypes.obj,
};

export default TableOfContent;
