import * as React from 'react';
import { useEffect, useState } from 'react';

import axios from 'axios';
import PropTypes from 'prop-types';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';
import TextField from '@mui/material/TextField';
import config from 'config';
// import config from '../../../../config';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function AlertDialogSlide({
  openConfirmModal,
  setOpenConfirmModal,
  confirmToken,
  confirmSeminar,
  setOpenSuccessModal,
  setOpenSnackBar,
  setErrorMessage,
}) {
  const [showForm, setShowForm] = useState(false); // form for not going
  const [userInput, setUserInput] = useState(''); // form input

  const [processConfirmation, setProcessConfirmation] =
    useState(confirmSeminar); //  processing state
  const [selectedConfirmation, setSelectedConfirmation] =
    useState(confirmSeminar); // final confirmation state

  const handleSubmit = (e) => {
    e?.preventDefault();
    setProcessConfirmation(true);
    axios
      .post(`${config.apiBaseUrl}/seminar/confirmation`, {
        token: confirmToken,
        confirm: selectedConfirmation,
        description: userInput,
      })
      .then((res) => {
        setOpenSuccessModal(true);
      })
      .catch((err) => {
        // console.log('error confirm seminar', err?.response.data.message);
        setErrorMessage(err?.response?.data?.message);
        setOpenSnackBar(true);
      })
      .finally(() => {
        setOpenConfirmModal(false);
      });
  };

  // send true confirmation if confirm is true from email
  useEffect(() => {
    if (confirmSeminar) {
      handleSubmit();
    }
  }, []);

  return (
    <div>
      {/* <Button variant="outlined">Slide in alert dialog</Button> */}
      <Dialog
        open={openConfirmModal}
        TransitionComponent={Transition}
        keepMounted
        // onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
        overflow="hidden"
        sx={{
          overflow: 'hidden',
        }}
      >
        {processConfirmation === true ? (
          <DialogTitle sx={{ width: 500, textAlign: 'center' }}>
            Processing confirmation...
          </DialogTitle>
        ) : (
          <>
            <DialogTitle sx={{ width: 500, textAlign: 'center' }}>
              {!showForm
                ? 'Confirm your availability for this event'
                : 'Reasons why you cannot attend'}
            </DialogTitle>

            {!showForm ? (
              <DialogActions
                sx={{
                  display: 'flex',
                  justifyContent: 'space-around',
                  m: 2,
                  width: 450,
                }}
              >
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    m: 3,
                    flexDirection: {
                      xs: 'column',
                      md: 'row',
                    },
                  }}
                >
                  <Box sx={{ width: 'fit-content', p: 1 }}>
                    <Button
                      fullwidth
                      variant="contained"
                      onClick={(e) => {
                        setSelectedConfirmation(true);
                        handleSubmit(e);
                      }}
                    >
                      Yes, I will attend
                    </Button>
                  </Box>
                  <Box sx={{ width: 'fit-content', p: 1 }}>
                    <Button
                      fullwidth
                      variant="outlined"
                      onClick={() => setShowForm(true)}
                    >
                      {`I'm not attending`}
                    </Button>
                  </Box>
                </Box>
              </DialogActions>
            ) : (
              <DialogActions
                sx={{ display: 'flex', justifyContent: 'space-around' }}
              >
                <Box>
                  <form onSubmit={handleSubmit}>
                    <Box sx={{ width: 450 }}>
                      <TextField
                        fullWidth
                        variant="outlined"
                        value={userInput}
                        onChange={(e) => setUserInput(e.target.value)}
                      />
                    </Box>
                    <Box
                      sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        m: 3,
                        flexDirection: {
                          xs: 'column',
                          md: 'row',
                        },
                      }}
                    >
                      <Box sx={{ width: 1, p: 1 }}>
                        <Button
                          fullWidth
                          variant="outlined"
                          onClick={() => setShowForm(false)}
                        >
                          Back
                        </Button>
                      </Box>
                      <Box sx={{ width: 1, p: 1 }}>
                        <Button
                          onClick={handleSubmit}
                          fullWidth
                          variant="contained"
                          type="submit"
                        >
                          Submit
                        </Button>
                      </Box>
                    </Box>
                  </form>
                </Box>
              </DialogActions>
            )}
          </>
        )}
      </Dialog>
    </div>
  );
}

AlertDialogSlide.propTypes = {
  openConfirmModal: PropTypes.bool,
  setOpenConfirmModal: PropTypes.func,
  confirmToken: PropTypes.string,
  confirmSeminar: PropTypes.bool,
  setOpenSuccessModal: PropTypes.func,
  setOpenSnackBar: PropTypes.func,
  setErrorMessage: PropTypes.func,
};
