import React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
// import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Avatar from '@mui/material/Avatar';
// import useMediaQuery from '@mui/material/useMediaQuery';
// import { useTheme } from '@mui/material/styles';
import { assets } from 'assets/assets';

const mock = assets.indexView.technologies1;
const mockTwo = assets.indexView.technologies2;

const Technologies = () => {
  // const theme = useTheme();
  // const isMd = useMediaQuery(theme.breakpoints.up('md'), {
  //   defaultMatches: true,
  // });

  return (
    <Box>
      <Box marginBottom={4}>
        <Typography
          variant="h3"
          align={'center'}
          data-aos={'fade-up'}
          gutterBottom
          sx={{
            fontWeight: 700,
          }}
        >
          Technologies
        </Typography>
      </Box>
      <Grid
        container
        spacing={0}
        sx={{
          // maxWidth: 800,
          margin: '0 auto',
        }}
        data-aos="fade-up"
      >
        {mock.map((item, index) => (
          <Grid
            item
            container
            key={index}
            xs={4}
            direction={index % 4 ? 'row' : 'row-reverse'}
          >
            <Grid item xs={6}>
              <Avatar
                src={item.logo}
                sx={{
                  width: { xs: 60, md: 180 },
                  height: { xs: 60, md: 180 },
                  padding: 2,
                  boxShadow: 4,
                  marginTop: 1,
                }}
                data-aos="zoom-in"
                data-aos-once="false"
              />
            </Grid>
            <Grid item xs={6}>
              {mockTwo[index] && (
                <Avatar
                  src={mockTwo[index].logo}
                  alt="topibro technologies Ijegun Lagos"
                  sx={{
                    width: { xs: 60, md: 100 },
                    height: { xs: 60, md: 100 },
                    padding: 2,
                    boxShadow: 4,
                    marginTop: 1,
                  }}
                  data-aos="zoom-in"
                  data-aos-once="false"
                />
              )}
            </Grid>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default Technologies;
