import React from 'react';

import { Link } from '@mui/material';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Grid from '@mui/material/Grid';
import { useTheme } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import { assets } from 'assets/assets';

const mock = assets.trainingSection.coursesImage;

const Main = () => {
  const theme = useTheme();
  return (
    <Box
      sx={{
        marginBottom: 0,
      }}
    >
      <Typography
        variant={'h1'}
        sx={{
          fontWeight: 700,
          fontFamily: 'Roboto, sans-serif',
          textAlign: 'center',
          marginY: 4,
        }}
      >
        Courses
      </Typography>
      <Grid container spacing={4}>
        {mock.map((item, i) => (
          <Grid item xs={12} md={6} xl={4} lg={4} key={i}>
            <Box
              component="a"
              href={item.path}
              display={'block'}
              width={'100%'}
              height={'100%'}
              margin="auto"
              sx={{
                textDecoration: 'none',
                transition: 'all .2s ease-in-out',
                '&:hover': {
                  transform: `translateY(-${theme.spacing(1 / 2)})`,
                },
              }}
            >
              <Box
                component={Card}
                width={'100%'}
                height={'100%'}
                display={'flex'}
                margin={'auto'}
                flexDirection={'column'}
              >
                <Box
                  sx={{
                    display: 'block',
                    height: { xs: 350, md: 300 },
                    margin: 'auto',
                    width: { xs: '100%', md: '100%', xl: '100%' },
                  }}
                >
                  <CardMedia
                    image={item.image}
                    title={item.title}
                    alt={item.alt}
                    sx={{
                      height: { xs: '100%', md: '100%' },
                      width: {
                        xl: '110%',
                        lg: '110$',
                        md: '120%',
                        sm: '115%',
                        xs: '120%',
                      },
                      margin: 'auto',
                      padding: -25,
                      filter:
                        theme.palette.mode === 'dark'
                          ? 'brightness(0.7)'
                          : 'none',
                    }}
                  />
                </Box>
                <Box component={CardContent}>
                  <Typography variant={'h6'} fontWeight={700} gutterBottom>
                    {item.title}
                  </Typography>
                  <Box
                    sx={{
                      display: 'flex',
                      flexDirection: 'row',
                      justifyContent: 'space-between',
                    }}
                  >
                    <Box>
                      {item.column1.map((course, index) => {
                        return (
                          <Grid container spacing={2} key={index}>
                            <Grid item xs={6}>
                              <Typography
                                variant={'body2'}
                                color="text.secondary"
                                sx={{
                                  width: 'fit-content',
                                  margin: 'auto',
                                }}
                              >
                                {course}
                              </Typography>
                            </Grid>
                          </Grid>
                        );
                      })}
                    </Box>
                    <Box>
                      {item.column2.map((course, index) => {
                        return (
                          <Grid container spacing={2} key={index}>
                            <Grid item xs={6}>
                              <Typography
                                variant={'body2'}
                                color="text.secondary"
                                sx={{
                                  width: 'fit-content',
                                  margin: 'auto',
                                }}
                              >
                                {course}
                              </Typography>
                            </Grid>
                          </Grid>
                        );
                      })}
                    </Box>
                  </Box>
                </Box>
                <Box flexGrow={1} />
                <Box
                  component={CardActions}
                  sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                  }}
                >
                  <Button
                    size="large"
                    endIcon={
                      <svg
                        width={16}
                        height={16}
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth={2}
                          d="M17 8l4 4m0 0l-4 4m4-4H3"
                        />
                      </svg>
                    }
                  >
                    <Link
                      component="a"
                      href={item.path}
                      sx={{
                        textDecoration: 'none',
                      }}
                    >
                      Monthly fee
                    </Link>
                  </Button>
                  <Typography
                    sx={{
                      backgroundColor: '#216d5b',
                      color: '#fff',
                      padding: 1,
                      fontFamily: 'Roboto, sans-serif',
                      borderRadius: 1,
                    }}
                  >
                    {item.price}
                  </Typography>
                </Box>
              </Box>
            </Box>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default Main;
