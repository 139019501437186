/* eslint-disable react/no-unescaped-entities */
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import { useTheme } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import useMediaQuery from '@mui/material/useMediaQuery';
import React from 'react';

const initialWhoWeAre = `
Publish the course you want, in the way you want, and always have control of your own content.
Our core values are hinged on superior service delivery, excellent user experience and solution- oriented systems. Our integrated business model guarantees easy access to all our services, time saving and cost- effectiveness.


Our clientele include businesses, government agencies, start ups, multinationals, SME's etc .


We believe that with the right tools in place, technology can scale up your business beyond what you can ever imagine and we are set to make it happen.
`;
const Creator = () => {
  // const [readMore, setReadMore] = useState(true);
  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.up('md'), {
    defaultMatches: true,
  });
  // const readMoreToggle = () => {
  //   setReadMore((prev) => !prev);
  // };
  return (
    <Box>
      <Typography
        variant={'h3'}
        sx={{
          fontWeight: 400,
          fontFamily: 'Roboto, sans-serif',
          textAlign: 'center',
          marginY: 4,
          textTransform: 'capitalize',
        }}
      >
        Create your course
      </Typography>
      <Grid container spacing={4} direction={isMd ? 'row' : 'column'}>
        <Grid
          item
          container
          alignItems={'center'}
          justifyContent="center"
          xs={12}
          md={6}
        >
          <Box>
            <Typography variant={'h4'} gutterBottom sx={{ fontWeight: 700 }}>
              Create your courses
            </Typography>
            <Typography component={'p'} color={'text.secondary'}>
              {initialWhoWeAre}
            </Typography>
            <Button variant="contained" href="/onlineplatform/create">
              Get started
            </Button>
          </Box>
        </Grid>
        <Grid
          item
          container
          justifyContent="center"
          alignItems="center"
          xs={12}
          md={6}
        >
          <Box maxWidth={700} width={1}>
            <Box
              component={'img'}
              src={
                'https://images.pexels.com/photos/3184292/pexels-photo-3184292.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2'
              }
              width={'100%'}
              height={1}
              sx={{
                filter:
                  theme.palette.mode === 'dark' ? 'brightness(0.8)' : 'none',
              }}
            />
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default Creator;
