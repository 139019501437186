import Box from '@mui/material/Box';
// import Button from '@mui/material/Button';
import { useTheme } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import React from 'react';
import Typed from 'react-typed';
import { assets } from 'assets/assets';
import FormDialog from './FormDialogue';

function Hero() {
  const description = `Let's help you scale up your business and productivity while you attract more reach with easy to use mobile apps built to specifications.`;

  const theme = useTheme();

  console.log(assets.mobileDev.heroGirl, 'my assets ooooo');

  return (
    <Box
      height={'95vh'}
      minHeight={'700px'}
      position={'relative'}
      sx={{
        marginTop: -16,
        paddingTop: 8,
        display: 'flex',
        overflow: 'hidden',
        flexDirection: 'row',
        justifyContent: 'center',
        backgroundColor: theme.palette.alternate.main,
        background: `url(${assets.mobileDev.herobackground}) no-repeat center`,
        backgroundSize: 'cover',
      }}
    >
      <Box
        sx={{
          backgroundColor: '#ffffffaa',
          opacity: 0.7,
          height: '100%',
          zIndex: 0,
          position: 'absolute',
          width: '100%',
          top: 0,
        }}
      />
      <Box
        sx={{
          display: 'flex',
          flexDirection: {
            xl: 'row',
            lg: 'row',
            md: 'row',
            sm: 'column',
            xs: 'column',
          },
          width: { xl: '80vw', lg: '80vw', md: '95vw', sm: '90vw', xs: '90vw' },
          margin: 'auto',
          // backgroundColor: '#016D5B',
          zIndex: 1,
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flex: 1,
            flexDirection: 'column',
            marginTop: { lg: 10 },
            alignItems: { md: 'flex-start', xs: 'center' },
          }}
        >
          <Typography
            variant="h1"
            color="white"
            sx={{
              fontWeight: 700,
              color: 'primary.main',
            }}
          >
            Create
          </Typography>

          <Typography
            variant={'h1'}
            sx={{
              backgroundColor: '#ffffffcc',
              color: 'primary.main',
              width: 'fit-content',
              padding: 0.6,
              fontWeight: 700,
              borderRadius: 1,
            }}
          >
            <Typed
              typedRef={(typed) => {
                console.log('typed', typed);
              }}
              strings={[' A Course']}
              typeSpeed={50}
              cursorChar={'.'}
            />
          </Typography>

          <Typography
            variant="body2"
            color="#ffffff"
            sx={{
              color: 'primary',
              fontSize: 18,
              fontWeight: 400,
              marginBottom: 0,
              maxWidth: 600,
              marginY: 3,
              zIndex: 10,
              fontFamily: 'Roboto, sans-serif',
            }}
          >
            {description}
          </Typography>

          <Box
            marginTop={5}
            // display="flex"
            // flexDirection={{ xs: 'column', sm: 'row' }}
            // alignItems={{ xs: 'stretched', sm: 'flex-start' }}
            fullwidth
          >
            <FormDialog />
          </Box>
        </Box>
        <Box
          flex={1}
          sx={{
            // marginBottom: { xl: -5, lg: -10, md: -15, sm: -20, xs: -25 },
            height: { lg: 550, md: 420, sm: 'none', xs: 'none' },
            display: {
              xl: 'flex',
              lg: 'flex',
              md: 'flex',
              sm: 'none',
              xs: 'none',
            },
          }}
        >
          <Box
            component={'img'}
            src={
              'https://images.pexels.com/photos/3184292/pexels-photo-3184292.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2'
            }
            width={1}
            height={1}
            sx={{
              boxShadow: '5px 5px 10px solid white',
              padding: 1,
              objectFit: 'cover',
            }}
          />
        </Box>
      </Box>
    </Box>
  );
}

export default Hero;
