/* eslint-disable react/no-unescaped-entities */
import { React, useEffect } from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import Avatar from '@mui/material/Avatar';
import { Link } from 'react-router-dom';
// import { article } from 'utils/Post';

// import IconButton from '@mui/material/IconButton';
// import FacebookIcon from '@mui/icons-material/Facebook';
// import TwitterIcon from '@mui/icons-material/Twitter';
// import InstagramIcon from '@mui/icons-material/Instagram';
import PropTypes from 'prop-types';

const Content = ({ articleData, articleid }) => {
  const routeId = articleid === 1 ? 2 : 1;
  const theme = useTheme();

  const isMd = useMediaQuery(theme.breakpoints.up('md'), {
    defaultMatches: true,
  });
  useEffect(() => {
    // scroll to top on page load
    window.scrollTo({ top: 0, left: 0, behavior: 'auto' });
  }, []);

  console.log('  my iiiidddd', articleid);

  const photos = [
    {
      src: articleData.picture1,
      rows: 1,
      cols: 2,
    },
    {
      src: articleData.picture2,
      rows: 1,
      cols: 1,
    },
    {
      src: articleData.picture3,
      rows: 1,
      cols: 1,
    },
    {
      src: articleData.picture4,
      rows: 1,
      cols: 2,
    },
  ];

  return (
    <Box>
      <Box paddingX={{ xs: 0, sm: 4, md: 6 }}>
        <Typography variant={'subtitle1'}>{articleData.text}</Typography>
        <Box width={1} height={1} marginY={4}>
          <LazyLoadImage
            height={'100%'}
            width={'100%'}
            src={articleData.image1}
            alt="Remote working"
            effect="blur"
            style={{
              filter:
                theme.palette.mode === 'dark' ? 'brightness(0.7)' : 'none',
              objectFit: 'cover',
              borderRadius: 8,
              width: '100%',
              height: '100%',
              maxHeight: 400,
            }}
          />
        </Box>
        <Typography
          variant={'h4'}
          color={'primary'}
          align={'center'}
          fontWeight={'normal'}
        >
          {articleData.quoteText}
        </Typography>
        <Box marginY={4}>
          <Typography variant={'h5'} gutterBottom>
            {articleData.heading}
          </Typography>
          <Typography>{articleData.headingText}</Typography>
          {/* <Box marginTop={2}>
            <ul>
              <li>
                <Typography>{articleData.secondHeading}</Typography>
              </li>
              <li>
                <Typography>
                {articleData.secondHeadingText}
                </Typography>
              </li>
            </ul>
          </Box> */}
        </Box>
      </Box>
      <Box marginY={4}>
        <ImageList
          variant="quilted"
          cols={3}
          rowHeight={isMd ? 550 : 300}
          gap={isMd ? 16 : 8}
        >
          {photos.map((item, i) => (
            <ImageListItem key={i} cols={item.cols || 2} rows={item.rows || 1}>
              <LazyLoadImage
                height={'100%'}
                width={'100%'}
                src={item.src}
                alt="atendees at our event"
                effect="blur"
                style={{
                  objectFit: 'cover',
                  cursor: 'poiner',
                  borderRadius: 8,
                  filter:
                    theme.palette.mode === 'dark' ? 'brightness(0.7)' : 'none',
                }}
              />
            </ImageListItem>
          ))}
        </ImageList>
      </Box>
      <Box paddingX={{ xs: 0, sm: 4, md: 6 }} paddingBottom={4}>
        <Box>
          <Typography variant={'h5'} gutterBottom>
            {articleData.secondHeading}
          </Typography>
          <Typography>{articleData.secondHeadingText}</Typography>
        </Box>
      </Box>
      <Box
        component={Card}
        boxShadow={2}
        display={'flex'}
        flexDirection={{ xs: 'column', md: 'row-reverse' }}
        sx={{ backgroundImage: 'none' }}
      >
        <Box
          sx={{
            width: { xs: 1, md: '50%' },
            position: 'relative',
            '& .lazy-load-image-loaded': {
              height: 1,
              display: 'flex !important',
            },
          }}
        >
          <Box
            component={LazyLoadImage}
            height={1}
            width={1}
            src={articleData.image2}
            alt="..."
            effect="blur"
            sx={{
              objectFit: 'cover',
              maxHeight: 360,
              filter:
                theme.palette.mode === 'dark' ? 'brightness(0.7)' : 'none',
            }}
          />
          <Box
            component={'svg'}
            viewBox="0 0 112 690"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            sx={{
              position: 'absolute',
              bottom: 0,
              top: '-50%',
              left: 0,
              right: 0,
              color: theme.palette.background.paper,
              transform: 'scale(2)',
              height: 1,
              width: 'auto',
              transformOrigin: 'top center',
              display: { xs: 'none', md: 'block' },
            }}
          >
            <path
              d="M0 0h62.759v172C38.62 384 112 517 112 517v173H0V0z"
              fill="currentColor"
            />
          </Box>
        </Box>
        {/* 
        {.map((invoice) => (
          <Link
            style={{ display: "block", margin: "1rem 0" }}
            to={`/invoices/${invoice.number}`}
            key={invoice.number}
          >
            {invoice.name}
          </Link>
        ))} */}
        <CardContent
          sx={{
            position: 'relative',
            width: { xs: 1, md: '50%' },
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Typography variant={'h6'} gutterBottom>
                {articleData.cardHeader}
              </Typography>
              <Typography color={'text.secondary'}>
                {' '}
                {articleData.cardText1}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography component="p">{articleData.cardText2}</Typography>
            </Grid>
            {/* <Grid item xs={12}>
              <Typography component="p">
                Bringing toghether a fine mix of professional and aspiring web
                devlopers, it was all fun and learning as we shared valuable
                tips on how to begin your web developer journey
              </Typography>
            </Grid> */}
            <Grid item xs={12}>
              <Link style={{ textDecoration: 'none' }} to={`/blog/${routeId}`}>
                <Button
                  size={'large'}
                  fullWidth
                  variant={'contained'}
                  type={'submit'}
                  sx={{ height: 54 }}
                  onClick={() => {
                    window.scrollTo({ top: 0, left: 0, behavior: 'auto' });
                  }}
                >
                  Read
                </Button>
              </Link>
            </Grid>
          </Grid>
        </CardContent>
      </Box>
      <Box paddingX={{ xs: 0, sm: 4, md: 6 }} paddingY={4}>
        <Typography color={'text.secondary'}>
          {articleData.closingText}
        </Typography>
      </Box>
      <Box paddingY={4}>
        <Divider />
      </Box>
      <Box
        display={'flex'}
        alignItems={'center'}
        justifyContent={'space-between'}
        flexWrap={'wrap'}
      >
        <Box
          display={'flex'}
          alignItems={'center'}
          sx={{ width: 1, height: 1 }}
        >
          <Avatar
            src={articleData.avatar}
            sx={{ objectFit: 'cover', height: 1, marginRight: 1 }}
          />
          <Box>
            <Typography fontWeight={600}> {articleData.title}</Typography>
            <Typography color={'text.secondary'}>
              {' '}
              {articleData.date}
            </Typography>
          </Box>
        </Box>
        {/* <Box display={'flex'} alignItems={'center'}>
          <Typography color={'text.secondary'}>Share:</Typography>
          <Box marginLeft={0.5}>
            <IconButton aria-label="Facebook">
              <FacebookIcon />
            </IconButton>
            <IconButton aria-label="Instagram">
              <InstagramIcon />
            </IconButton>
            <IconButton aria-label="Twitter">
              <TwitterIcon />
            </IconButton>
          </Box>
        </Box> */}
      </Box>
    </Box>
  );
};

Content.propTypes = {
  articleData: PropTypes.obj,
  articleid: PropTypes.number,
};

export default Content;
